import parseISO from "date-fns/parseISO";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import http from "@/services/http";
import signal from "@/services/signal";
import {IAuditEntry} from "./models";

import T from "@translate/T";
import UserLink from "../building-blocks/UserLink";
import Card from "../design/Card";
import Time from "../design/Time";
import AuditAction from "../display-blocks/AuditAction";

export interface IAuditLogProps<TValue> {
    endpoint: string;
    renderEntry(document: TValue, entry: IAuditEntry<TValue>): JSX.Element;
}

interface IAuditLogState<TValue> {
    entries: IAuditEntry<TValue>[];
}

class AuditLog<TValue = never> extends React.PureComponent<
    IAuditLogProps<TValue>,
    IAuditLogState<TValue>
> {
    public readonly state: IAuditLogState<TValue> = {entries: []};

    private unsubscribe?: () => void;

    public async componentDidMount() {
        await this.refresh();

        this.unsubscribe = signal.register({
            auditChanged: () => this.refresh(),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        return (
            <Card headerClassName="bg-secondary" title={<T>Activities</T>}>
                <table className="table table-sm table-striped table-center mb-0">
                    <tbody>{this.renderContent()}</tbody>
                </table>
            </Card>
        );
    }

    private renderContent() {
        const entries = this.renderEntries();
        if (entries.length) {
            return entries;
        }

        return (
            <tr>
                <td>
                    <em className="text-muted">
                        <T>No recent activity.</T>
                    </em>
                </td>
            </tr>
        );
    }

    private renderEntries() {
        const {renderEntry} = this.props;

        return this.state.entries
            .map((x, i) => {
                const item = renderEntry(x.document, x);
                if (item === null) {
                    return null;
                }

                return (
                    <tr key={i}>
                        <td key={x.id} data-testid={"item-" + x.id}>
                            {this.getAuditMessage(x)}
                        </td>
                    </tr>
                );
            })
            .filter(Boolean);
    }

    private getAuditMessage(x: IAuditEntry<TValue>) {
        return (
            <React.Fragment>
                <UserLink
                    username={x.author.username}
                    displayName={x.author.displayName}
                    documentType={EDocumentTypes.User}
                />{" "}
                <AuditAction value={x.action} />{" "}
                {this.props.renderEntry(x.document, x)}
                <br />
                <Time value={parseISO(x.time)} />
            </React.Fragment>
        );
    }

    private async refresh() {
        try {
            const response = await http
                .get(this.props.endpoint)
                .json<IAuditEntry<TValue>[]>();

            this.setState({entries: response});
        } catch {
            this.setState({entries: []});
        }
    }
}

export default AuditLog;

import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import http from "@/services/http";
import intlLocale from "@/services/intlLocale";
import {ILanguage} from "./models";

function getSelected(options: ILanguage[], id: string): ILanguage {
    return (
        options.find((x) => x.id === id) ??
        options.find((x) => x.id === "en") ?? {id: "en", name: "English"}
    );
}

interface ILanguagesProps {
    intl: IntlShape;
}

interface ILanguagesState {
    options: ILanguage[];
}

class Languages extends React.PureComponent<ILanguagesProps, ILanguagesState> {
    public readonly state: ILanguagesState = {options: []};

    public async componentDidMount() {
        await this.retrieve();
    }

    @boundMethod
    public async onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        await intlLocale.setLanguage((e.target as HTMLElement).id);
    }

    public render() {
        const {intl} = this.props;
        const {options} = this.state;
        const selected = getSelected(options, intl.locale.split("-")[0]);

        if (!options.length) {
            return null;
        }

        return (
            <li
                className="nav-item dropdown"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
            >
                <Link
                    className="nav-link dropdown-toggle"
                    to=""
                    id="languages"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {selected.name}
                </Link>

                <div
                    aria-labelledby="languages"
                    className="dropdown-menu dropdown-menu-right"
                >
                    {options.map((x) => this.renderLanguage(x.id, x.name))}
                </div>
            </li>
        );
    }

    private renderLanguage(id: string, name: string) {
        return (
            <button
                type="button"
                key={id}
                id={id}
                className="dropdown-item"
                onClick={this.onClick}
            >
                {name}
            </button>
        );
    }

    private async retrieve() {
        try {
            const options = await http
                .get("/api/languages")
                .json<ILanguage[]>();

            this.setState({options});
        } catch {
            this.setState({options: []});
        }
    }
}

export default injectIntl(Languages);

import {EPascal, EPressureUnits} from "@/components/preferences/models";
import {ILocalizedText} from "@translate/models";

import {intl2Str} from "@translate/T";

export function getPressureUnit(unit: EPressureUnits): ILocalizedText {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            switch (getPascal()) {
                default:
                case EPascal.Pa:
                    return (intl) => intl2Str(intl, "Pa");

                case EPascal.KPa:
                    return (intl) => intl2Str(intl, "kPa");

                case EPascal.MPa:
                    return (intl) => intl2Str(intl, "MPa");
            }

        case EPressureUnits.Psi:
            return (intl) => intl2Str(intl, "psi");

        case EPressureUnits.Nmm2:
            return (intl) => intl2Str(intl, "N/mm²");
    }
}

export function fromPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value / Math.pow(10, getPascal());

        case EPressureUnits.Nmm2:
            return value / 1000000;

        case EPressureUnits.Psi:
            return value / 6894.76;
    }
}

export function fromKiloPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value / Math.pow(10, getPascal() - 3);

        case EPressureUnits.Nmm2:
            return value / 1000;

        case EPressureUnits.Psi:
            return fromPascal(value * 1000, unit);
    }
}

export function fromMegaPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value / Math.pow(10, getPascal() - 6);

        case EPressureUnits.Nmm2:
            return value;

        case EPressureUnits.Psi:
            return fromPascal(value * 1000000, unit);
    }
}

export function toPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value * Math.pow(10, getPascal());

        case EPressureUnits.Nmm2:
            return value * 1000000;

        case EPressureUnits.Psi:
            return value * 6894.76;
    }
}

export function toKiloPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value * Math.pow(10, getPascal() - 3);

        case EPressureUnits.Nmm2:
            return value * 1000;

        case EPressureUnits.Psi:
            return toPascal(value, unit) / 1000;
    }
}

export function toMegaPascal(value: number, unit: EPressureUnits) {
    switch (unit) {
        default:
        case EPressureUnits.Pa:
            return value * Math.pow(10, getPascal() - 6);

        case EPressureUnits.Nmm2:
            return value;

        case EPressureUnits.Psi:
            return toPascal(value, unit) / 1000000;
    }
}

function getPascal(): EPascal {
    const href = window.location.href;
    if (href.includes("fracture")) {
        return EPascal.MPa;
    } else if (href.includes("centrifuge")) {
        return EPascal.KPa;
    }

    return EPascal.Pa;
}

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ECheckStates} from "./models";

import InlineCheckbox from "./InlineCheckbox";

interface ITristateCheckboxProps {
    value: ECheckStates;
    suffixId: string;

    checkboxClassNameOverwrite?: string;
    dataTestId?: string;

    onChange(checked: boolean): void;
}

class TristateCheckbox extends React.PureComponent<ITristateCheckboxProps> {
    @boundMethod
    public onClick(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(e.target.checked);
    }

    public render() {
        const {value, checkboxClassNameOverwrite, dataTestId, suffixId} =
            this.props;
        const id = "tristate-checkbox-" + suffixId;

        return (
            <InlineCheckbox
                idSuffix="tristate"
                data-testid={dataTestId ?? id}
                checkboxClassNameOverwrite={checkboxClassNameOverwrite}
                checked={value === ECheckStates.Checked}
                indeterminate={value === ECheckStates.Indeterminate}
                toggle={this.onClick}
            />
        );
    }
}

export default TristateCheckbox;

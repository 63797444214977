import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import {Titled} from "react-titled";

import license from "@/services/license";
import {ESopLicenses} from "../license/models";

import MissingLicense from "@toolbox/render-page/MissingLicense";
import {intl2Str} from "@translate/T";
import DeleteTemplate from "./DeleteTemplate";
import EditTemplate from "./EditTemplate";
import TemplateList from "./TemplateList";

interface ITemplateProps {
    intl: IntlShape;
    baseUrl: string;
}

class Template extends React.PureComponent<ITemplateProps> {
    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "Templates | {parent}", {parent});
    }

    public render() {
        const {baseUrl} = this.props;

        if (!license.value.sops.includes(ESopLicenses.Template)) {
            return (
                <MissingLicense missingSopLicenses={[ESopLicenses.Template]} />
            );
        }

        return (
            <Titled title={this.getTitle}>
                <Switch>
                    <Route
                        path={`${baseUrl}/edit/:id`}
                        render={this.renderEdit}
                    />
                    <Route
                        path={`${baseUrl}/delete/:id`}
                        render={this.renderDelete}
                    />

                    <Route render={this.renderList} />
                </Switch>
            </Titled>
        );
    }

    @boundMethod
    private renderEdit(props: RouteComponentProps<{id?: string}>) {
        const id = props.match.params.id ?? "";

        return <EditTemplate id={id} baseUrl={this.props.baseUrl} />;
    }

    @boundMethod
    private renderDelete(props: RouteComponentProps<{id?: string}>) {
        const id = props.match.params.id ?? "";

        return <DeleteTemplate id={id} baseUrl={this.props.baseUrl} />;
    }

    @boundMethod
    private renderList(props: RouteComponentProps) {
        return (
            <TemplateList
                baseUrl={this.props.baseUrl}
                history={props.history}
                location={props.location}
                match={props.match}
            />
        );
    }
}

export default injectIntl(Template);

import {boundMethod} from "autobind-decorator";
import React from "react";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {EDocIdPrefixes, ISelectedItem} from "../models";

import Delete from "@toolbox/button-like/DeleteButton";

export interface IDocumentDeleteProps {
    project: number;
    selection: ISelectedItem[];

    onDeleted(): void;
    onDelete(undeletedIds: string[]): void;
}

class DocumentDelete extends React.PureComponent<IDocumentDeleteProps> {
    public render() {
        if (!this.canDelete()) {
            return null;
        }

        return <Delete onDelete={this.deleteSelection} />;
    }

    private getEndpoint(item: ISelectedItem) {
        const {id, prefix} = item;

        switch (prefix) {
            case EDocIdPrefixes.CentMeasures:
                return `/api/centrifuge/measurement/${id}`;

            case EDocIdPrefixes.FracMeasures:
                return `/api/fracture/measurement/${id}`;

            case EDocIdPrefixes.SpocMeasures:
                return `/api/spoc/measurement/${id}`;

            case EDocIdPrefixes.SpocAlignments:
                return `/api/spoc/alignment/${id}`;

            case EDocIdPrefixes.CentAnalyses:
                return `/api/centrifuge/analysis/${id}`;

            case EDocIdPrefixes.FracAnalyses:
                return `/api/fracture/analysis/${id}`;

            case EDocIdPrefixes.SpocAnalyses:
                return `/api/spoc/analysis/${id}`;

            case EDocIdPrefixes.CentSops:
                return `/api/centrifuge/sop/${id}`;

            case EDocIdPrefixes.FracSops:
                return `/api/fracture/sop/${id}`;

            case EDocIdPrefixes.SpocSops:
                return `/api/spoc/sop/${id}`;

            case EDocIdPrefixes.CentReports:
            case EDocIdPrefixes.SpocReports:
                return `/api/report/${id}`;
        }
    }

    @boundMethod
    private async deleteSelection() {
        const {selection, onDeleted, onDelete} = this.props;
        if (!selection.length) {
            return false;
        }

        const undeletedIds: string[] = [];
        for (const item of selection) {
            const endpoint = this.getEndpoint(item);
            if (!endpoint) {
                continue;
            }

            const {id, nested, prefix} = item;
            if (prefix === EDocIdPrefixes.SpocMeasures) {
                for (const rep of nested) {
                    const parts = rep.split("-");
                    try {
                        await http.delete(
                            endpoint + "/" + parts[1] + "/" + parts[2],
                        );
                    } catch {
                        undeletedIds.push(prefix + "/" + id);
                    }
                }

                if (nested.length) {
                    continue;
                }
            }

            try {
                await http.delete(endpoint);
            } catch {
                undeletedIds.push(prefix + "/" + id);
            }
        }

        onDelete(undeletedIds);
        onDeleted();

        return true;
    }

    private canDelete() {
        const {project, selection} = this.props;

        if (!selection.length) {
            return false;
        }

        for (const item of selection) {
            if (
                item.prefix.includes("Measures") &&
                !session.hasRole(ERoles.Manager, project)
            ) {
                return false;
            }

            if (
                !session.hasRole(ERoles.Manager, project) &&
                !(
                    session.hasRole(ERoles.Editor, project) &&
                    item.owner === session.user
                )
            ) {
                return false;
            }
        }

        return true;
    }
}

export default DocumentDelete;

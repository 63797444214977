import {IDLE_DELAY} from "@toolbox/models";

/** time multiplied by 250ms */
export default async function sleep(time: number) {
    await new Promise((resolve) =>
        window.setTimeout(resolve, time * IDLE_DELAY),
    );
}

const triggers: (() => void)[] = [];

export async function sleepingBeauty() {
    await new Promise((resolve) => {
        // sleep for n hour
        const id = window.setTimeout(resolve, 14400);
        triggers.push(() => {
            window.clearTimeout(id);
            window.setTimeout(resolve, IDLE_DELAY);
        });
    });
}

export async function princePhillip() {
    for (const trigger of triggers) {
        trigger();
    }

    await sleep(1);
}

import {boundMethod} from "autobind-decorator";
import {isEqual} from "lodash";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import display from "@/services/display-name";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {IRoleAssignment} from "./models";

import T from "@translate/T";
import RolesAssignment from "../admin/users/RolesAssignment";

export interface IAssignedRoleProps {
    idSuffix: string;
    value: IRoleAssignment;
    type: EDocumentTypes.User | EDocumentTypes.Group;

    onChange(value: IRoleAssignment, role: ERoles): void;
    onRemove(value: IRoleAssignment): void;
}

interface IAssignedRoleState {
    disabled: boolean;
}

class AssignedRole extends React.PureComponent<
    IAssignedRoleProps,
    IAssignedRoleState
> {
    public readonly state: IAssignedRoleState = {disabled: true};

    public async componentDidMount() {
        await this.retrieve();
    }

    public async componentDidUpdate(prevProps: Readonly<IAssignedRoleProps>) {
        const {type, value} = this.props;
        if (
            !isEqual(prevProps.type, type) ||
            !isEqual(prevProps.value, value)
        ) {
            await this.retrieve();
        }
    }

    @boundMethod
    public onChange(roles: ERoles, clear?: string) {
        const {onChange, onRemove, value} = this.props;

        if (clear !== undefined) {
            onRemove(value);
        } else {
            onChange(value, roles);
        }
    }

    public render() {
        const {idSuffix, type, value} = this.props;
        const {disabled} = this.state;
        const {role, target} = value;

        return (
            <RolesAssignment
                idSuffix={idSuffix}
                role={role}
                type={type}
                header={target}
                disabled={disabled}
                clearText={<T>Remove</T>}
                onChange={this.onChange}
            />
        );
    }

    private async retrieve() {
        const {type, value} = this.props;

        if (session.hasRole(ERoles.Administrator)) {
            this.setState({disabled: false});
            return;
        }

        if (type === EDocumentTypes.User) {
            this.setState({disabled: value.target === session.user});
            return;
        }

        const userDetail = await display.getUserDetails(session.user ?? "");

        const groups = userDetail?.groups;
        if (!groups) {
            this.setState({disabled: true});
            return;
        }

        this.setState({disabled: groups.includes(value.target)});
    }
}

export default AssignedRole;

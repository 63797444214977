import {boundMethod} from "autobind-decorator";
import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import http from "@/services/http";
import {ERoles} from "@/services/models";
import {IRoleAssignment, ISearchResult} from "./models";
import {IUser} from "../admin/groups/models";

import AsyncSelects from "@toolbox/button-like/AsyncSelects";
import T from "@translate/T";
import AssignedRole from "./AssignedRole";

interface IRolesEditorProps {
    values: IRoleAssignment[];
    endpoint: "groups" | "users";
    onChange(value: IRoleAssignment[]): void;
}

interface IRolesEditorState {
    options: IUser[];
}

class RolesEditor extends React.PureComponent<
    IRolesEditorProps,
    IRolesEditorState
> {
    public readonly state: IRolesEditorState = {options: []};

    public async componentDidMount() {
        const options = await this.performSearch("");
        this.setState({options});
    }

    @boundMethod
    public addTarget({value}: IUser) {
        const {onChange, values} = this.props;

        onChange([...values, {role: ERoles.None, target: value}]);
    }

    @boundMethod
    public updateTarget(item: IRoleAssignment, role: ERoles) {
        const {onChange, values} = this.props;
        const updated = [...values];
        updated[updated.indexOf(item)] = {target: item.target, role};
        onChange(updated);
    }

    @boundMethod
    public removeTarget(item: IRoleAssignment) {
        const {onChange, values} = this.props;
        const updated = [...values];
        updated.splice(updated.indexOf(item), 1);
        onChange(updated);
    }

    public render() {
        const {endpoint, values} = this.props;
        const existings = values.map((x) => x.target);
        const filtered = this.state.options.filter(
            (x) => existings.indexOf(x.value) < 0,
        );

        return (
            <div>
                <AsyncSelects<IUser>
                    className="mb-1"
                    inputId={endpoint}
                    selected={null}
                    options={filtered}
                    onSelected={this.addTarget}
                    retrieve={this.performSearch}
                    getDisplayName={this.getOptionLabel}
                />

                {this.renderAssignedRoles()}
            </div>
        );
    }

    @boundMethod
    private getOptionLabel(option: IUser) {
        return option.display;
    }

    private renderAssignedRoles() {
        const {values, endpoint} = this.props;

        if (!values?.length) {
            const message =
                endpoint === "users" ? (
                    <T>No user has been assigned to project</T>
                ) : (
                    <T>No group has been assigned to project</T>
                );

            return <div className="text-warning text-center">{message}</div>;
        }

        return (
            <table className="table table-sm table-striped table-borderless mb-0">
                <tbody>{this.renderTargets()}</tbody>
            </table>
        );
    }

    private renderTargets() {
        const {values, endpoint} = this.props;

        return values.map((x) => (
            <tr key={x.target}>
                <AssignedRole
                    idSuffix={`${endpoint}-${x.target}`}
                    value={x}
                    type={
                        endpoint === "users"
                            ? EDocumentTypes.User
                            : EDocumentTypes.Group
                    }
                    onChange={this.updateTarget}
                    onRemove={this.removeTarget}
                />
            </tr>
        ));
    }

    @boundMethod
    private async performSearch(query: string) {
        try {
            const response = await http
                .get("/api/" + this.props.endpoint, {
                    searchParams: {page: 1, pageSize: 20, query},
                })
                .json<ISearchResult>();

            return response.items.map(({displayName, name}) => ({
                display: displayName ?? name,
                value: name,
            }));
        } catch {
            return [];
        }
    }
}

export default RolesEditor;

import AboutConfig from "@/images/others/about-config.png";

import {boundMethod} from "autobind-decorator";
import React from "react";

import warning from "./services/warning";

import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface IAboutConfigModalState {
    show: boolean;
}

class AboutConfigModal extends React.PureComponent<{}, IAboutConfigModalState> {
    public readonly state: IAboutConfigModalState = {show: false};

    private readonly modal = React.createRef<Modal>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = warning.subscribe({
            aboutconfigWarningChanged: this.showModal,
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public showModal() {
        this.setState({show: true});
    }

    @boundMethod
    public afterClose() {
        this.setState({show: false});
    }

    public render() {
        if (!this.state.show) {
            return null;
        }

        return (
            <Modal
                ref={this.modal}
                header={<T>You need to enable copy to clipboard</T>}
                afterClose={this.afterClose}
            >
                <div className="modal-body">
                    <div>
                        <T>Your browser has copy to clipboard blocked.</T>
                    </div>
                    <div>
                        <T>
                            You can use a different browser to use that feature.
                        </T>
                    </div>
                    <div>
                        <T>
                            If you want to stay here, you need to enable copy to
                            clipboard.
                        </T>
                    </div>
                    <div>
                        <T>
                            Please open a new tab and enter the following url:
                        </T>
                        <kbd className="ml-1">about:config</kbd>
                    </div>
                    <div>
                        <T>Enter the following search param:</T>
                        <kbd className="ml-1">clipboardItem</kbd>
                    </div>
                    <div>
                        <T>Double click on it, to change the param to true.</T>
                    </div>
                    <img
                        className="img-fluid border my-3"
                        alt="about:config clipboardItem" // no translate here pls
                        src={AboutConfig}
                    />
                </div>

                <div className="modal-footer">
                    <CancelButton />
                </div>
            </Modal>
        );
    }
}

export default AboutConfigModal;

import React from "react";

import {BootstrapColors} from "@/components/template/models";

interface IDropdownButtonProps {
    type: BootstrapColors;
    idSuffix: string;
    menu(): JSX.Element | null;

    classNameButton?: string;
    dataTestId?: string;
    disabled?: boolean;
    isButtonGroup?: boolean;
    standAloneButton?: boolean;
    title?: string;
}

class DropdownButton extends React.PureComponent<IDropdownButtonProps> {
    public render() {
        const {isButtonGroup} = this.props;

        if (isButtonGroup) {
            return (
                <div className="dropdown btn-group">{this.renderButton()}</div>
            );
        }

        return this.renderButton();
    }

    private renderButton() {
        const {
            idSuffix,
            type,
            disabled,
            classNameButton,
            dataTestId,
            title,
            children,
            standAloneButton,
            menu,
        } = this.props;
        const id = "dropdown-" + idSuffix;

        let className = `btn btn-${type}`;
        if (!standAloneButton) {
            className += " dropdown-toggle";
        }

        if (classNameButton) {
            className += ` ${classNameButton}`;
        }

        return (
            <React.Fragment>
                <button
                    type="button"
                    id={id}
                    data-testid={dataTestId ?? id}
                    className={className}
                    disabled={disabled}
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle="dropdown"
                    title={title}
                >
                    {children}
                </button>
                <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby={id}
                >
                    {menu()}
                </div>
            </React.Fragment>
        );
    }
}

export default DropdownButton;

import {isEqual} from "lodash";

import {IMaterial} from "@/components/materials/models";
import {
    EConnection,
    EDataColorSchemes,
    EDetailedFingerprint,
    EFingerprintOrientations,
    EPressureUnits,
    ESampleColorSchemes,
    ESmoothing,
    IDeviceConnection,
} from "@/components/preferences/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {EDeviceClasses} from "@toolbox/models";
import networkDevices from "./network-devices";

import {hasRotation} from "@toolbox/functions/device-check";

export function shouldUseLandscape(
    orientation: EFingerprintOrientations,
    device: EDeviceClasses,
) {
    switch (orientation) {
        case EFingerprintOrientations.Landscape:
            return true;

        case EFingerprintOrientations.Portrait:
            return false;

        default:
            return hasRotation(device);
    }
}

// map every default device, either server has a matching one with safed prefs, or we will overwrite with default
function handleDeviceConnections(response: Partial<IUserPreferences>) {
    const deviceConnections = DEFAULT_VALUE.deviceConnections.map(
        (valueDef) =>
            response.deviceConnections?.find((valueRes) =>
                networkDevices.sameSeries(
                    {series: valueDef.series, fabrication: 0},
                    {series: valueRes.series, fabrication: 0},
                ),
            ) ?? valueDef,
    );

    response.deviceConnections = deviceConnections;
}

interface IEventHandler {
    regionChanged(value: string): void;
}

export interface IUserPreferences {
    colorbarScheme: EDataColorSchemes;
    detailedFingerprint: EDetailedFingerprint;
    deviceConnections: IDeviceConnection[];
    fingerprint: EDataColorSchemes;
    fingerprintOrientation: EFingerprintOrientations;
    fluid?: IMaterial;
    gateColorScheme: ESampleColorSchemes;
    particle?: IMaterial;
    region: string;
    sampleColorScheme: ESampleColorSchemes;
    smoothing: ESmoothing;
    unit: EPressureUnits;
}

const DEFAULT_VALUE: IUserPreferences = {
    colorbarScheme: EDataColorSchemes.Turbo,
    detailedFingerprint: EDetailedFingerprint.Detailed,
    deviceConnections: [
        {
            deviceClass: EDeviceClasses.XRay,
            series: 4350,
            connection: EConnection.Ethernet,
        },
    ],
    fingerprint: EDataColorSchemes.RdGn,
    fingerprintOrientation: EFingerprintOrientations.Auto,
    fluid: undefined,
    gateColorScheme: ESampleColorSchemes.Retro,
    particle: undefined,
    region: "",
    sampleColorScheme: ESampleColorSchemes.OldSchool,
    smoothing: ESmoothing.Average9,
    unit: EPressureUnits.Pa,
};

class PreferencesService {
    public value: IUserPreferences = DEFAULT_VALUE;

    private readonly events = new EventHandlers<IEventHandler>();

    public async setState(value: Partial<IUserPreferences>) {
        const updated = {...this.value, ...value};

        if (isEqual(this.value, updated)) {
            return true;
        }

        try {
            const response = await http
                .post("/api/prefs", {json: value})
                .json<Partial<IUserPreferences>>();

            this.value = {...DEFAULT_VALUE, ...response};
            this.setRegion(value.region);

            return true;
        } catch {
            return false;
        }
    }

    public async reset() {
        await this.setState(DEFAULT_VALUE);
        return this.value;
    }

    // just to reset user settings on log-out
    public silentReset() {
        this.value = DEFAULT_VALUE;
        this.setRegion(DEFAULT_VALUE.region);
    }

    public async retrieve() {
        try {
            const response = await http
                .get("/api/prefs", {cache: "no-cache"})
                .json<Partial<IUserPreferences>>();

            handleDeviceConnections(response);
            this.value = {...DEFAULT_VALUE, ...response};
        } catch {
            this.value = DEFAULT_VALUE;
        }

        this.setRegion(this.value.region);
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }

    private setRegion(region: string | undefined) {
        if (region !== undefined) {
            this.events.publish((x) => x.regionChanged(region));
        }
    }
}

const preferences = new PreferencesService();
export default preferences;

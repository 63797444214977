import {boundMethod} from "autobind-decorator";
import React from "react";

interface IPageNumberProps {
    value: number;
    active?: number;
    text?: JSX.Element;

    onClick(page: number): void;
}

class PageNumber extends React.PureComponent<IPageNumberProps> {
    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onClick, value} = this.props;
        onClick(value);
    }

    public render() {
        const {active, text, value} = this.props;

        let className = "page-item";
        if (value === active) {
            className += " active";
        }

        return (
            <li data-testid={"page-" + value} className={className}>
                <button
                    type="button"
                    className="page-link"
                    onClick={this.onClick}
                >
                    {text ?? value}
                </button>
            </li>
        );
    }
}

export default PageNumber;

import {floor} from "lodash";
import {IntlShape} from "react-intl";

import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import license from "@/services/license";
import signal from "@/services/signal";
import {IDevice, IDeviceId, ILicensedDevice} from "@toolbox/models";

import {
    device2DeviceApi,
    getDeviceDisplayName,
} from "@toolbox/functions/device-check";
import {intl2Str} from "@translate/T";

interface IEventHandler {
    devicesChanged?(devices: IDeviceStatus[]): void;
}

export interface ILastMeasurement {
    id: number;
    name: string;
    project: number;
}

export interface IDeviceStatus {
    device: IDeviceId;
    state: number;
    lastMessage: string;
    lastMeasurement: ILastMeasurement | null;

    isOnline: boolean;
}

export interface INetworkDeviceConfig {
    ipAddresses: string[];
    port: number;
}

class NetworkDevicesService {
    private readonly events = new EventHandlers<IEventHandler>();

    public config: INetworkDeviceConfig = {ipAddresses: [], port: 9000};
    private _devices: IDeviceStatus[] = [];

    public constructor() {
        signal.register({
            devicesChanged: (devices) => {
                this._devices = devices;
                this.events.publish((x) => x.devicesChanged?.(devices));
            },
        });
    }

    public get devices() {
        return this._devices;
    }

    public getDeviceName(intl: IntlShape, device: IDeviceId | ILicensedDevice) {
        const name = getDeviceDisplayName(device.deviceClass);
        const fabrication = device.fabrication;
        let series = device.series;

        // if fabrication is 0 and series ends with 0, it is considered a "series"
        if (!fabrication && !(series % 10)) {
            series = floor(series / 10);

            return intl2Str(intl, "{name} series {series}", {name, series});
        }

        return `${name} ${this.deviceIdString(device)}`;
    }

    public deviceIdString(value: IDevice) {
        return `${value.series}-${value.fabrication}`;
    }

    public sameDevice(device1: IDevice, device2: IDevice) {
        return this.deviceIdString(device1) === this.deviceIdString(device2);
    }

    public sameSeries(device1: IDevice, device2: IDevice) {
        return floor(device1.series / 10) === floor(device2.series / 10);
    }

    public canBeExtracted(device: IDeviceId) {
        return license.getSopLicences()[device2DeviceApi(device.deviceClass)];
    }

    public isLicensed(device: IDevice) {
        return !!license.value.devices.find(
            (x) =>
                (device.series === x.series &&
                    device.fabrication === x.fabrication) ||
                this.isGenericSameDevice(x, device),
        );
    }

    public isGenericSameDevice(generic: IDevice, device: IDevice) {
        return (
            generic.fabrication === 0 &&
            device.fabrication !== 0 &&
            this.sameSeries(generic, device)
        );
    }

    public findBestSuitedDevice(devices: IDeviceId[], value?: IDeviceId) {
        if (!devices.length) {
            return undefined;
        }

        if (!value) {
            return devices[0];
        }

        // find the one
        const exact = devices.find((x) => networkDevices.sameDevice(x, value));
        if (exact) {
            return exact;
        }

        // same generic (first 3 numbers are the same)
        const sameGeneric = devices.find((x) =>
            networkDevices.isGenericSameDevice(x, value),
        );
        if (sameGeneric) {
            return sameGeneric;
        }

        // same deviceClass
        const sameClass = devices.find(
            (x) => x.deviceClass === value.deviceClass,
        );
        if (sameClass) {
            return sameClass;
        }

        return devices[0];
    }

    public async retrieveConfig() {
        try {
            const config = await http
                .get("/api/devices/networkconfig", {cache: "no-cache"})
                .json<INetworkDeviceConfig>();

            this.config = config;

            return config;
        } catch {
            return null;
        }
    }

    public async retrieveDevices() {
        try {
            const devices = await http
                .get("/api/devices/status", {cache: "no-cache"})
                .json<IDeviceStatus[]>();

            this._devices = devices; // deviceStatus
        } catch {
            this._devices = [];
        }

        this.events.publish((x) => x.devicesChanged?.(this._devices));
    }

    public reset() {
        this.config = {ipAddresses: [], port: 9000};
        this._devices = [];
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const networkDevices = new NetworkDevicesService();
export default networkDevices;

import React from "react";
import {FormattedMessage} from "react-intl";

import {IMissingLicenseResponse, IServerErrorCode} from "./models";

import ErrorMessage from "@toolbox/design/ErrorMessage";
import T from "@translate/T";

export async function serverErrorCode(
    response: Response,
): Promise<IServerErrorCode> {
    switch (response.status) {
        case 404:
            return {notFound: true};

        case 405:
            const json = (await response.json()) as IMissingLicenseResponse;
            return {
                missingModuleLicenses: json.missingModuleLicenses,
                missingSopLicenses: json.missingSopLicenses,
            };

        case 406:
            return {corrupted: true};

        case 409:
            return {cannotBePaired: true};

        default:
            return {};
    }
}

interface INotFoundProps {
    item: JSX.Element;
    id?: string | number;
}

class NotFound extends React.PureComponent<INotFoundProps> {
    public render() {
        const {item} = this.props;
        const id = this.renderId();

        return (
            <ErrorMessage header={<T>Not found</T>}>
                <FormattedMessage
                    id="{item}{id} could not be found. It may have been deleted."
                    defaultMessage="{item}{id} could not be found. It may have been deleted."
                    values={{item, id}}
                />
            </ErrorMessage>
        );
    }

    private renderId() {
        const {id} = this.props;

        if (id === undefined) {
            return null;
        }

        return (
            <React.Fragment>
                {" "}
                (<em>{id}</em>)
            </React.Fragment>
        );
    }
}

export default NotFound;

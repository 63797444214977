import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";

export interface IInlineSwitchProps {
    idSuffix: string;
    checked: boolean;

    dataTestId?: string;
    disabled?: boolean;
    title?: ILocalizedText;

    toggle(e: React.ChangeEvent<HTMLInputElement>): void;
}

class InlineSwitch extends React.PureComponent<IInlineSwitchProps> {
    public render() {
        return <IntlContext.Consumer children={this.renderSwitch} />;
    }

    @boundMethod
    private renderSwitch(intl: IntlShape) {
        const {
            checked,
            children,
            dataTestId,
            disabled,
            idSuffix,
            title,
            toggle,
        } = this.props;
        const id = `switch-${idSuffix}`;

        return (
            <div className="custom-control custom-switch" title={title?.(intl)}>
                <input
                    type="checkbox"
                    className="custom-control-input"
                    disabled={disabled}
                    id={id}
                    data-testid={dataTestId ?? id}
                    checked={checked}
                    onChange={toggle}
                />
                <label className="custom-control-label" htmlFor={id}>
                    {children}
                </label>
            </div>
        );
    }
}

export default InlineSwitch;

import React from "react";

import {EFingerprintOrientations} from "./models";

import InlineRadios from "@toolbox/button-like/InlineRadios";
import T from "@translate/T";

interface IOrientationProps {
    value: EFingerprintOrientations;
    onChange(value: EFingerprintOrientations): void;
}

class Orientation extends React.PureComponent<IOrientationProps> {
    public render() {
        const {onChange, value} = this.props;

        return (
            <div className="form-row mb-3">
                <div className="col-sm-2">
                    <label>
                        <T>Fingerprint orientation</T>
                    </label>
                </div>

                <div className="col-sm-10">
                    <InlineRadios<EFingerprintOrientations>
                        idSuffix="fingerprint-orientation"
                        selected={value}
                        onSelected={onChange}
                        values={[
                            <T key={EFingerprintOrientations.Auto}>Auto</T>,
                            <T key={EFingerprintOrientations.Landscape}>
                                Always landscape
                            </T>,
                            <T key={EFingerprintOrientations.Portrait}>
                                Always portrait
                            </T>,
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Orientation;

import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {intl2Str} from "@translate/T";

export interface ISearchInputProps {
    autofocus?: boolean;

    query: string;
    onSearch(value: string): void;
}

class SearchInput extends React.PureComponent<ISearchInputProps> {
    private readonly input = React.createRef<HTMLInputElement>();

    public componentDidMount() {
        if (this.props.autofocus === false) {
            return;
        }

        this.input.current?.focus();
    }

    @boundMethod
    public setQuery(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.props.onSearch(e.target.value);
    }

    @boundMethod
    public triggerSearch(e: React.SyntheticEvent) {
        e.preventDefault();

        const {query, onSearch} = this.props;
        onSearch(query);
        this.input.current?.focus();
    }

    @boundMethod
    public handleKeydown(e: React.KeyboardEvent) {
        if (e.key !== "Enter") {
            return;
        }

        this.triggerSearch(e);
    }

    @boundMethod
    public clear(e: React.SyntheticEvent) {
        e.preventDefault();

        const input = this.input.current!;
        input.focus();

        if (input.value.trim() === "") {
            // Query already empty, clear it but don't trigger
            input.value = "";
            return;
        }

        // Clear search and trigger search
        input.value = "";
        this.props.onSearch(input.value);
    }

    public render() {
        return (
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <button
                        type="button"
                        aria-label="search"
                        id="btn-search"
                        onClick={this.triggerSearch}
                        className="btn btn-secondary"
                    >
                        <FontAwesomeIcon icon={faSearch} fixedWidth={true} />
                    </button>
                </div>

                <IntlContext.Consumer children={this.renderInput} />

                <span className="input-group-append">
                    <button
                        type="button"
                        aria-label="clear"
                        id="btn-delete"
                        onClick={this.clear}
                        className="btn btn-secondary"
                    >
                        <FontAwesomeIcon icon={faTimes} fixedWidth={true} />
                    </button>

                    {this.props.children}
                </span>
            </div>
        );
    }

    @boundMethod
    private renderInput(intl: IntlShape) {
        return (
            <input
                type="search"
                className="form-control"
                id="search-input"
                data-testid="search-input"
                ref={this.input}
                value={this.props.query}
                onChange={this.setQuery}
                placeholder={intl2Str(intl, "Search")}
                onKeyDown={this.handleKeydown}
            />
        );
    }
}

export default SearchInput;

import {faFlask, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import $ from "jquery";
import React from "react";
import {Link} from "react-router-dom";

import {ERoles} from "@/services/models";
import session from "@/services/session";
import {EDocumentTypes} from "../../models";

import ExecuteButton from "@shared/sop/ExecuteButton";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import NameChecker from "@toolbox/display-blocks/NameChecker";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface IAstmModalProps {
    id: number;
}

interface IAstmModalState {
    name: string;
    showModal: boolean;
}

class AstmModal extends React.PureComponent<IAstmModalProps, IAstmModalState> {
    public readonly state: IAstmModalState = {showModal: false, name: ""};

    private readonly form = React.createRef<ValidatedForm>();
    private readonly modal = React.createRef<Modal>();

    @boundMethod
    public updateName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showModal: true});
    }

    @boundMethod
    public afterClose() {
        this.setState({showModal: false, name: ""});
    }

    @boundMethod
    public submit() {
        this.form.current?.manualSubmit();
    }

    @boundMethod
    public execute() {
        const {id} = this.props;
        const {name} = this.state;
        if (!name.trim()) {
            return;
        }

        const base = document.querySelector("base")!;
        const baseUrl = base.href
            .replace("http://", "sepview://")
            .replace("https://", "sepview-secure://");

        const query = $.param({
            name: name.trim(),
            project: id,
            user: session.user,
        });

        location.href = baseUrl + "/api/centrifuge/astm?" + query;

        this.modal.current?.triggerClose();
    }

    public render() {
        if (!session.hasRole(ERoles.Recorder)) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderModal()}
                <button
                    type="button"
                    id="new-astm"
                    className="btn btn-secondary mr-1"
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon
                        icon={faFlask}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>ASTM D7827-12</T>
                </button>
            </React.Fragment>
        );
    }

    public renderModal() {
        const {name, showModal} = this.state;

        if (!showModal) {
            return null;
        }

        return (
            <Modal
                ref={this.modal}
                header={<T>Perform ASTM D7827-12 measurement</T>}
                afterClose={this.afterClose}
            >
                <ValidatedForm
                    ref={this.form}
                    suffixId="create-astm"
                    onSubmit={this.execute}
                >
                    <div className="modal-body">
                        <label htmlFor="analysis-name-trim">
                            <T>Sample name</T>
                        </label>

                        <input
                            type="text"
                            id="analysis-name-trim"
                            className="form-control"
                            required={true}
                            value={name}
                            onChange={this.updateName}
                        />
                        <div className="invalid-feedback">
                            <T>Analysis name is required!</T>
                        </div>

                        <NameChecker
                            name={name}
                            apiUrl={`/api/projects/${this.props.id}/namecheck`}
                            id={0}
                            type={EDocumentTypes.Analysis}
                        />
                    </div>
                    <div className="modal-footer">
                        <Link
                            to="/downloads"
                            className="mr-auto"
                            target="_blank"
                        >
                            <T>Download recorder</T>
                        </Link>
                        <ExecuteButton icon={faSave} onClick={this.submit}>
                            <T>Execute</T>
                        </ExecuteButton>

                        <CancelButton />
                    </div>
                </ValidatedForm>
            </Modal>
        );
    }
}

export default AstmModal;

import React from "react";
import {FormattedMessage, IntlShape, injectIntl} from "react-intl";

import license from "@/services/license";
import {IMissingLicense} from "./models";

import Barcode from "@/components/license/Barcode";
import {getDisplayModule} from "@/components/license/ModuleLicence";
import {sopLicenseDisplayName} from "@/components/license/SopFeatures";
import {getSepviewVersion} from "@/Footer";
import ErrorMessage from "@toolbox/design/ErrorMessage";
import T, {intl2Str} from "@translate/T";
import {getDeviceDisplayName} from "../functions/device-check";

interface IMissingLicenseProps extends IMissingLicense {
    intl: IntlShape;
}

interface IMissingLicenseState {
    hasMail: boolean;
}

class MissingLicense extends React.PureComponent<
    IMissingLicenseProps,
    IMissingLicenseState
> {
    public readonly state: IMissingLicenseState = {
        hasMail: false,
    };

    private get missingFeature() {
        const {
            intl,
            missingDeviceLicense,
            missingModuleLicenses,
            missingSopLicenses,
        } = this.props;
        const missingFeature: string[] =
            missingSopLicenses?.map((x) => sopLicenseDisplayName(x)(intl)) ??
            [];

        if (missingModuleLicenses) {
            missingFeature.push(
                ...missingModuleLicenses.map((x) => getDisplayModule(x)(intl)),
            );
        }

        if (missingDeviceLicense) {
            const missingDeviceLicenses = Array.isArray(missingDeviceLicense)
                ? missingDeviceLicense
                : [missingDeviceLicense];

            missingFeature.push(
                ...missingDeviceLicenses.map(
                    (x) =>
                        getDeviceDisplayName(x) +
                        " " +
                        intl2Str(intl, "device"),
                ),
            );
        }

        return missingFeature;
    }

    private get mail() {
        const {installationId, serialKey} = license.value;

        return (
            "mailto:info@lum-gmbh.de?subject=Request to extend License&body=" +
            "To Whom It May Concern, please contact me regarding an offer to upgrade my license." +
            `Feature: ${
                this.missingFeature
            }, Installation ID: ${installationId}, Serial key: ${
                serialKey ?? "not found"
            }, Version: ${getSepviewVersion()}`
        );
    }

    public render() {
        return (
            <React.Fragment>
                <ErrorMessage
                    header={<T>Missing license</T>}
                    extra={this.renderQRCode()}
                >
                    <FormattedMessage
                        id="You are missing some licenses and therefore do not have the permission to open this document. Your current license can be upgraded to include the desired feature, kindly contact your local LUM sales partner."
                        defaultMessage="You are missing some licenses and therefore do not have the permission to open this document. Your current license can be upgraded to include the desired feature, kindly contact your local LUM sales partner."
                    />
                    <ul className="mb-0">
                        {this.missingFeature.map((x, i) => (
                            <li key={i}>{x}</li>
                        ))}
                    </ul>
                </ErrorMessage>
            </React.Fragment>
        );
    }

    private renderQRCode() {
        return (
            <Barcode
                mail={this.mail}
                flavorText={
                    <T>You can request a new license on this computer.</T>
                }
            />
        );
    }
}

export default injectIntl(MissingLicense);

export const IDLE_DELAY = 250;
export const BUTTON_ANIMATION_DELAY = 2000;
export const CLOSING_DELAY = 10000;

// Workaround for every svg-download for Firefox.
export const USING_FIREFOX = window.navigator.userAgent.includes("Firefox");

// WARNING: DO NOT CHANGE ANY NAME HERE, because of Object.values() !!!
export enum EDeviceClasses {
    // LUMiFrac, 8/12 channels, no optical.
    Fracture,

    // LUMiFuge, 8 channels, single optical.
    Fuge,

    // LUMiSizer, 12 channels, single optical.
    Sizer,

    // LUMiSizer MWL, 12 channels, multiple opticals.
    SizerMwl,

    // LUMiReader, single channel, multiple opticals.
    Reader,

    // LUMiReader X-Ray, single channel, single optical.
    XRay,

    // LUMiSizer Nano, 12 channels, multiple optical.
    SizerNano,

    // LUMiSpoc.
    Spoc,
}

export interface IDevice {
    series: number;
    fabrication: number;
}

export interface ILicensedDevice extends IDevice {
    /** Do not use this for checks, but rather LicenseService.getSopLicences() */
    deviceClass: IDeviceClasses;
}

export interface IDeviceId extends IDevice {
    deviceClass: EDeviceClasses;
}

export interface IFakeMap<TValue> {
    [key: number | string]: TValue | undefined;
}

export type IDeviceClasses =
    | "LUMiFrac"
    | "LUMiFuge"
    | "LUMiReader X-Ray"
    | "LUMiReader"
    | "LUMiSizer"
    | "LUMiSpoc";

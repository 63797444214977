import "./InlineSelect.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {ISelectValue} from "./models";

import T, {intl2Num} from "@translate/T";

interface IInlineSelectProps<TValue> {
    values: TValue[];
    idSuffix: string;
    selected: TValue;
    convert?(value: TValue): ISelectValue;

    classNameDiv?: string;
    classNameLabel?: string;
    dataTestId?: string;
    disabled?: boolean;
    disableOnEmpty?: boolean;
    dynamicDropUpDown?: boolean;

    invalid?: boolean;
    invalidWarning?: boolean;

    label?: JSX.Element | ILocalizedText;
    title?: ILocalizedText;

    otherSelectDisplay?(intl: IntlShape, value: TValue): string;
    otherOptionDisplay?(intl: IntlShape, value: TValue): JSX.Element | string;

    onSelected(value: TValue, idSuffix: string): void;
}

interface IInlineSelectState {
    search: string;
    upwards: boolean;
}

class InlineSelect<TValue extends string | number> extends React.PureComponent<
    IInlineSelectProps<TValue>,
    IInlineSelectState
> {
    public readonly state: IInlineSelectState = {search: "", upwards: false};

    private readonly dropdown = React.createRef<HTMLDivElement>();
    private readonly options = React.createRef<HTMLDivElement>();
    private readonly select = React.createRef<HTMLInputElement>();
    private readonly onScroll = () => {
        const dropdown = this.dropdown.current;
        if (!dropdown) {
            return;
        }

        const rect = dropdown.getBoundingClientRect();
        const middle = rect.top + rect.height / 2;
        const upwards = middle > window.innerHeight / 2;
        this.setState({upwards});
    };

    private get id() {
        return "select-" + this.props.idSuffix;
    }

    public componentDidMount() {
        if (this.props.dynamicDropUpDown) {
            document.addEventListener("scroll", this.onScroll);
            this.onScroll();
        }
    }

    public componentWillUnmount() {
        if (this.props.dynamicDropUpDown) {
            document.removeEventListener("scroll", this.onScroll);
        }
    }

    @boundMethod
    public onDivClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const id = (e.target as HTMLElement).id;
        if (id.startsWith("div")) {
            this.select.current?.click();
        }
    }

    @boundMethod
    public onSelected(value: TValue) {
        const {onSelected, idSuffix} = this.props;

        this.hideOptions();
        this.setState({search: ""}, () => onSelected(value, idSuffix));
    }

    @boundMethod
    public updateSearchText(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({search: e.target.value});
    }

    @boundMethod
    public clearSearchText(e: React.FocusEvent) {
        const target = e.relatedTarget as Element | null;
        if (target && "BUTTON" === target.tagName) {
            const dropdown = target.closest(".dropdown");
            if (dropdown === this.dropdown.current) {
                return;
            }
        }

        this.hideOptions();
        this.setState({search: ""});
    }

    @boundMethod
    public handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case "Escape":
                e.preventDefault();

                this.hideOptions();
                this.setState({search: ""});
                break;

            case "Enter":
                e.preventDefault();

                const firstChild = this.options.current?.firstChild as
                    | HTMLElement
                    | null
                    | undefined;
                firstChild?.click();

                break;
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderLabel()}
                {this.renderDiv()}
            </React.Fragment>
        );
    }

    private renderLabel() {
        const {classNameLabel, label} = this.props;

        if (!label) {
            return null;
        }

        return (
            <label htmlFor={this.id} className={classNameLabel ?? "mr-1"}>
                {typeof label === "function" ? (
                    <IntlContext.Consumer children={label} />
                ) : (
                    label
                )}
            </label>
        );
    }

    private renderDiv() {
        const {classNameDiv} = this.props;

        return (
            <div className={classNameDiv}>
                <IntlContext.Consumer children={this.renderSelect} />
            </div>
        );
    }

    @boundMethod
    private renderSelect(intl: IntlShape) {
        const {
            dataTestId,
            disabled,
            invalid,
            invalidWarning,
            otherSelectDisplay,
            selected,
            title,
        } = this.props;
        const {search, upwards} = this.state;

        let className =
            "form-control from-control-inline inline-select-dropdown pointer-cursor";
        if (invalid) {
            className += " is-invalid";
        } else if (invalidWarning) {
            className += " is-invalid-warning";
        }

        return (
            <div
                ref={this.dropdown}
                id={"div-" + this.id}
                className={`${upwards ? "dropup" : "dropdown"} pointer-cursor`}
                styleName="mimic-select"
                onClick={this.onDivClick}
            >
                <input
                    ref={this.select}
                    type="text"
                    id={this.id}
                    className={className}
                    styleName="placeholder-input"
                    aria-expanded="false"
                    aria-haspopup="true"
                    autoComplete="off"
                    data-display="static"
                    data-testid={dataTestId ?? this.id}
                    data-toggle="dropdown"
                    disabled={disabled}
                    title={title?.(intl)}
                    value={search}
                    placeholder={
                        otherSelectDisplay?.(intl, selected) ??
                        this.convert(selected).name(intl)
                    }
                    onBlur={this.clearSearchText}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.updateSearchText}
                />

                {this.renderDropdown(intl)}
            </div>
        );
    }

    private renderDropdown(intl: IntlShape) {
        const {values} = this.props;
        const {search} = this.state;

        const filtered = values.filter((value) =>
            this.search(intl, this.convert(value), search),
        );

        return (
            <div
                ref={this.options}
                className="dropdown-menu dropdown-menu-right div-overflow-y"
                styleName="scrollable"
            >
                {!filtered.length && (
                    <div className="dropdown-item text-muted">
                        <T>No match found</T>
                    </div>
                )}
                {filtered.map((value) => this.renderOption(intl, value))}
            </div>
        );
    }

    private renderOption(intl: IntlShape, value: TValue) {
        const {selected, otherOptionDisplay} = this.props;
        const {id, name} = this.convert(value);
        const onClick = () => this.onSelected(value);

        let className = "dropdown-item px-2 py-0";
        if (value === selected) {
            className += " active";
        }

        return (
            <button
                type="button"
                key={id}
                id={"option-" + this.id + "-" + id}
                data-testid={"option-" + this.id + "-" + id}
                className={className}
                value={id}
                disabled={this.isDisabled(id)}
                styleName={this.isDisabled(id) ? "color-option" : undefined}
                onClick={onClick}
            >
                {otherOptionDisplay?.(intl, value) ?? name(intl)}
            </button>
        );
    }

    private convert(id: TValue): ISelectValue {
        const {convert} = this.props;

        const value = convert?.(id) ?? {
            id: id.toString(),
            name: (intl) => intl2Num(intl, id),
        };

        // e2e tests cannot work with "."
        value.id = value.id.replace(".", "");

        return value;
    }

    // disable option if key is empty and desired
    private isDisabled(id: string) {
        return this.props.disableOnEmpty && id === "";
    }

    private search(intl: IntlShape, value: ISelectValue, search: string) {
        const query = search.trim().toUpperCase();

        return (
            [value.name(intl), value.moresearchhelp?.(intl) ?? ""]
                .join(" ")
                .toUpperCase()
                .indexOf(query) >= 0
        );
    }

    private hideOptions() {
        const options = this.options.current;
        if (options) {
            const className = options.className;
            if (className?.endsWith(" show")) {
                options.className = className.replace(" show", "");
            }
        }
    }
}

export default InlineSelect;

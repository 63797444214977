import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import {ITemplateNameId} from "./models";

import AuditLog from "@toolbox/building-blocks/AuditLog";

interface ITemplateAuditProps {
    baseUrl: string;
}

class TemplateAudit extends React.PureComponent<ITemplateAuditProps> {
    public render() {
        return (
            <AuditLog<ITemplateNameId>
                endpoint="/api/audit/templates"
                renderEntry={this.renderEntry}
            />
        );
    }

    @boundMethod
    private renderEntry(doc: ITemplateNameId) {
        return (
            <Link to={`${this.props.baseUrl}/edit/${doc.id}`}>{doc.name}</Link>
        );
    }
}

export default TemplateAudit;

import React from "react";

import session from "@/services/session";

interface ISessionCheckState {
    hasSession: boolean;
}

class SessionCheck extends React.PureComponent<{}, ISessionCheckState> {
    public readonly state: ISessionCheckState = {
        hasSession: session.hasSession,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = session.subscribe({
            authenticated: () =>
                this.setState({hasSession: session.hasSession}),
            expired: () => this.setState({hasSession: session.hasSession}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {children} = this.props;
        const {hasSession} = this.state;
        if (!hasSession) {
            return null;
        }

        return children;
    }
}

export default SessionCheck;

import $ from "jquery";
import "signalr";

import {trimEnd} from "lodash";

import {IImportSession} from "@/components/project/import/models";
import {IChangedMeasurement} from "@/components/project/search/models";
import {SIGNAL_KEY, SIGNAL_LOGS_KEY} from "@/services/models";
import {IDeviceStatus} from "@/services/network-devices";
import {INumberOrNaN} from "@toolbox/functions/models";

import {parseNaNText} from "@toolbox/functions/requests";

interface IEventHandler {
    auditChanged?(): void;
    devicesChanged?(devices: IDeviceStatus[]): void;
    measurementsChanged?(measurements: IChangedMeasurement[]): void;
    sopQueueChanged?(numberOfQueued: number): void;
    hardDriveSpaceChanged?(value: number): void;
}

export type IImportHandler = (session: IImportSession) => void;

class SignalService {
    private static getBaseUrl() {
        const elements = document.getElementsByTagName("base");
        if (!elements.length) {
            return "";
        }

        const url = elements[0].getAttribute("href");
        if (!url) {
            return "";
        }

        return trimEnd(url, "/");
    }

    private readonly hub;
    private start?: Promise<void>;

    private readonly handlers = [] as IEventHandler[];
    private readonly imports = [] as IImportHandler[];

    public constructor() {
        const connection = ($ as any).hubConnection(SignalService.getBaseUrl());
        this.hub = connection.createHubProxy("notificationsHub");
        this.hub.connection.disconnected(() => {
            if (this.start !== undefined) {
                this.hub.connection.start();
            }
        });

        this.registerEvents();

        // this.hub.connection.stateChanged((state: any) => {
        //     console.log(state);
        // });
    }

    public isOnline() {
        return this.start !== undefined;
    }

    public toggleOnOff() {
        if (this.isOnline()) {
            this.disconnect2Backend();
            return;
        }

        this.connect2Backend();
    }

    // only call this if logged in
    public async connect2Backend() {
        if (
            this.start !== undefined ||
            localStorage.getItem(SIGNAL_KEY) === "true"
        ) {
            return; // do not do this twice without disconnect
        }

        this.start = this.hub.connection.start();
    }

    public disconnect2Backend() {
        this.start = undefined;
        this.hub.connection.disconnected(() => null);
        this.hub.connection.stop();
        // this.hub.connection?.transport?.abort(this.hub.connection);
    }

    public register(handler: IEventHandler) {
        const index = this.handlers.push(handler) - 1;

        return () => {
            delete this.handlers[index];
        };
    }

    public async registerImportHandler(
        clientId: string,
        handler: IImportHandler,
    ) {
        const imports = this.imports;
        const index = imports.push(handler) - 1;

        try {
            await this.start;

            const hub = this.hub;
            hub.invoke("subscribe", clientId);

            return () => {
                delete imports[index];
                hub.invoke("unsubscribe", clientId);
            };
        } catch (error) {
            delete imports[index];
            throw error;
        }
    }

    private registerEvents() {
        const hub = this.hub;

        hub.on("auditChanged", () => {
            this.shareSomeInfo("auditChanged", {});
            for (const handler of this.handlers) {
                handler?.auditChanged?.();
            }
        });

        hub.on("sopQueueChanged", (numberOfQueued: INumberOrNaN) => {
            this.shareSomeInfo("sopQueueChanged", {numberOfQueued});
            for (const handler of this.handlers) {
                handler?.sopQueueChanged?.(parseNaNText(numberOfQueued));
            }
        });

        hub.on(
            "measurementsChanged",
            (data: {measurements: IChangedMeasurement[]}) => {
                this.shareSomeInfo("measurementsChanged", data);
                for (const handler of this.handlers) {
                    handler?.measurementsChanged?.(data.measurements);
                }
            },
        );

        hub.on("importProgress", (_session: IImportSession) => {
            this.shareSomeInfo("importProgress", {_session});
            for (const handler of this.imports) {
                handler?.(_session);
            }
        });

        hub.on("devicesChanged", (data: {devices: IDeviceStatus[]}) => {
            this.shareSomeInfo("devicesChanged", {data});
            for (const handler of this.handlers) {
                handler?.devicesChanged?.(data.devices);
            }
        });

        hub.on("diskSpaceChanged", (data: {freeSpace: number}) => {
            this.shareSomeInfo("diskSpaceChanged", {data});
            for (const handler of this.handlers) {
                handler?.hardDriveSpaceChanged?.(data.freeSpace);
            }
        });
    }

    private shareSomeInfo(api: string, data: object) {
        if (localStorage.getItem(SIGNAL_LOGS_KEY) === "true") {
            // tslint:disable-next-line:no-console
            console.debug(api, data);
        }
    }
}

const signal = new SignalService();
export default signal;

import {boundMethod} from "autobind-decorator";
import parseISO from "date-fns/parseISO";
import {motion} from "framer-motion";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Link, RouteComponentProps} from "react-router-dom";

import {animation, onClosed, onOpen} from "@toolbox/design/models";
import {ITemplateModel} from "./models";

import Time from "@toolbox/design/Time";
import ContainerActivity from "@toolbox/display-blocks/ContainerActivity";
import ModuleIcon, {getModuleInfo} from "@toolbox/display-blocks/ModuleIcon";
import T from "@translate/T";
import TemplateAudit from "./TemplateAudit";
import TemplateSearch from "./TemplateSearch";
import TemplateTaskPanel from "./TemplateTaskPanel";

interface ITemplateListProps extends RouteComponentProps {
    intl: IntlShape;
    baseUrl: string;
}

interface ITemplateListState {
    templates: ITemplateModel[];
}

class TemplateList extends React.PureComponent<
    ITemplateListProps,
    ITemplateListState
> {
    public readonly state: ITemplateListState = {templates: []};

    @boundMethod
    public onChange(templates: ITemplateModel[]) {
        this.setState({templates});
    }

    public render() {
        return (
            <div className="container-fluid">
                <ContainerActivity
                    left={this.renderLeft()}
                    right={this.renderRight()}
                />
            </div>
        );
    }

    private renderLeft() {
        const {baseUrl, history, location, match} = this.props;

        return (
            <React.Fragment>
                <TemplateTaskPanel baseUrl={baseUrl} />

                <h1>
                    <T>Analysis templates</T>
                </h1>
                <hr />

                <TemplateSearch
                    history={history}
                    location={location}
                    match={match}
                    onChange={this.onChange}
                >
                    {this.renderTemplates()}
                </TemplateSearch>
            </React.Fragment>
        );
    }

    private renderRight() {
        return <TemplateAudit baseUrl={this.props.baseUrl} />;
    }

    @boundMethod
    private renderTemplates() {
        const {baseUrl, intl} = this.props;
        return this.state.templates.map((x, i) => {
            const info = getModuleInfo(x.engine);

            return (
                <motion.tr
                    key={i}
                    animate={onOpen}
                    exit={onClosed}
                    initial={onClosed}
                    transition={animation}
                >
                    <td>
                        <Link
                            data-testid={"templates-link-" + x.id}
                            to={`${baseUrl}/edit/${x.id}`}
                            title={info.type(intl)}
                        >
                            <ModuleIcon icon={info.icon} /> {x.name}
                        </Link>
                    </td>
                    <td data-testid={"templates-engine-" + x.id}>
                        {info.type(intl)}
                    </td>
                    <td data-testid={"templates-modified-" + x.id}>
                        <Time value={parseISO(x.modified)} />
                    </td>
                </motion.tr>
            );
        });
    }
}

export default injectIntl(TemplateList);

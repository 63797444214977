import "./SopFeatures.module.scss";

import {compressToEncodedURIComponent} from "lz-string";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

import {ESopLicenses, ILicenseDisplay} from "@/components/license/models";
import {IDeviceId, ILicensedDevice} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";

import Card from "@toolbox/design/Card";
import {
    deviceName2Device,
    hasSingleChannel,
    isCentEngine,
} from "@toolbox/functions/device-check";
import T, {intl2Str} from "@translate/T";

export function displayLicenseFeatures(
    intl: IntlShape,
    x: ILicenseDisplay,
    i: number,
) {
    if (
        !!x.missing.missingModuleLicenses?.length ||
        !!x.missing.missingSopLicenses?.length
    ) {
        const data = compressToEncodedURIComponent(JSON.stringify(x.missing));
        return null; // ToDo: delete this line in #2577

        return (
            <li
                key={i}
                title={intl2Str(
                    intl,
                    "Available for purchase, click to request",
                )}
            >
                <Link styleName="link-color-stale" to={`/missing?data=${data}`}>
                    <em>{x.name}</em>
                </Link>
            </li>
        );
    }

    return <li key={i}>{x.name}</li>;
}

export function sopLicenseDisplayName(license: ESopLicenses): ILocalizedText {
    switch (license) {
        case ESopLicenses.Fast:
            return (intl) => intl2Str(intl, "Interval below 5 seconds");

        case ESopLicenses.Long:
            return (intl) => intl2Str(intl, "Duration above 42 hours");

        case ESopLicenses.Magnet:
            return (intl) => intl2Str(intl, "Magnet cell holders");

        case ESopLicenses.Template:
            return (intl) => intl2Str(intl, "Analysis template");

        case ESopLicenses.Unlimited:
            return (intl) =>
                intl2Str(
                    intl,
                    "Unlimited maximum interval, number of profiles and duration",
                );

        case ESopLicenses.TemperatureRamp:
            return (intl) => intl2Str(intl, "Temperature ramp");

        case ESopLicenses.SectorShapedCells:
            return (intl) => intl2Str(intl, "Sector Shaped Cell Types (SSC)");

        default:
            return (intl) => intl2Str(intl, "Unknown feature");
    }
}

function getLicensedSop(devices: ILicensedDevice[]): ESopLicenses[] {
    const mapped: IDeviceId[] = devices.map((x) => ({
        ...x,
        deviceClass: deviceName2Device(x.deviceClass),
    }));

    const licensed: ESopLicenses[] = [];
    if (mapped.some((x) => isCentEngine(x.deviceClass))) {
        if (mapped.some((x) => !hasSingleChannel(x.deviceClass))) {
            licensed.push(ESopLicenses.Fast, ESopLicenses.Long);
        }

        licensed.push(
            ESopLicenses.Magnet,
            ESopLicenses.SectorShapedCells,
            ESopLicenses.TemperatureRamp,
            ESopLicenses.Template,
            ESopLicenses.Unlimited,
        );
    }

    return licensed;
}

interface ISopFeaturesProps {
    intl: IntlShape;
    value: ESopLicenses[];
    devices: ILicensedDevice[];
}

class SopFeatures extends React.PureComponent<ISopFeaturesProps> {
    public render() {
        return (
            <Card
                className="h-100"
                headerClassName="bg-secondary"
                title={<T>SOP features</T>}
                noMargin={true}
            >
                <div className="card-body" data-testid="licensed-sop-features">
                    <ul className="list-unstyled m-0">
                        {this.renderLicenses()}
                    </ul>
                </div>
            </Card>
        );
    }

    private renderLicenses() {
        const {intl, value, devices} = this.props;
        const licensed = getLicensedSop(devices);

        if (!licensed.length) {
            return (
                <em className="text-muted">
                    <T>None</T>
                </em>
            );
        }

        const sorted: ILicenseDisplay[] = licensed
            .map((x) => ({
                name: sopLicenseDisplayName(x)(intl),
                missing: {
                    missingSopLicenses: !value.includes(x) ? [x] : undefined,
                },
            }))
            .sort((a, b) => a.name.localeCompare(b.name));

        return sorted.map((x, i) => displayLicenseFeatures(intl, x, i));
    }
}

export default injectIntl(SopFeatures);

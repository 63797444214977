import {boundMethod} from "autobind-decorator";
// tslint:disable-next-line:no-implicit-dependencies
import {PrimitiveType} from "intl-messageformat";
import React from "react";
import {FormatNumberOptions, IntlContext, IntlShape} from "react-intl";

import {getTranslator} from "./Provider";

export function intl2Str(
    intl: IntlShape,
    values: string | string[],
    options?: Record<string, PrimitiveType>,
    seporator: string = " ",
): string {
    return (!Array.isArray(values) ? [values] : values)
        .map((x) => intl.formatMessage({defaultMessage: x, id: x}, options))
        .join(seporator);
}

export function intl2Num(
    intl: IntlShape,
    values: string | number | (number | string)[],
    decimal?: number | undefined,
    options: FormatNumberOptions = {},
    seperator: string = " ",
): string {
    if (decimal !== undefined) {
        options.maximumFractionDigits = decimal;
        options.minimumFractionDigits = decimal;
    }

    const tmp = !Array.isArray(values) ? [values] : values;

    const forceScientific = options.notation === "scientific";
    const noPrinting = options.useGrouping !== false;
    const noOtherNotation = options.notation === undefined;
    if (
        forceScientific ||
        (noPrinting && noOtherNotation && isScientificNum(tmp))
    ) {
        // "useGrouping" as an indicator that number shall be raw
        options.notation = "scientific";
        options.maximumFractionDigits = 3;
        options.minimumFractionDigits = undefined;
        options.maximumSignificantDigits = undefined;
        options.minimumSignificantDigits = undefined;
    }

    if (
        options.maximumFractionDigits === undefined &&
        options.maximumSignificantDigits === undefined &&
        options.minimumFractionDigits === undefined &&
        options.minimumSignificantDigits === undefined
    ) {
        // Fyi: it seems that intl will round after 3 digits, if nothing is provided.
        options.maximumFractionDigits = 16;
    }

    if (intl.locale.includes("-")) {
        intl = getTranslator("en-" + intl.locale.split("-")[1]);
    }

    return tmp
        .map((x) =>
            typeof x !== "string"
                ? customNumIntl(intl.formatNumber(x, options))
                : x,
        )
        .join(seperator);
}

export function parseNumber(text: string): number {
    text = text.trim().replace(/,/g, ".");
    return Number(text);
}

export function isScientificNum(tmp: (string | number)[]): boolean {
    return !!tmp.find((x) => typeof x === "number" && x >= 1000000);
}

export function getDecimal(intl: IntlShape) {
    return intl2Num(intl, 0.1).replace(/[01]/g, "");
}

function customNumIntl(value: string): string {
    if (!value.replace(/-0[,.]*[0]*/, "")) {
        value = value.replace("-", "");
    }

    if (value.includes("E-")) {
        value = value.replace("E", "e");
    }

    if (value.includes("E")) {
        value = value.replace("E", "e+");
    }

    return value;
}

interface ITProps extends Record<string, PrimitiveType | React.ReactElement> {
    // Props used only using text extraction
    context?: string;

    /** Same as `id` and `defaultMessage` for `FormattedMMesasage` */
    children: string;
}

class T extends React.PureComponent<ITProps> {
    @boundMethod
    public renderContent(intl: IntlShape) {
        const {children, ...others} = this.props;
        let nodes = intl.formatMessage(
            {id: children, defaultMessage: children},
            others,
        ) as string | React.ReactNodeArray;
        if (!Array.isArray(nodes)) {
            nodes = [nodes];
        }

        // Suppress React warning about unique keys
        return React.createElement(React.Fragment, null, ...nodes);
    }

    public render() {
        return <IntlContext.Consumer children={this.renderContent} />;
    }
}

export default T;

import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link, Redirect} from "react-router-dom";

import {getVerificationName} from "@/components/project/DeleteProject";
import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";

import DeleteButton from "@toolbox/button-like/DeleteButton";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import Loading from "@toolbox/render-page/Loading";
import NotFound from "@toolbox/render-page/NotFound";
import T, {parseNumber} from "@translate/T";
import DeleteTemplateList from "./DeleteTemplateList";
import {getTemplateWithId} from "./EditTemplate";

interface IDeleteTemplateProps {
    id: string;
    baseUrl: string;
}

interface IDeleteTemplateState {
    name: string;
    couldNotDelete: boolean;
    isDeleted: boolean;
    isLoading: boolean;
    isNotFound: boolean;
    userInput: string;
}

class DeleteTemplate extends React.PureComponent<
    IDeleteTemplateProps,
    IDeleteTemplateState
> {
    public readonly state: IDeleteTemplateState = {
        name: "",
        couldNotDelete: false,
        isDeleted: false,
        isLoading: true,
        isNotFound: false,
        userInput: "",
    };

    public componentDidMount() {
        this.retrieveProject();
    }

    private get isConfirmed() {
        const {userInput, name} = this.state;

        return userInput === getVerificationName(name);
    }

    @boundMethod
    public setUserInput(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({userInput: e.target.value});
    }

    @boundMethod
    public async delete() {
        const {id} = this.props;

        try {
            const response = await http.delete("/api/template/" + id);

            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            this.setState({isDeleted: true});

            return true;
        } catch {
            return false;
        }
    }

    public render() {
        const {baseUrl, id} = this.props;
        const {isDeleted, isLoading, isNotFound, name, userInput} = this.state;

        if (isLoading) {
            return <Loading />;
        }

        if (isNotFound) {
            return <NotFound id={id} item={<T>The specified template</T>} />;
        }

        if (isDeleted || !session.hasRole(ERoles.Editor)) {
            return <Redirect to={baseUrl} push={true} />;
        }

        const required = <kbd>{getVerificationName(name)}</kbd>;
        const nameElement = <em className="text-warning">{name}</em>;

        return (
            <ContainerCol col={6}>
                <h1>
                    <T>Delete template</T>
                </h1>
                <hr />

                <div className="form-text">
                    <b>
                        <FormattedMessage
                            id="You are going to delete template {name} and all its data. This action cannot be reversed."
                            defaultMessage="You are going to delete template {name} and all its data. This action cannot be reversed."
                            values={{name: nameElement}}
                        />
                    </b>
                </div>

                <div className="mb-3">
                    <label htmlFor="template-name">
                        <FormattedMessage
                            id="Enter the template name {required} in the textbox below to confirm."
                            defaultMessage="Enter the template name {required} in the textbox below to confirm."
                            values={{required}}
                        />
                    </label>
                    <input
                        type="text"
                        id="template-name"
                        data-testid="template-name"
                        autoComplete="off"
                        className="form-control"
                        required={true}
                        value={userInput}
                        onChange={this.setUserInput}
                    />
                </div>

                <DeleteTemplateList id={id} />

                <div className="d-flex">
                    <DeleteButton
                        id="delete-template"
                        classNameButton="mr-auto"
                        disabled={!this.isConfirmed}
                        noAnimation={true}
                        skipConfirm={true}
                        onDelete={this.delete}
                    />

                    <Link to={baseUrl}>
                        <button type="button" className="btn btn-secondary">
                            <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            <T>Cancel</T>
                        </button>
                    </Link>
                </div>
            </ContainerCol>
        );
    }

    private async retrieveProject() {
        const ele = await getTemplateWithId(parseNumber(this.props.id));
        if (!ele) {
            this.setState({isLoading: false, isNotFound: true});
            return;
        }

        this.setState({isLoading: false, name: ele.name});
    }
}

export default DeleteTemplate;

import {boundMethod} from "autobind-decorator";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import parseISO from "date-fns/parseISO";
import ky from "ky";
import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";

import EventHandlers from "@/services/handlers";
import display from "@/services/display-name";
import license from "@/services/license";
import {EExpireReasons, SESSION_KEY, ERoles} from "@/services/models";
import networkDevices from "@/services/network-devices";
import preferences from "@/services/preferences";
import realtime from "@/services/realtime";
import signal from "@/services/signal";

import {bitwiseAND} from "@toolbox/functions/bitwise";

interface IEventHandler {
    expired?(reason: EExpireReasons): void;
    authenticated?(session: ISession): void;
}

export interface ISessionClaims {
    /** Global role. */
    global: ERoles;

    /** Project roles. */
    projects: {[project: number]: ERoles};

    /** Hidden project roles, that will not include normal roles. */
    hiddens: {[project: number]: ERoles};
}

export interface ISession {
    /** User ID. */
    user: string;

    /** Authentication token. */
    token: string;

    /** Token expiry time in UTC. */
    expiry: string;

    /** Session effective claims. */
    claims: ISessionClaims;
}

export class SessionService {
    /**
     * Gets the logged in user session.
     * Only available if user has logged in.
     */
    private session?: ISession;

    private timer: number = 0;
    private readonly handlers = new EventHandlers<IEventHandler>();

    public constructor() {
        this.session = SessionService.loadSession();

        if (this.session) {
            this.scheduleRefresh();
        }
    }

    private static isValid(value: ISession) {
        return parseISO(value.expiry) > new Date();
    }

    private static loadSession(): ISession | undefined {
        const json = localStorage.getItem(SESSION_KEY);
        if (!json) {
            return;
        }

        try {
            const value = JSON.parse(
                decompressFromEncodedURIComponent(json),
            ) as ISession;
            if (!SessionService.isValid(value)) {
                return;
            }

            return value;
        } catch {
            localStorage.removeItem(SESSION_KEY);
        }
    }

    /** Checks if user has a session */
    public get hasSession() {
        return this.session !== undefined;
    }

    /** Gets current session's claims */
    public get claims() {
        return this.session?.claims;
    }

    /** Gets current session's token */
    public get token() {
        return this.session?.token;
    }

    /** Gets current user's ID */
    public get user() {
        return this.session?.user;
    }

    @boundMethod
    public override(global: ERoles) {
        const local = this.session;
        if (!local) {
            return;
        }

        const claims: ISessionClaims = {...local.claims, global};
        const newValue: ISession = {...local, claims};

        this.setSession(newValue);
        window.location.reload();
    }

    public async setSession(value: ISession | undefined) {
        if (!value) {
            // fallback for Startup.tsx
            value = this.session;
            if (!value) {
                return;
            }
        }

        if (!SessionService.isValid(value)) {
            this.expired();
            return;
        }

        await this.login(value);
        this.handlers.publish((x) => x.authenticated?.(value!));
    }

    /** Notifies that the token has expired */
    public async expired() {
        await this.logout();
        this.handlers.publish((x) => x.expired?.(EExpireReasons.Expired));
    }

    public async clear() {
        await this.logout();
        this.handlers.publish((x) => x.expired?.(EExpireReasons.LoggedOut));
    }

    public clearLocalStorage() {
        localStorage.clear();
    }

    /** Determines if the logged in user has the specified role */
    public hasRole(role: ERoles, project?: number) {
        const claims = this.claims;
        if (!claims) {
            return false;
        }

        // Global admin can do everything.
        if (claims.global >= ERoles.GlobalAdministrator) {
            return true;
        }

        // special project roles have prio, normal claims will be "overwritten"
        if (project !== undefined) {
            const assigned: ERoles | undefined = claims.hiddens[project];
            if (assigned !== undefined) {
                return bitwiseAND(assigned, role) === role;
            }
        }

        if (bitwiseAND(claims.global, role) === role) {
            return true;
        }

        if (!project) {
            return false;
        }

        return (
            bitwiseAND(claims.projects[project] ?? ERoles.None, role) === role
        );
    }

    public canEditThisUser(affectedUser: ERoles) {
        if (session.hasRole(ERoles.GlobalAdministrator)) {
            return true;
        }

        return affectedUser < ERoles.GlobalAdministrator;
    }

    /**
     * Subscribes to be notified when authentication session changes.
     * Returns function to dispose subscription.
     */
    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }

    public async refreshToken(existing?: string) {
        const token = this.session?.token ?? existing;
        if (!token) {
            return;
        }

        try {
            const response = await ky
                .get("/api/auth/refresh", {
                    cache: "no-cache",
                    headers: {Authorization: "Token " + token},
                })
                .json<ISession>();

            if (!SessionService.isValid(response)) {
                return;
            }

            this.session = response;
            this.setSession(response);
        } catch {
            this.clear();
            return;
        }
    }

    private async login(value: ISession) {
        this.session = value;
        localStorage.setItem(
            SESSION_KEY,
            compressToEncodedURIComponent(JSON.stringify(value)),
        );
        this.scheduleRefresh();

        // services ready
        await license.retrieveLicense();
        await networkDevices.retrieveConfig();
        await networkDevices.retrieveDevices();
        await preferences.retrieve();
        await realtime.retrieve();
        await signal.connect2Backend();
    }

    private async logout() {
        this.session = undefined;
        localStorage.removeItem(SESSION_KEY);
        window.clearTimeout(this.timer);

        // services reset
        license.reset();
        networkDevices.reset();
        preferences.silentReset();
        realtime.silentReset();
        signal.disconnect2Backend();

        // remove data that got added over time
        display.deleteAll();
    }

    private scheduleRefresh() {
        window.clearTimeout(this.timer);
        const active = this.session!;
        const timeout = differenceInMilliseconds(
            parseISO(active.expiry),
            new Date(),
        );

        this.timer = window.setTimeout(() => this.refreshToken(), timeout);
    }
}

const session = new SessionService();
export default session;

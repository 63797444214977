import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {CLICK} from "@shared/compare/chart/models";
import {
    HTML_PRINT_KEY,
    HTTP_LOGS_KEY,
    ERoles,
    SIGNAL_KEY,
    SIGNAL_LOGS_KEY,
} from "./services/models";
import resize from "./services/resize";
import session from "./services/session";
import signal from "./services/signal";

import InlineSwitch from "@toolbox/button-like/InlineSwitch";
import NumberInput from "@toolbox/nativ-inputs/NumberInput";
import {getLongDate} from "@toolbox/design/Time";
import {intl2Str} from "@translate/T";
import FooterWarning from "./components/hard-drice-space/FooterWarning";
import LicenseWarning from "./components/LicenseWarning";
import SessionCheck from "./SessionCheck";

export function getSepviewVersion() {
    return "SEPView" + trademark() + " " + APP_VERSION;
}

export function trademark() {
    return "\u00ae";
}

interface IFooterProps {
    intl: IntlShape;
}

interface IFooterState {
    fixed: string;
    showT: boolean;
}

class Footer extends React.PureComponent<IFooterProps, IFooterState> {
    public readonly state: IFooterState = {showT: false, fixed: ""};

    private readonly footer = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = resize.subscribe(document.body, this.calcFixed);
        this.calcFixed();
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public show(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (CLICK(e)) {
            e.preventDefault();

            this.setState({showT: !this.state.showT});
        }
    }

    @boundMethod
    public update(e: React.ChangeEvent<HTMLInputElement>) {
        const id = e.target.id.split("-")[1];
        if (e.target.checked) {
            localStorage.setItem(id, "true");
        } else {
            localStorage.removeItem(id);
        }

        switch (id) {
            case SIGNAL_KEY:
                signal.toggleOnOff();
                return;

            default:
                // not desired
                // window.location.reload();
                return;
        }
    }

    public render() {
        const {fixed} = this.state;

        return (
            <footer
                ref={this.footer}
                className={`container-fluid${fixed} pb-2`} // padding, hence it will be counted towards height
            >
                <hr />
                <SessionCheck>
                    <LicenseWarning />
                    <FooterWarning />
                </SessionCheck>

                {this.renderFooter()}
            </footer>
        );
    }

    private renderFooter() {
        if (!this.state.showT) {
            return this.renderInfo();
        }

        return (
            <div className="form-row">
                <div className="col-lg-3">{this.renderInfo()}</div>
                <div className="col-lg-2">
                    <NumberInput
                        id="session-override"
                        decimals={0}
                        min={ERoles.None}
                        max={ERoles.GlobalAdministrator}
                        value={session.claims?.global ?? 0}
                        onChange={session.override}
                    />
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={SIGNAL_KEY}
                        checked={localStorage.getItem(SIGNAL_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Stop signal</React.Fragment>
                    </InlineSwitch>
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={HTTP_LOGS_KEY}
                        checked={localStorage.getItem(HTTP_LOGS_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Http logs</React.Fragment>
                    </InlineSwitch>
                    <InlineSwitch
                        idSuffix={SIGNAL_LOGS_KEY}
                        checked={localStorage.getItem(SIGNAL_LOGS_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Signal logs</React.Fragment>
                    </InlineSwitch>
                </div>
                <div className="col-lg-2">
                    <InlineSwitch
                        idSuffix={HTML_PRINT_KEY}
                        checked={localStorage.getItem(HTML_PRINT_KEY) !== null}
                        toggle={this.update}
                    >
                        <React.Fragment>Html print</React.Fragment>
                    </InlineSwitch>
                </div>
            </div>
        );
    }

    private renderInfo() {
        return (
            <React.Fragment>
                <span
                    onDoubleClick={this.show}
                    title={
                        getLongDate(new Date(BUILD_TIME)) + " / " + BUILD_HASH
                    }
                >
                    {getSepviewVersion()}
                </span>
                <br />
                {intl2Str(this.props.intl, "Copyright {build}", {
                    build: BUILD_YEAR,
                })}
                <React.Fragment> &copy; </React.Fragment>
                <a
                    href="http://www.lum-gmbh.com/"
                    rel="noreferrer"
                    target="_blank"
                >
                    LUM GmbH
                </a>
            </React.Fragment>
        );
    }

    @boundMethod
    private calcFixed() {
        let _body = document.body.clientHeight;
        const _window = window.innerHeight;
        const _footer = this.footer.current?.clientHeight ?? 0;

        // if no classname, meaning just plain footer, substract it from body
        if (!this.state.fixed) {
            _body -= _footer;
        }

        const fixed = _window > _body + _footer ? " fixed-bottom" : "";
        this.setState({fixed});
    }
}

export default injectIntl(Footer);

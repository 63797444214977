import React from "react";

import ActivityFlyoutButton from "@toolbox/building-blocks/ActivityFlyoutButton";
import TasksPanel from "@toolbox/building-blocks/TasksPanel";

interface ITemplateTaskPanelProps {
    baseUrl: string;
}

class TemplateTaskPanel extends React.PureComponent<ITemplateTaskPanelProps> {
    public render() {
        return (
            <TasksPanel>
                <div className="btn-toolbar mr-auto" />

                <ActivityFlyoutButton />
            </TasksPanel>
        );
    }
}

export default TemplateTaskPanel;

import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";

interface IFlyoutButtonProps {
    intl: IntlShape;
    left: boolean;
    title: ILocalizedText;
    className?: string;

    onClick(e: React.SyntheticEvent): void;
}

class FlyoutButton extends React.PureComponent<IFlyoutButtonProps> {
    public render() {
        const {intl, left, title, className, onClick} = this.props;
        const icon = left ? faAngleDoubleLeft : faAngleDoubleRight;
        const cName = `btn btn-secondary${className ? " " + className : ""}`;

        return (
            <button
                type="button"
                className={cName}
                onClick={onClick}
                title={title(intl)}
            >
                <FontAwesomeIcon icon={icon} fixedWidth={true} size="lg" />
            </button>
        );
    }
}

export default injectIntl(FlyoutButton);

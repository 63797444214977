import React from "react";

interface IContainerColProps {
    col: 4 | 6 | 8 | 10;
}

class ContainerCol extends React.PureComponent<IContainerColProps> {
    public render() {
        const {col, children} = this.props;
        const lg = Math.min(12, col + 2);
        const md = Math.min(12, lg + 2);
        const sm = Math.min(12, md + 2);

        return (
            <div className="container-fluid">
                <div className={`form-row my-${col === 8 ? "3" : "5"}`}>
                    <div
                        className={`col-xl-${col} offset-xl-${
                            (12 - col) / 2
                        } col-lg-${lg} offset-lg-${
                            (12 - lg) / 2
                        } col-md-${md} offset-md-${
                            (12 - md) / 2
                        } col-sm-${sm} offset-sm-${(12 - sm) / 2}`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContainerCol;

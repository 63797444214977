import {EAuditActions} from "@/components/admin/audit/models";
import {IUserInfoModel} from "@/components/admin/models";
import {EDocumentTypes} from "@/components/project/models";
import {ISampleBaseModel} from "@shared/models";
import {EDeviceClasses} from "../models";

export const pagingDefault: IPaging = {page: 1, pageSize: 20, total: 0};

export enum EActivities {
    Hidden,
    Visible,
}

export enum EFloatStates {
    Hidden,
    SlideDown,
    Visible,
    SlideUp,
}

export interface IAuditEntry<TDoc = never> {
    id: number;
    action: EAuditActions;
    author: IUserInfoModel;
    time: string;
    document: TDoc;
}

export interface IPagedList<T> {
    items: T[];
    page: number;
    total: number;
    suggestions?: string[];
}

export interface IPaging {
    page: number;
    pageSize: number;
    total: number;
}

export interface IProjectDocumentModel extends IBaseDocumentModel {
    id: string;
    project: number;
}

export interface IBaseDocumentModel {
    name: string;

    type: EDocumentTypes;
    deviceClass?: EDeviceClasses;
}

export type ICompareFunction = (
    a: ISampleBaseModel,
    b: ISampleBaseModel,
) => number;

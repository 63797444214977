import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import AsyncSelect from "react-select/async";
import {OptionTypeBase, ValueType} from "react-select/src/types";

import {customStyles} from "./models";

import T, {intl2Str} from "@translate/T";

interface IAsyncSelectsProps<TValue> {
    selected: TValue | null;
    options: TValue[];

    className?: string;
    dataTestId?: string;
    inputId?: string;

    onSelected(value: TValue, id?: string): void;
    retrieve(query: string): void;
    getDisplayName(option: TValue): string;
}

class AsyncSelects<TValue extends OptionTypeBase> extends React.PureComponent<
    IAsyncSelectsProps<TValue>
> {
    @boundMethod
    public onSelected(value: ValueType<TValue>) {
        const {inputId, onSelected} = this.props;
        if (value) {
            // AsyncSelect sometimes throws random undefiend, therefore we need to catch those.
            onSelected(!Array.isArray(value) ? value : value[0], inputId);
        }
    }

    public render() {
        return <IntlContext.Consumer children={this.renderIntl} />;
    }

    @boundMethod
    private renderIntl(intl: IntlShape) {
        const {
            className,
            dataTestId,
            inputId,
            selected,
            options,
            retrieve,
            getDisplayName,
        } = this.props;
        const loadingMessage = () => intl2Str(intl, "Loading...");
        const noOptionsMessage = () => intl2Str(intl, "No match.");

        return (
            <AsyncSelect<TValue>
                className={className}
                data-testid={dataTestId ?? inputId}
                inputId={inputId}
                value={selected}
                cacheOptions={true}
                styles={customStyles}
                defaultOptions={options}
                placeholder={<T>Please select...</T>}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
                onChange={this.onSelected}
                loadOptions={retrieve}
                getOptionLabel={getDisplayName}
            />
        );
    }
}

export default AsyncSelects;

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";

interface IInlineRadioProps<TValue> {
    value: TValue;
    idSuffix: string;
    isSelected: boolean;

    dataTestId?: string;
    disabled?: boolean;
    title?: ILocalizedText;

    onSelected(value: TValue): void;
}

class InlineRadio<TValue> extends React.PureComponent<
    IInlineRadioProps<TValue>
> {
    @boundMethod
    public onSelected() {
        const {onSelected, value} = this.props;
        onSelected(value);
    }

    public render() {
        return <IntlContext.Consumer children={this.renderRadio} />;
    }

    @boundMethod
    private renderRadio(intl: IntlShape) {
        const {children, dataTestId, idSuffix, isSelected, disabled, title} =
            this.props;
        const id = "radio-" + idSuffix;

        return (
            <div className="form-check form-check-inline" title={title?.(intl)}>
                <input
                    type="radio"
                    className="form-check-input checkbox-primary"
                    id={id}
                    data-testid={dataTestId ?? id}
                    checked={isSelected}
                    disabled={disabled}
                    onChange={this.onSelected}
                />
                <label htmlFor={id} className="form-check-label">
                    {children}
                </label>
            </div>
        );
    }
}

export default InlineRadio;

import React from "react";

import resize from "@/services/resize";
import {IPaging} from "@toolbox/building-blocks/models";

import Pager from "@toolbox/building-blocks/Pager";
import PageSize from "@toolbox/building-blocks/PageSize";

interface IPagerPageSizeFooterProps extends IPaging {
    setPage(page: number): void;
    setPageSize(pageSize: number): void;
}

interface IPagerPageSizeFooterState {
    width: number;
}

class PagerPageSizeFooter extends React.PureComponent<
    IPagerPageSizeFooterProps,
    IPagerPageSizeFooterState
> {
    public readonly state: IPagerPageSizeFooterState = {width: 0};

    private readonly div = React.createRef<HTMLDivElement>();

    private unsubscribe?: () => void;

    public async componentDidMount() {
        this.unsubscribe = resize.subscribe(this.div.current!, (rect) =>
            this.setState({width: rect.width}),
        );
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {page, total, pageSize, setPage, setPageSize} = this.props;
        const {width} = this.state;

        return (
            <div className="modal-footer d-flex justify-content-between">
                <div style={{width}} />

                <Pager
                    page={page}
                    total={total}
                    pageSize={pageSize}
                    onChange={setPage}
                />

                <div ref={this.div}>
                    <PageSize value={pageSize} onChange={setPageSize} />
                </div>
            </div>
        );
    }
}

export default PagerPageSizeFooter;

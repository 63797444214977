import astm from "@/images/icons/astm.svg";
import concentration from "@/images/icons/concentration.svg";
import filtration from "@/images/icons/filtration.svg";
import firstDerivative from "@/images/icons/first-derivative.svg";
import frontTracking from "@/images/icons/front-tracking.svg";
import instability from "@/images/icons/instability.svg";
import integration from "@/images/icons/integration.svg";
import lumview from "@/images/icons/lumview.svg";
import mwl from "@/images/icons/mwl.svg";
import psa from "@/images/icons/psa.svg";
import ratio from "@/images/icons/ratio.svg";
import sedimentation from "@/images/icons/sedimentation.svg";

import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {EModules} from "@/models";
import {IModuleInfo} from "./models";

import {trademark} from "@/Footer";
import {intl2Str} from "@translate/T";

export function getModuleInfo(engine: EModules): IModuleInfo {
    switch (engine) {
        case EModules.Frac:
            return {
                icon: "missing",
                type: (intl) => intl2Str(intl, "LUMiFrac analysis"),
            };

        default:
        case EModules.LumView:
            return {
                icon: lumview,
                type: (intl) =>
                    intl2Str(intl, "LUMView{trademark} analysis", {
                        trademark: trademark(),
                    }),
            };

        case EModules.Instability:
            return {
                icon: instability,
                type: (intl) => intl2Str(intl, "Stability analysis"),
            };

        case EModules.Integration:
            return {
                icon: integration,
                type: (intl) => intl2Str(intl, "Integration analysis"),
            };

        case EModules.FrontTracking:
            return {
                icon: frontTracking,
                type: (intl) => intl2Str(intl, "Front tracking analysis"),
            };
        case EModules.PsaVelocityPos:
        case EModules.PsaVelocityTime:
            return {
                icon: psa,
                type: (intl) =>
                    intl2Str(intl, "Velocity distribution analysis"),
            };

        case EModules.PsaIntensityPos:
        case EModules.PsaIntensityTime:
            return {
                icon: psa,
                type: (intl) =>
                    intl2Str(intl, "Extinction distribution analysis"),
            };

        case EModules.PsaVolumeWeightedPos:
        case EModules.PsaVolumeWeightedTime:
            return {
                icon: psa,
                type: (intl) => intl2Str(intl, "Volume distribution analysis"),
            };

        case EModules.PsaNumberWeightedPos:
        case EModules.PsaNumberWeightedTime:
            return {
                icon: psa,
                type: (intl) => intl2Str(intl, "Number distribution analysis"),
            };

        case EModules.Filtration:
            return {
                icon: filtration,
                type: (intl) => intl2Str(intl, "Filtration analysis"),
            };

        case EModules.SedimentationHeight:
            return {
                icon: sedimentation,
                type: (intl) => intl2Str(intl, "Sedimentation height analysis"),
            };

        case EModules.FirstDerivative:
            return {
                icon: firstDerivative,
                type: (intl) => intl2Str(intl, "First derivative analysis"),
            };

        case EModules.MwlRatio:
            return {
                icon: ratio,
                type: (intl) => intl2Str(intl, "MWL extinction ratio analysis"),
            };

        case EModules.Astm:
            return {
                icon: astm,
                type: (intl) =>
                    intl2Str(intl, "SEPCalc ASTM D7827-12 analysis"),
            };

        case EModules.PsaMwlVolumeWeighted:
            return {
                icon: mwl,
                type: (intl) =>
                    intl2Str(intl, "MWL volume distribution analysis"),
            };

        case EModules.PsaMwlNumberWeighted:
            return {
                icon: mwl,
                type: (intl) =>
                    intl2Str(intl, "MWL number distribution analysis"),
            };

        case EModules.SpocView:
            return {
                icon: lumview,
                type: (intl) => intl2Str(intl, "Spoc View analysis"),
            };

        case EModules.SpocAlignment:
            return {
                icon: lumview,
                type: (intl) => intl2Str(intl, "Alignment View"),
            };

        case EModules.SpocCount:
            return {
                icon: concentration,
                type: (intl) =>
                    intl2Str(intl, "Count & Concentration analysis"),
            };

        case EModules.SpocSizeVolume:
            return {
                icon: psa,
                type: (intl) => intl2Str(intl, "Volume distribution analysis"),
            };

        case EModules.SpocSizeNumber:
            return {
                icon: psa,
                type: (intl) => intl2Str(intl, "Number distribution analysis"),
            };
    }
}

interface IModuleIconProps {
    intl: IntlShape;
    icon: string;
}

class ModuleIcon extends React.PureComponent<IModuleIconProps> {
    public render() {
        const {intl, icon} = this.props;

        return (
            <img
                alt={intl2Str(intl, "Module icon")}
                src={icon}
                height="24px"
                width="24px"
            />
        );
    }
}

export default injectIntl(ModuleIcon);

import {IMissingLicenseResponse} from "@toolbox/render-page/models";
import {INumberDocumentModel} from "../search/models";

export enum EImportProgress {
    Pending,
    Processing,
    Imported,
    NotImported,
    FileTooLarge,
}

export enum EMissingImport {
    None = 0,
    MissingAnalysisData = 1,
    DoubledDocument = 2,
}

export interface IImportFile extends IMissingLicenseResponse {
    importedDocs: INumberDocumentModel[];
    name: string;
    progress: EImportProgress;

    missingMaterials?: string[];
    missing?: EMissingImport;
}

export interface IImportSession {
    clientId: string;
    files: IImportFile[];
}

import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ERoles} from "@/services/models";
import session from "@/services/session";
import {EServerId} from "../../models";
import {IModuleGroup} from "../analyze-button/models";

import T from "@translate/T";
import AnalyzeModal from "./AnalyzeModal";

interface IAnalyzeButtonProps {
    project: number;
    serverId: EServerId;

    className?: string;
    hrefTarget?: string;

    getModules(targets: string): Promise<IModuleGroup[]>;
    getTargets(): Promise<string>;
}

interface IAnalyzeButtonState {
    modules: IModuleGroup[];
    targets: string;
}

class AnalyzeButton extends React.PureComponent<
    IAnalyzeButtonProps,
    IAnalyzeButtonState
> {
    public readonly state: IAnalyzeButtonState = {modules: [], targets: ""};

    private readonly ref = React.createRef<AnalyzeModal>();

    @boundMethod
    public async onClick() {
        const {getModules, getTargets} = this.props;
        const targets = await getTargets();
        const modules = await getModules(targets.split("&storage=")[0]);
        this.setState({modules, targets});
    }

    @boundMethod
    public afterClose() {
        this.setState({targets: ""});
    }

    public render() {
        const {project, className} = this.props;

        if (!session.hasRole(ERoles.Editor, project)) {
            return null;
        }

        let _className = "btn btn-secondary";
        if (className) {
            _className += ` ${className}`;
        }

        return (
            <React.Fragment>
                <button
                    type="button"
                    id="analyze-button"
                    className={_className}
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon
                        icon={faFlask}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Analyze</T>
                </button>

                {this.renderModal()}
            </React.Fragment>
        );
    }

    private renderModal() {
        const {project, hrefTarget, serverId} = this.props;
        const {modules, targets} = this.state;

        if (!targets) {
            return null;
        }

        return (
            <AnalyzeModal
                ref={this.ref}
                hrefTarget={hrefTarget}
                modules={modules}
                project={project}
                serverId={serverId}
                targets={targets}
                afterClose={this.afterClose}
            />
        );
    }
}

export default AnalyzeButton;

import {INumberOrNaN} from "./models";

export function parseNaNText(value: INumberOrNaN | null | undefined): number {
    return typeof value === "number" ? value : NaN;
    // return value === "NaN" ? NaN : value;
}

export function parseNaNtoText(value: number): INumberOrNaN {
    return isNaN(value) ? "NaN" : value;
}

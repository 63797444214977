import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import {STORAGE_KEY} from "./models";

interface IEventHandler {
    localeChanged(
        language: string,
        messages: Record<string, string> | undefined,
    ): void;
}

class IntlLocaleService {
    public language: string = "en";
    public messages: Record<string, string> | undefined = {};

    private readonly cachedMessages = new Map<
        string,
        Record<string, string> | undefined
    >();
    private readonly handlers = new EventHandlers<IEventHandler>();

    public async setLanguage(language: string) {
        if (language === this.language) {
            return;
        }

        await this.loadMessages(language);
        localStorage.setItem(STORAGE_KEY, language);

        this.handlers.publish((x) =>
            x.localeChanged?.(language, this.messages),
        );
    }

    public async loadUserPreferredLanguage(_language?: string) {
        const language = _language ?? localStorage.getItem(STORAGE_KEY) ?? "en";
        if (language === this.language) {
            return;
        }

        await this.loadMessages(language);
        this.handlers.publish((x) =>
            x.localeChanged?.(language, this.messages),
        );
    }

    // button will use this, to get new translation
    public async requestNewTranslation() {
        try {
            const response = await http.post("/api/languages/new", {
                timeout: false,
            });
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            await this.resetCache();

            return true;
        } catch {
            return false;
        }
    }

    // button will tell backend, it shall do auto updates from time to time
    public async autoTranslation(auto: boolean) {
        try {
            const response = await http.post("/api/languages/auto", {
                json: {auto},
            });
            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            return true;
        } catch {
            return false;
        }
    }

    // will be called after user has put new translation intp manual drag&drop input
    public async resetCache() {
        this.cachedMessages.clear();
        await this.loadUserPreferredLanguage();
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }

    private async download(language: string) {
        if (language === "en") {
            return {};
        }

        try {
            return await http
                .get("/api/languages/" + language)
                .json<Record<string, string>>();
        } catch {
            return {};
        }
    }

    private async loadMessages(language: string) {
        if (!this.cachedMessages.has(language)) {
            const messages = await this.download(language);

            this.cachedMessages.set(language, messages);
        }

        this.messages = this.cachedMessages.get(language)!;
        this.language = language;

        document.documentElement.lang = this.language;
    }
}

const intlLocale = new IntlLocaleService();
export default intlLocale;

import _ar from "date-fns/locale/ar";
import _at from "date-fns/locale/de-AT";
import _au from "date-fns/locale/en-AU";
import _be from "date-fns/locale/be";
import _br from "date-fns/locale/pt-BR";
import _ca from "date-fns/locale/ca";
import _ch from "date-fns/locale/fr-CH";
import _cn from "date-fns/locale/zh-CN";
import _de from "date-fns/locale/de";
import _es from "date-fns/locale/es";
import _fi from "date-fns/locale/fi";
import _fr from "date-fns/locale/fr";
import _gb from "date-fns/locale/en-GB";
import _hk from "date-fns/locale/zh-HK";
import _id from "date-fns/locale/id";
import _ie from "date-fns/locale/en-IE";
import _in from "date-fns/locale/en-IN";
import _it from "date-fns/locale/it";
import _jp from "date-fns/locale/ja";
import _nl from "date-fns/locale/nl";
import _nz from "date-fns/locale/en-NZ";
import _pl from "date-fns/locale/pl";
import _pt from "date-fns/locale/pt";
import _ru from "date-fns/locale/ru";
import _sk from "date-fns/locale/sk";
import _th from "date-fns/locale/th";
import _tr from "date-fns/locale/tr";
import _tw from "date-fns/locale/zh-TW";
import _us from "date-fns/locale/en-US";
import {IntlShape} from "react-intl";

import {IDate} from "./models";

const dates: IDate[] = [
    {id: "AO"},
    {id: "AR", locale: _ar},
    {id: "AT", locale: _at},
    {id: "AU", locale: _au},
    {id: "BE", locale: _be},
    {id: "BR", locale: _br},
    {id: "CA", locale: _ca},
    {id: "CH", locale: _ch},
    {id: "CN", locale: _cn},
    {id: "CO"},
    {id: "CZ"},
    {id: "DE", locale: _de},
    {id: "DK"},
    {id: "ES", locale: _es},
    {id: "FI", locale: _fi},
    {id: "FR", locale: _fr},
    {id: "GB", locale: _gb},
    {id: "GR"},
    {id: "HK", locale: _hk},
    {id: "ID", locale: _id},
    {id: "IE", locale: _ie},
    {id: "IL"},
    {id: "IN", locale: _in},
    {id: "IT", locale: _it},
    {id: "JP", locale: _jp},
    {id: "KR"},
    {id: "LU"},
    {id: "MX"},
    {id: "MY"},
    {id: "MZ"},
    {id: "NL", locale: _nl},
    {id: "NO"},
    {id: "NZ", locale: _nz},
    {id: "PH"},
    {id: "PL", locale: _pl},
    {id: "PT", locale: _pt},
    {id: "RU", locale: _ru},
    {id: "SE"},
    {id: "SG"},
    {id: "SK", locale: _sk},
    {id: "TH", locale: _th},
    {id: "TR", locale: _tr},
    {id: "TW", locale: _tw},
    {id: "US", locale: _us},
];

function getLocale(intl: IntlShape): globalThis.Locale {
    const parts = intl.locale.split("-");
    const date = dates.find((x) => x.id === parts[1])?.locale;

    if (!date) {
        // hardest part here not to forget:
        // if we add new language, we have to add it here as well
        switch (parts[0]) {
            default:
            case "en":
                return _us;

            case "de":
                return _de;

            case "fr":
                return _fr;
        }
    }

    return date;
}

export default getLocale;

import EventHandlers from "@/services/handlers";

interface IEventHandler {
    triggerChanged?(id: string): void;
    // can be added more
}

class TriggerService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    public trigger(id: string) {
        this.handlers.publish((x) => x.triggerChanged?.(id));
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }
}

const trigger = new TriggerService();
export default trigger;

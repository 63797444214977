export enum ETimeUnit {
    Seconds,
    Minutes,
    Hours,
    Days,
    Months,
}

export enum ELengthUnit {
    Nanometer,
    Micrometer,
    Millimeter,
}

import React from "react";
import {GRID_GAP} from "./models";

interface IGridDeckProps {
    className?: string;
    style?: React.CSSProperties;

    gap?: number;
    cols?: number;
    rows?: number;
}

class GridDeck extends React.PureComponent<IGridDeckProps> {
    public readonly grid = React.createRef<HTMLDivElement>();

    public render() {
        const {children, className, cols, gap, rows, style} = this.props;

        const gridStyle: React.CSSProperties = {
            display: "grid",
            gap: `${gap ?? GRID_GAP}px`,
            gridTemplateColumns: `repeat(${cols ?? 2}, 1fr)`,
            gridTemplateRows: `repeat(${rows ?? 2}, 1fr)`,
        };

        return (
            <div
                ref={this.grid}
                id="grid-deck"
                data-testid="grid-deck"
                className={className}
                style={{...gridStyle, ...style}}
            >
                {children}
            </div>
        );
    }
}

export default GridDeck;

import ky, {Options} from "ky";
import NProgress from "nprogress";

import loading from "@/services/loading";
import {HTTP_LOGS_KEY, ELoadingStatus} from "@/services/models";
import session from "@/services/session";

import {princePhillip, sleepingBeauty} from "@/services/sleep";

const http = ky.extend({
    headers: {Accept: "application/json"},
    hooks: {
        afterResponse: [
            async (_, options: Options, response) => {
                NProgress.done();

                // Check if token has expired
                const token = session.token;
                if (!token) {
                    return;
                }

                if (localStorage.getItem(HTTP_LOGS_KEY) === "true") {
                    const request =
                        options.method === "GET"
                            ? options.searchParams
                            : options.json;
                    const info = [options.method + " " + response.url, request];

                    try {
                        // tslint:disable-next-line:no-console
                        console.debug(...info, await response.json());
                    } catch {
                        // tslint:disable-next-line:no-console
                        console.debug(...info, "no json -> no readable");
                    }
                }

                if ((options as Options).timeout !== 60000) {
                    loading.status = response.ok
                        ? ELoadingStatus.Success
                        : ELoadingStatus.Failed;

                    if (loading.count > 0) {
                        await sleepingBeauty();
                    }

                    await princePhillip();
                }

                const headers = response.headers;
                const expired = headers.get("X-Token-Expired");
                if (expired !== token) {
                    return;
                }

                session.expired();
            },
        ],
        beforeRequest: [
            async (request, options: Options) => {
                NProgress.configure({showSpinner: false});
                NProgress.start();

                // override default timeout
                if (options.timeout === 10000) {
                    options.timeout = 60000;
                }

                if (options.timeout !== 60000) {
                    loading.status = ELoadingStatus.Waiting;
                }

                // Appends token to all requests
                const token = session.token;
                const current = request.headers.get("Authorization");
                if (token && !current) {
                    request.headers.set("Authorization", "Token " + token);
                }
            },
        ],
    },
});

export default http;

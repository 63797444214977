class EventHandlers<THandler> {
    private readonly handlers = [] as THandler[];

    public register(handler: THandler) {
        const index = this.handlers.push(handler) - 1;

        return () => {
            delete this.handlers[index];
        };
    }

    public publish(action: (handler: THandler) => void) {
        for (const handler of this.handlers) {
            if (handler) {
                action(handler);
            }
        }
    }

    public dispose() {
        this.handlers.splice(0);
    }
}

export default EventHandlers;

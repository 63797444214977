import "katex/dist/katex.css";

import katex from "katex";
import {MathNode, parse} from "mathjs";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import T, {getDecimal} from "@translate/T";

export function toTex(intl: IntlShape, root: MathNode, doE: boolean) {
    return root
        .toTex({
            handler: (node: MathNode) => {
                if (node.type === "SymbolNode") {
                    switch (node.name) {
                        case "T":
                            return "\\theta";

                        case "L":
                            if (doE) {
                                return "E";
                            }

                            return "\\lambda";
                    }
                }

                return node.value;
            },
            parenthesis: "auto",
        })
        .toString()
        .replace(/\./g, getDecimal(intl))
        .replace(/\\cdot/g, "⋅");
}

interface IMathBlockProps {
    intl: IntlShape;
    value: string; // the latex string to display as formula
    usesEnergy: boolean;
}

interface IMathBlockState {
    isValid: boolean;
}

class MathBlock extends React.PureComponent<IMathBlockProps, IMathBlockState> {
    public readonly state: IMathBlockState = {isValid: true};

    private readonly span = React.createRef<HTMLSpanElement>();

    public componentDidMount() {
        this.update();
    }

    public componentDidUpdate() {
        this.update();
    }

    public render() {
        return (
            <React.Fragment>
                <span ref={this.span} />
                {this.renderError()}
            </React.Fragment>
        );
    }

    private renderError() {
        if (this.state.isValid) {
            return null;
        }

        return (
            <em id="text-danger" className="text-danger">
                <T>Function is invalid!</T>
            </em>
        );
    }

    private update() {
        const {intl, value, usesEnergy} = this.props;
        const span = this.span.current;
        if (!span) {
            return;
        }

        try {
            const node = parse(value);
            const latex = toTex(intl, node, usesEnergy);

            katex.render(latex, span);
            this.setState({isValid: true});
        } catch {
            this.setState({isValid: false});
        }
    }
}

export default injectIntl(MathBlock);

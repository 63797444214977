import {boundMethod} from "autobind-decorator";
import MarkdownIt from "markdown-it";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {Titled} from "react-titled";

import http from "@/services/http";
import fileUrl from "./eula.md";

import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T, {intl2Str} from "@translate/T";

interface IEulaProps {
    intl: IntlShape;
}

interface IEulaState {
    eula: string;
}

class Eula extends React.PureComponent<IEulaProps, IEulaState> {
    public readonly state: IEulaState = {eula: ""};

    private readonly md = new MarkdownIt({breaks: true});

    public async componentDidMount() {
        try {
            const file = await http.get(fileUrl);
            const eula = await file.text();
            this.setState({eula});
        } catch {
            this.setState({eula: ""});
        }
    }

    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(
            this.props.intl,
            "End User License Agreement | {parent}",
            {parent},
        );
    }

    public render() {
        return (
            <Titled title={this.getTitle}>
                <ContainerCol col={8}>
                    <h1>
                        <T>End user license agreement</T>
                    </h1>
                    <hr />
                    {this.renderContent()}
                </ContainerCol>
            </Titled>
        );
    }

    private renderContent() {
        const {eula} = this.state;
        if (!eula) {
            return null;
        }

        const html = this.md.render(eula);
        return <div dangerouslySetInnerHTML={{__html: html}} />;
    }
}

export default injectIntl(Eula);

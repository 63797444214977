import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {IItemSelectionState, INestedBaseItem} from "../models";
import {ISample, ISelectTarget} from "./models";

import T, {intl2Str, parseNumber} from "@translate/T";
import {getSelection, isSelected} from "../SearchItem";
import DirectGroup from "./DirectGroup";

export interface ISpocSamplesProps {
    project: number;
    selection: IItemSelectionState;
    items: INestedBaseItem[] | undefined;

    onChange(selection: IItemSelectionState): void;
}

class SpocSamples extends React.PureComponent<ISpocSamplesProps> {
    private get samples(): null | ISample[] {
        const {project, selection, items} = this.props;
        const spocItems = items?.filter((x) =>
            x.id.startsWith("spocMeasures/"),
        );

        if (!spocItems?.length) {
            return null;
        }

        return spocItems.map((x) => {
            const [_, parent, type, series, repetition, started] =
                x.id.split("/");

            const id = [parent, series, repetition].join("-");
            const url = `/project/${project}/spoc/measurement/${parent}/${series}/${repetition}`;

            return {
                id,
                url,
                type,
                name: x.name,
                prefixName: x.prefixName,
                started,
                series: parseNumber(series),
                repetition: parseNumber(repetition),
                selected: isSelected(id, selection),
            };
        });
    }

    @boundMethod
    public onSelected(e: ISelectTarget) {
        const samples = this.samples;
        if (!samples) {
            return;
        }

        const {checked, id} = e.target;
        let targetIds = id;
        if (!Array.isArray(targetIds)) {
            targetIds = [targetIds];
        }

        for (const targetId of targetIds) {
            for (const sample of samples) {
                if (targetId.includes(sample.id)) {
                    sample.selected = checked;
                }
            }
        }

        this.props.onChange(getSelection(samples));
    }

    public render() {
        const samples = this.samples;
        if (!samples) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderBlanks(samples)}
                {this.renderSamples(samples)}
            </React.Fragment>
        );
    }

    private renderBlanks(samples: ISample[]) {
        const blanks = samples.filter(
            (x) => x.series === 0 && x.repetition === 0,
        );

        if (!blanks.length) {
            return null;
        }

        return (
            <DirectGroup samples={blanks} onChange={this.onSelected}>
                <T>Blank</T>
            </DirectGroup>
        );
    }

    private renderSamples(samples: ISample[]) {
        const allSeries = samples.filter((x) => x.series > 0);

        if (!allSeries.length) {
            return null;
        }

        const seriess: ISample[][] = [];
        for (const serie of allSeries) {
            // if the last entry has diffrent series, start new array entry
            if (
                !seriess.length ||
                seriess[seriess.length - 1][0].series !== serie.series
            ) {
                seriess.push([serie]);
            } else {
                seriess[seriess.length - 1].push(serie);
            }
        }

        if (seriess.length === 1) {
            return (
                <DirectGroup samples={seriess[0]} onChange={this.onSelected}>
                    <T>Sample</T>
                </DirectGroup>
            );
        }

        return seriess.map((series, i) => {
            const text = (intl: IntlShape) =>
                intl2Str(intl, "Sample {series}", {
                    series: series[0].prefixName?.split(".")[0],
                });

            return (
                <DirectGroup
                    key={i}
                    samples={series}
                    onChange={this.onSelected}
                >
                    <IntlContext.Consumer children={text} />
                </DirectGroup>
            );
        });
    }
}

export default SpocSamples;

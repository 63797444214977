import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import T from "@translate/T";

interface ISearchSuggestionProps {
    suggestions?: string[];
    onClick(suggestion: string): void;
}

class SearchSuggestion extends React.PureComponent<ISearchSuggestionProps> {
    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        this.props.onClick((e.target as HTMLElement).id);
    }

    public render() {
        const {suggestions} = this.props;
        if (!suggestions?.length) {
            return null;
        }

        const elements = suggestions.map((x, i) => (
            <Link key={i} id={x} to="" onClick={this.onClick}>
                <em id={x}>{x}</em>
            </Link>
        ));

        return (
            <small>
                <T>Did you mean:</T> {elements}
            </small>
        );
    }
}

export default SearchSuggestion;

import {
    faCheckSquare,
    faMinusSquare,
    faSquare,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ECheckStates} from "@toolbox/button-like/models";

import DropdownButton from "@toolbox/button-like/DropdownButton";
import T from "@translate/T";

interface ITriStateSplitButtonProps {
    value: ECheckStates;

    onSelectType(type: string): void;
    onClick(selectAll: boolean): void;
}

class TriStateSplitButton extends React.PureComponent<ITriStateSplitButtonProps> {
    @boundMethod
    public onSelectType(e: React.SyntheticEvent<HTMLButtonElement>) {
        e.preventDefault();

        const type = e.currentTarget.dataset.docType;
        if (!type) {
            return;
        }

        this.props.onSelectType(type);
    }

    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {onClick, value} = this.props;
        const selectAll = value !== ECheckStates.Checked;
        onClick(selectAll);
    }

    private get icon() {
        switch (this.props.value) {
            default:
            case ECheckStates.NotChecked:
                return faSquare;

            case ECheckStates.Indeterminate:
                return faMinusSquare;

            case ECheckStates.Checked:
                return faCheckSquare;
        }
    }

    public render() {
        return (
            <div className="btn-group">
                <button
                    type="button"
                    data-testid="triStateSplitButton"
                    className="btn btn-secondary"
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon icon={this.icon} fixedWidth={true} />
                </button>

                <DropdownButton
                    type="secondary"
                    idSuffix="tri-state-split"
                    classNameButton="dropdown-toggle-split"
                    menu={this.renderMenu}
                />
            </div>
        );
    }

    @boundMethod
    private renderMenu() {
        return (
            <React.Fragment>
                <h6 className="dropdown-header">
                    <T>Select documents</T>
                </h6>

                <button
                    type="button"
                    data-doc-type="sop"
                    className="dropdown-item"
                    onClick={this.onSelectType}
                >
                    <T>SOPs</T>
                </button>
                <button
                    type="button"
                    data-doc-type="measurement"
                    className="dropdown-item"
                    onClick={this.onSelectType}
                >
                    <T>Measurements</T>
                </button>
                <button
                    type="button"
                    data-doc-type="analysis"
                    className="dropdown-item"
                    onClick={this.onSelectType}
                >
                    <T>Analyses</T>
                </button>
            </React.Fragment>
        );
    }
}

export default TriStateSplitButton;

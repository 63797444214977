import "katex/dist/katex.css";

import katex from "katex";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {intl2Num} from "@translate/T";
import {FACTOR} from "./models";

interface IFormulaProps {
    intl: IntlShape;
}

class Formula extends React.PureComponent<IFormulaProps> {
    private readonly span = React.createRef<HTMLSpanElement>();

    public componentDidMount() {
        this.renderLatex();
    }

    public componentDidUpdate() {
        this.renderLatex();
    }

    public render() {
        return <span ref={this.span} />;
    }

    private renderLatex() {
        const {intl} = this.props;
        const span = this.span.current;
        if (!span) {
            return;
        }

        const factor = intl2Num(intl, FACTOR);
        const formula = `\\mathit{RCA} = {\\left( \\dfrac{\\mathit{RPM}}{1000} \\right)}^2 \\times r \\times ${factor}`;

        katex.render(formula, span);
    }
}

export default injectIntl(Formula);

export enum EModules {
    Frac = "frac",

    LumView = "lumview",

    Range = "range/", // fake
    Instability = "range/instability",
    Integration = "range/integration",
    FrontTracking = "range/front-tracking",

    Psa = "psa/", // fake
    Velocity = "velocity", // fake
    Intensity = "weighted/intensity", // fake
    Volume = "weighted/volume", // fake
    Number = "weighted/number", // fake
    Weighted = "weighted/", // fake

    PsaPos = "psa/constant-position",
    PsaVelocityPos = "psa/constant-position/velocity",
    PsaIntensityPos = "psa/constant-position/weighted/intensity",
    PsaVolumeWeightedPos = "psa/constant-position/weighted/volume",
    PsaNumberWeightedPos = "psa/constant-position/weighted/number",

    PsaTime = "psa/constant-time",
    PsaVelocityTime = "psa/constant-time/velocity",
    PsaIntensityTime = "psa/constant-time/weighted/intensity",
    PsaVolumeWeightedTime = "psa/constant-time/weighted/volume",
    PsaNumberWeightedTime = "psa/constant-time/weighted/number",

    Filtration = "range/filtration",
    SedimentationHeight = "range/sedimentation-height",
    FirstDerivative = "range/first-derivative",
    MwlRatio = "range/mwl-ratio",
    Astm = "astm",

    Mwl = "mwl", // fake
    PsaMwl = "psa/mwl/", // fake
    PsaMwlVolumeWeighted = "psa/mwl/weighted/volume",
    PsaMwlNumberWeighted = "psa/mwl/weighted/number",

    Spoc = "spoc",
    SpocView = "spocview",
    SpocAlignment = "spoc/alignment",
    SpocCount = "spoc/count",

    Size = "size/", // fake
    SpocSizeVolume = "spoc/size/volume",
    SpocSizeNumber = "spoc/size/number",
}

import {format} from "date-fns";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import getLocale from "@/services/date-locale";

export function getLongDate(value: Date, locale?: globalThis.Locale) {
    return format(value, "PPPPpp", {locale});
}

export function getShortDate(value: Date, locale?: globalThis.Locale) {
    return format(value, "PPp", {locale});
}

interface IRelativeTimeProps {
    intl: IntlShape;
    value: Date;
}

/**
 * Component to render time as relative text (1 day ago).
 * Set `asAbsolute` to `true` to display time as absolute value.
 */
class Time extends React.PureComponent<IRelativeTimeProps> {
    public render() {
        try {
            const {intl, value} = this.props;
            const locale = getLocale(intl);

            // How to build relativ Date. (just look below)
            // const relative = formatRelative(value, new Date(), {locale});

            return (
                <time
                    title={getLongDate(value, locale)}
                    dateTime={value.toISOString()}
                >
                    {getShortDate(value, locale)}
                </time>
            );
        } catch {
            return null;
        }
    }
}

export default injectIntl(Time);

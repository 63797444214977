import {faExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface IConfirmationModalProps {
    title: JSX.Element;
    onSubmit(): void;
}

interface IConfirmationModalState {
    show: boolean;
}

class ConfirmationModal extends React.PureComponent<
    IConfirmationModalProps,
    IConfirmationModalState
> {
    public readonly state: IConfirmationModalState = {show: false};

    private readonly ref = React.createRef<Modal>();

    public showModal() {
        this.setState({show: true});
    }

    @boundMethod
    public onSubmit() {
        this.props.onSubmit();
        this.ref.current?.triggerClose();
    }

    @boundMethod
    public afterClose() {
        this.setState({show: false});
    }

    public render() {
        const {title, children} = this.props;

        if (!this.state.show) {
            return null;
        }

        return (
            <Modal ref={this.ref} header={title} afterClose={this.afterClose}>
                <div className="modal-body">
                    <label className="text-warning">{children}</label>
                </div>

                <div className="modal-footer">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                    >
                        <FontAwesomeIcon
                            icon={faExclamation}
                            fixedWidth={true}
                            className="mr-1"
                        />
                        <T>Confirm</T>
                    </button>

                    <CancelButton />
                </div>
            </Modal>
        );
    }
}

export default ConfirmationModal;

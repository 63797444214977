type IResizeHandler = (rect: DOMRectReadOnly, target: Element) => void;

class ResizeService {
    private readonly ro: ResizeObserver;
    private readonly handlers = new Map<Element, IResizeHandler>();

    public constructor() {
        this.ro = new ResizeObserver((entries) => {
            const handlers = this.handlers;
            for (const entry of entries) {
                handlers.get(entry.target)?.(entry.contentRect, entry.target);
            }
        });
    }

    public subscribe(target: Element, handler: IResizeHandler) {
        this.ro.observe(target);
        this.handlers.set(target, handler);
        handler(target.getBoundingClientRect(), target); // call once

        return () => this.handlers.delete(target);
    }
}

const resize = new ResizeService();
export default resize;

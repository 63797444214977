import {faFlask} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {Link} from "react-router-dom";

import {EModuleLicences} from "@/components/license/models";
import {EModules} from "@/models";
import license from "@/services/license";
import {ERoles} from "@/services/models";
import session from "@/services/session";

import T from "@translate/T";

function isEngineLicensed(engine: EModules) {
    const {modules} = license.value;

    switch (engine) {
        case EModules.Frac:
            return modules.includes(EModuleLicences.FracAnalysis);

        default:
            return false;
    }
}

interface IFracAnalyzeButtonProps {
    params: string;
    project: number;
}

class FracAnalyzeButton extends React.PureComponent<IFracAnalyzeButtonProps> {
    public render() {
        const {params, project} = this.props;
        const url = `/project/${project}/fracture/analyze?${params}`;

        if (
            !session.hasRole(ERoles.Editor, project) ||
            !isEngineLicensed(EModules.Frac)
        ) {
            return null;
        }

        return (
            <Link className="mr-1" to={url}>
                <button type="button" className="btn btn-secondary">
                    <FontAwesomeIcon
                        icon={faFlask}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Analyze</T>
                </button>
            </Link>
        );
    }
}

export default FracAnalyzeButton;

import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import hardDriveSpace from "@/services/hard-drive-space";
import {FOOTER_VALUE} from "./models";

import {intl2Num, intl2Str} from "@translate/T";

interface IFooterWarningState {
    value: number;
}

class FooterWarning extends React.PureComponent<{}, IFooterWarningState> {
    public readonly state: IFooterWarningState = {
        value: hardDriveSpace.value,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = hardDriveSpace.subscribe({
            hardDriveSpaceChanged: (value) => this.setState({value}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        if (this.state.value > FOOTER_VALUE) {
            return null;
        }

        return (
            <div className="alert alert-warning text-center">
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    fixedWidth={true}
                    className="mr-1"
                />
                <IntlContext.Consumer children={this.renderMessage} />
            </div>
        );
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        return intl2Str(
            intl,
            "Server has {value} GB of free space left, should be at least 10 GB, please contact your administrator.",
            {value: intl2Num(intl, this.state.value, 1)},
        );
    }
}

export default FooterWarning;

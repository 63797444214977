import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import {ReactIntlError} from "react-intl";
import {BrowserRouter} from "react-router-dom";

import "bootstrap/js/src/alert";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/collapse";
import "bootstrap/js/src/popover";
import "bootstrap/js/src/tab";
import "bootstrap/js/src/tooltip";

import App from "./App";

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById("root") as HTMLElement,
);

if (process.env.NODE_ENV === "development") {
    // Disable missing translation message as translations will be added later.
    // We can add a toggle for this later when we have most translations.

    function shouldIgnoreError(message: Partial<ReactIntlError>) {
        switch (message.code) {
            case "MISSING_TRANSLATION":
                return true;

            case "MISSING_DATA":
                return message.message?.includes(
                    "Missing locale data for locale",
                );

            default:
                return false;
        }
    }

    function shouldIgnoreWarn(message: any) {
        if (message.startsWith("You're attempting to animate multiple")) {
            return true;
        }

        return false;
    }

    // tslint:disable: no-console
    const consoleError = console.error.bind(console);
    console.error = (message: any, ...args: any[]) => {
        if (shouldIgnoreError(message)) {
            return;
        }

        consoleError(message, ...args);
    };

    const consoleWarn = console.warn.bind(console);
    console.warn = (message: any, ...args: any[]) => {
        if (shouldIgnoreWarn(message)) {
            return;
        }

        consoleWarn(message, ...args);
    };
    // tslint:enable: no-console
}

import {
    faCheck,
    faSpinner,
    faTimes,
    faTrash,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {BUTTON_ANIMATION_DELAY} from "../models";
import {EButtonState} from "./models";

import T from "@translate/T";
import Tooltip from "../building-blocks/Tooltip";

interface IDeleteButtonProps {
    onDelete(): Promise<boolean>;

    classNameButton?: string;
    disabled?: boolean;
    id?: string;
    noAnimation?: boolean;
    noMargin?: boolean;
    otherName?: JSX.Element;
    otherIcon?: IconDefinition;
    skipConfirm?: boolean;
    title?: string;
}

interface IDeleteButtonState {
    showConfirm: boolean;
    deleteState: EButtonState;
}

class DeleteButton extends React.PureComponent<
    IDeleteButtonProps,
    IDeleteButtonState
> {
    public readonly state: IDeleteButtonState = {
        showConfirm: false,
        deleteState: EButtonState.None,
    };

    private timer = 0;

    public componentWillUnmount() {
        window.clearTimeout(this.timer);
    }

    @boundMethod
    public async delete(e: React.SyntheticEvent) {
        e.preventDefault();

        const {noAnimation, onDelete} = this.props;
        this.setState({
            showConfirm: false,
            deleteState: EButtonState.InProgress,
        });

        const deleted = await onDelete();
        if (deleted && noAnimation) {
            return;
        }

        this.setState(
            {deleteState: deleted ? EButtonState.Done : EButtonState.Error},
            () => {
                window.clearTimeout(this.timer);

                this.timer = window.setTimeout(
                    () => this.setState({deleteState: EButtonState.None}),
                    BUTTON_ANIMATION_DELAY,
                );
            },
        );
    }

    @boundMethod
    public toogleDeleteButton(e: React.SyntheticEvent) {
        if (this.props.skipConfirm) {
            this.delete(e);
            return;
        }

        e.preventDefault();

        this.setState((prevState) => ({showConfirm: !prevState.showConfirm}));
    }

    public render() {
        const {showConfirm} = this.state;

        if (!showConfirm) {
            return (
                <Tooltip
                    content={this.getTooltip()}
                    visible={true}
                    noSpanElement={true}
                >
                    {this.renderDeleteButton()}
                </Tooltip>
            );
        }

        return this.renderButtonGroup();
    }

    private renderDeleteButton() {
        const {classNameButton, id, disabled, title, otherName, noMargin} =
            this.props;
        const {deleteState} = this.state;

        const btnProps = this.getClassName(deleteState);
        const addClassName = classNameButton ? " " + classNameButton : "";

        if (noMargin) {
            btnProps.className = btnProps.className.replace(" mr-1", "");
        }

        return (
            <button
                type="button"
                id={id}
                data-testid={id}
                className={btnProps.className + addClassName}
                disabled={disabled || deleteState === EButtonState.InProgress}
                title={title}
                onClick={this.toogleDeleteButton}
            >
                <FontAwesomeIcon
                    icon={btnProps.icon}
                    fixedWidth={true}
                    className="mr-1"
                    spin={deleteState === EButtonState.InProgress}
                />
                {otherName ?? <T>Delete</T>}
            </button>
        );
    }

    private renderButtonGroup() {
        return (
            <div className="btn-group">
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.delete}
                    id="confirm"
                >
                    <FontAwesomeIcon
                        icon={faTrash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Confirm</T>
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.toogleDeleteButton}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Cancel</T>
                </button>
            </div>
        );
    }

    private getClassName(saveState: EButtonState) {
        const {otherIcon} = this.props;

        switch (saveState) {
            case EButtonState.Done:
                return {className: "btn btn-dark mr-1", icon: faCheck};

            case EButtonState.Error:
                return {className: "btn btn-secondary mr-1", icon: faTimes};

            case EButtonState.InProgress:
                return {className: "btn btn-danger mr-1", icon: faSpinner};

            default:
                return {
                    className: "btn btn-danger mr-1",
                    icon: otherIcon ?? faTrash,
                };
        }
    }

    private getTooltip() {
        const {deleteState} = this.state;

        if (deleteState !== EButtonState.Error) {
            return null;
        }

        return (
            <span className="text-warning">
                <T>Could not be removed.</T>
            </span>
        );
    }
}

export default DeleteButton;

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";
import {Link} from "react-router-dom";

import {ILicenses} from "@/services/models";

import DropdownButton from "@toolbox/button-like/DropdownButton";
import T, {intl2Str} from "@translate/T";

interface ICreateSopButtonProps {
    project: number;
    licenses: ILicenses;

    className?: string;
    isButtonGroup?: boolean;
}

class CreateSopButton extends React.PureComponent<ICreateSopButtonProps> {
    public render() {
        return <IntlContext.Consumer children={this.renderDropdownButton} />;
    }

    @boundMethod
    private renderDropdownButton(intl: IntlShape) {
        const {className, isButtonGroup} = this.props;

        return (
            <DropdownButton
                type="primary"
                idSuffix="new-sop"
                title={intl2Str(intl, "Create new SOP")}
                classNameButton={className}
                isButtonGroup={isButtonGroup}
                menu={this.renderMenu}
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    fixedWidth={true}
                    className="mr-1"
                />
                <T>SOP</T>
            </DropdownButton>
        );
    }

    @boundMethod
    private renderMenu() {
        const {project, licenses} = this.props;
        const {frac, fuge, reader, sizer, spoc, xray} = licenses;

        return (
            <React.Fragment>
                {frac && (
                    <Link to={`/project/${project}/fracture/wizard`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiFrac SOP</T>
                        </button>
                    </Link>
                )}
                {fuge && (
                    <Link to={`/project/${project}/centrifuge/wizard/fuge`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiFuge SOP</T>
                        </button>
                    </Link>
                )}
                {reader && (
                    <Link to={`/project/${project}/centrifuge/wizard/reader`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiReader SOP</T>
                        </button>
                    </Link>
                )}
                {sizer && (
                    <Link to={`/project/${project}/centrifuge/wizard/sizer`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiSizer SOP</T>
                        </button>
                    </Link>
                )}
                {spoc && (
                    <Link to={`/project/${project}/spoc/wizard`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiSpoc SOP</T>
                        </button>
                    </Link>
                )}
                {xray && (
                    <Link to={`/project/${project}/centrifuge/wizard/xray`}>
                        <button type="button" className="dropdown-item">
                            <T>New LUMiReader X-Ray SOP</T>
                        </button>
                    </Link>
                )}
            </React.Fragment>
        );
    }
}

export default CreateSopButton;

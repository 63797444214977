import {IUserDetails} from "@/components/account/models";
import EventHandlers from "@/services/handlers";
import http from "@/services/http";

import {intl2Str} from "@translate/T";
import {getTranslator} from "@translate/Provider";

interface IEventHandler {
    namesChanged?(): void;
}

class DisplayNameService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    private _userDetails = new Map<string, IUserDetails>();

    public get default() {
        return intl2Str(getTranslator(), "Anonymous");
    }

    public async getDisplayName(user: string) {
        if (!user) {
            return this.default;
        }

        return (await this.getUserDetails(user))?.displayName ?? this.default;
    }

    public async getUserDetails(user: string) {
        return this._userDetails.get(user) ?? (await this.retrieve(user));
    }

    /** If UI has changed some displayName, this will retrieve the new. */
    public async refresh(user: string) {
        return this.retrieve(user);
    }

    /** Clear this cache if we delete user. */
    public delete(user: string) {
        this._userDetails.delete(user);
    }

    /** Clear full cache. */
    public deleteAll() {
        this._userDetails.clear();
    }

    private async retrieve(user: string) {
        try {
            const response = await http
                .get("/api/users/" + user)
                .json<IUserDetails>();

            this._userDetails.set(user, response);
            this.handlers.publish((x) => x.namesChanged?.());

            return response;
        } catch {
            return undefined;
        }
    }

    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }
}

const display = new DisplayNameService();
export default display;

import {ascending} from "d3-array";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {EModuleLicences, ILicenseDisplay} from "@/components/license/models";
import license from "@/services/license";
import {EServerId} from "@shared/models";
import {ILicensedDevice} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";

import {trademark} from "@/Footer";
import Card from "@toolbox/design/Card";
import T, {intl2Str} from "@translate/T";
import {displayLicenseFeatures} from "./SopFeatures";

export function getDisplayModule(licensed: EModuleLicences): ILocalizedText {
    switch (licensed) {
        case EModuleLicences.FracView:
            return (intl) => intl2Str(intl, "Frac view");

        case EModuleLicences.FracAnalysis:
            return (intl) => intl2Str(intl, "Analysis");

        case EModuleLicences.LumView:
            return (intl) => intl2Str(intl, "LUMView") + trademark();

        case EModuleLicences.Instability:
            return (intl) => intl2Str(intl, "Stability");

        case EModuleLicences.Integration:
            return (intl) => intl2Str(intl, "Integration");

        case EModuleLicences.FrontTracking:
            return (intl) => intl2Str(intl, "Front tracking");

        case EModuleLicences.Psa:
            return (intl) => intl2Str(intl, "Particle size characterization");

        case EModuleLicences.Filtration:
            return (intl) => intl2Str(intl, "Filtration");

        case EModuleLicences.Sedimentation:
            return (intl) => intl2Str(intl, "Sedimentation height");

        case EModuleLicences.FirstDerivative:
            return (intl) => intl2Str(intl, "First derivative");

        case EModuleLicences.ExtinctionRatio:
            return (intl) => intl2Str(intl, "MWL extinction ratio");

        case EModuleLicences.Astm:
            return (intl) => intl2Str(intl, "SEPCalc ASTM D7827-12");

        case EModuleLicences.PsaMwl:
            return (intl) => intl2Str(intl, "Multiple wavelengths PSA");

        case EModuleLicences.SpocView:
            return (intl) => intl2Str(intl, "Spoc view");

        case EModuleLicences.SpocCount:
            return (intl) => intl2Str(intl, "Count & concentration");

        case EModuleLicences.SpocSize:
            return (intl) => intl2Str(intl, "Number weighted PSD");

        case EModuleLicences.MultiPeaks:
            return (intl) => intl2Str(intl, "Multi peaks");

        case EModuleLicences.VolumeConcentration:
            return (intl) => intl2Str(intl, "Volume concentration");
    }
}

function getLicensedModules(
    serverId: EServerId,
    licensed: EModuleLicences[],
): EModuleLicences[] {
    const modules: EModuleLicences[] = [];

    switch (serverId) {
        case EServerId.Centrifuge:
            modules.push(
                EModuleLicences.LumView,
                EModuleLicences.Instability,
                EModuleLicences.Integration,
                EModuleLicences.FrontTracking,
                EModuleLicences.Filtration,
                EModuleLicences.Sedimentation,
                EModuleLicences.FirstDerivative,
                EModuleLicences.ExtinctionRatio,
                EModuleLicences.Astm,
                EModuleLicences.Psa,
                EModuleLicences.PsaMwl,
                EModuleLicences.VolumeConcentration,
            );

            if (licensed.includes(EModuleLicences.Psa)) {
                modules.push(EModuleLicences.MultiPeaks);
            }

            break;

        case EServerId.Fracture:
            modules.push(
                EModuleLicences.FracView,
                EModuleLicences.FracAnalysis,
            );
            break;

        case EServerId.Spoc:
            modules.push(
                EModuleLicences.SpocView,
                EModuleLicences.SpocCount,
                EModuleLicences.SpocSize,
            );

            if (licensed.includes(EModuleLicences.SpocSize)) {
                modules.push(EModuleLicences.MultiPeaks);
            }

            break;
    }

    return modules;
}

interface IModulesProps {
    intl: IntlShape;
    value: EModuleLicences[];
    devices: ILicensedDevice[];
}

class ModuleLicence extends React.PureComponent<IModulesProps> {
    public render() {
        return (
            <Card
                className="h-100"
                headerClassName="bg-secondary"
                title={<T>Licensed analysis modules</T>}
                noMargin={true}
            >
                <div className="card-body" data-testid="licensed-modules">
                    {this.renderModules()}
                </div>
            </Card>
        );
    }

    private renderModules() {
        const {devices, value} = this.props;
        const centDevices = devices
            .filter((x) => license.getSopLicences([x]).cent)
            .map((x) => x.deviceClass)
            .sort(ascending);
        const allDevices = devices.map((x) => x.deviceClass);
        const centTitle = [...new Set(centDevices)].join(" | ");

        if (!value.length) {
            return (
                <em className="text-muted">
                    <T>None</T>
                </em>
            );
        }

        return (
            <React.Fragment>
                {!!centDevices.length &&
                    this.renderList(centTitle, EServerId.Centrifuge)}
                {allDevices.includes("LUMiFrac") &&
                    this.renderList("LUMiFrac", EServerId.Fracture, " mt-4")}
                {allDevices.includes("LUMiSpoc") &&
                    this.renderList("LUMiSpoc", EServerId.Spoc, " mt-4")}
            </React.Fragment>
        );
    }

    private renderList(
        title: string,
        serverId: EServerId,
        className: string = "",
    ) {
        return (
            <React.Fragment>
                <h6 className={"card-title text-muted mb-1" + className}>
                    {title}
                </h6>
                <ul className="list-unstyled mb-0">
                    {this.renderLicenses(serverId)}
                </ul>
            </React.Fragment>
        );
    }

    private renderLicenses(serverId: EServerId) {
        const {intl, value} = this.props;

        const sorted: ILicenseDisplay[] = getLicensedModules(serverId, value)
            .map((x) => ({
                name: getDisplayModule(x)(intl),
                missing: {
                    missingModuleLicenses: !value.includes(x) ? [x] : undefined,
                },
            }))
            .sort((a, b) => a.name.localeCompare(b.name));

        return sorted.map((x, i) => displayLicenseFeatures(intl, x, i));
    }
}

export default injectIntl(ModuleLicence);

import {ILocalizedText} from "@translate/models";
import {ETimeUnit} from "./models";

import {intl2Str} from "@translate/T";

export function getTimeUnit(unit: ETimeUnit): ILocalizedText {
    const values: ILocalizedText[] = [
        (intl) => intl2Str(intl, "s"),
        (intl) => intl2Str(intl, "min"),
        (intl) => intl2Str(intl, "h"),
        (intl) => intl2Str(intl, "days"),
        (intl) => intl2Str(intl, "months"),
    ];

    return values[unit];
}

export function fromSeconds(seconds: number, toUnit: ETimeUnit) {
    switch (toUnit) {
        default:
        case ETimeUnit.Seconds:
            return seconds;

        case ETimeUnit.Minutes:
            return seconds / 60;

        case ETimeUnit.Hours:
            return seconds / 3600;

        case ETimeUnit.Days:
            return seconds / 3600 / 24;

        case ETimeUnit.Months:
            return seconds / 3600 / 24 / 30;
    }
}

export function toSeconds(value: number, fromUnit: ETimeUnit) {
    switch (fromUnit) {
        default:
        case ETimeUnit.Seconds:
            return value;

        case ETimeUnit.Minutes:
            return value * 60;

        case ETimeUnit.Hours:
            return value * 3600;

        case ETimeUnit.Days:
            return value * 24 * 3600;

        case ETimeUnit.Months:
            return value * 30 * 24 * 3600;
    }
}

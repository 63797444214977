import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import Dropzone, {DropzoneState, FileRejection} from "react-dropzone";

import {IImportFile, EImportProgress} from "./models";

import DropArea from "@toolbox/design/DropArea";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T from "@translate/T";
import FileStatus from "./FileStatus";
import Progress from "./Progress";

interface IImportPageProps {
    title: JSX.Element;
    files: IImportFile[];

    clientId: string | undefined; // force me to send via props id everytime
    project?: number;
    accept?: string;
    maxSize?: number;

    onUpload(files: IImportFile[], accepted: File[]): void;
}

class ImportPage extends React.PureComponent<IImportPageProps> {
    @boundMethod
    public onDrop(accepted: File[], rejected: FileRejection[]) {
        const {onUpload, files} = this.props;
        const _files = [...files];

        const targets = rejected
            .map((x) => ({
                name: x.file.name,
                progress:
                    x.errors[0].code === "file-too-large"
                        ? EImportProgress.FileTooLarge
                        : EImportProgress.NotImported,
            }))
            .concat(
                accepted.map((x) => ({
                    name: x.name,
                    progress: EImportProgress.Pending,
                })),
            );

        for (const {name, progress} of targets) {
            const index = _files.findIndex((x) => x.name === name);
            if (index >= 0) {
                continue;
            }

            _files.push({importedDocs: [], name, progress});
        }

        onUpload(_files, accepted);
    }

    public render() {
        const {title, files} = this.props;

        return (
            <ContainerCol col={6}>
                <h1>{title}</h1>
                <hr />

                <Progress files={files} />
                {this.renderContent()}
            </ContainerCol>
        );
    }

    private renderContent() {
        const {accept, maxSize, clientId} = this.props;

        if (clientId === "") {
            return (
                <div className="alert alert-info" role="alert">
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        fixedWidth={true}
                        className="mr-1"
                        spin={true}
                    />
                    <T>Initializing file upload...</T>
                </div>
            );
        }

        return (
            <div className="form-row">
                <div className="col-md-4 mb-2">
                    <Dropzone
                        accept={accept}
                        maxSize={maxSize}
                        onDrop={this.onDrop}
                        children={this.renderDropzoneContent}
                    />
                </div>
                <div className="col-md-8">{this.renderImportList()}</div>
            </div>
        );
    }

    @boundMethod
    private renderDropzoneContent(args: DropzoneState) {
        return (
            <DropArea
                cta={<T>Click to select files to import.</T>}
                args={args}
            />
        );
    }

    private renderImportList() {
        const {project, files} = this.props;
        if (!files.length) {
            return null;
        }

        const fileItems = files.map((x) => (
            <FileStatus key={x.name} file={x} project={project ?? 0} />
        ));

        return <div className="list-group">{fileItems}</div>;
    }
}

export default ImportPage;

import lightFormat from "date-fns/lightFormat";

import {parseNumber} from "@translate/T";

class ParamsParser {
    private readonly result = new URLSearchParams();

    public constructor(search?: string) {
        this.result = new URLSearchParams(search);
    }

    public get params() {
        return this.result;
    }

    public setString(
        key: string,
        value: string | null | undefined,
        defaultValue: string = "",
    ) {
        if (!value || value === defaultValue) {
            this.result.delete(key);
            return;
        }

        this.result.set(key, value);
    }

    public setArray(
        key: string,
        values: (string | number)[] | null | undefined,
    ) {
        if (!values || !values.length) {
            this.result.delete(key);
            return;
        }

        for (const value of values) {
            this.result.append(key, value.toString());
        }
    }

    public setInt(
        key: string,
        value: number | null | undefined,
        defaultValue: number = 0,
    ) {
        if (typeof value !== "number" || value === defaultValue) {
            this.result.delete(key);
            return;
        }

        this.result.set(key, value.toString());
    }

    public setBoolean(
        key: string,
        value: boolean | null | undefined,
        defaultValue: boolean = false,
    ) {
        const effective = !!value;
        if (effective === defaultValue) {
            this.result.delete(key);
            return;
        }

        this.result.set(key, effective ? "true" : "false");
    }

    public setDate(
        key: string,
        value: Date | null | undefined,
        defaultValue?: Date,
    ) {
        if (!value || value === defaultValue) {
            this.result.delete(key);
            return;
        }

        this.result.set(key, lightFormat(value, "yyyy-MM-dd"));
    }

    public getString(key: string): string | undefined;
    public getString(key: string, defaultValue: string): string;
    public getString(key: string, defaultValue?: string) {
        return this.result.get(key) || defaultValue;
    }

    public getInt(key: string, defaultValue: number = 0) {
        const value = this.result.get(key);
        if (!value) {
            return defaultValue;
        }

        const parsed = parseNumber(value);
        if (isNaN(parsed) || parsed === defaultValue) {
            return defaultValue;
        }

        return parsed;
    }

    public getBoolean(key: string, defaultValue: boolean = false) {
        const value = this.result.get(key);
        if (!value) {
            return defaultValue;
        }

        return value === "true";
    }

    public getIntArray(key: string) {
        const parsedValues: number[] = [];
        for (const value of this.result.getAll(key)) {
            const parsed = parseNumber(value);
            if (!isNaN(parsed)) {
                parsedValues.push(parsed);
            }
        }

        return parsedValues;
    }

    public getStringArray(key: string) {
        return this.result.getAll(key);
    }

    public getDate(key: string): Date | undefined;
    public getDate(key: string, defaultValue: Date): Date;
    public getDate(key: string, defaultValue?: Date) {
        const value = this.result.get(key);
        if (!value) {
            return defaultValue;
        }

        const parsed = new Date(value);
        return !isNaN(parsed.valueOf()) ? parsed : defaultValue;
    }

    public toString() {
        return this.params.toString();
    }
}

export default ParamsParser;

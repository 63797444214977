import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import http from "@/services/http";
import loading from "@/services/loading";
import session from "@/services/session";
import {ILocalizedText} from "@translate/models";

import Tooltip from "../building-blocks/Tooltip";

interface IFormSubmitProps {
    method: "POST" | "GET";
    action: string;

    className: string;
    idSuffix: string;
    name: JSX.Element;
    icon: IconDefinition;

    dataTestId?: string;
    disabled?: boolean;
    title?: ILocalizedText;
    warningXerror?: JSX.Element;

    getJson?(): Promise<object>;
    onChange?(response: Response, after: () => void): void;
}

class FormSubmit extends React.PureComponent<IFormSubmitProps> {
    private readonly form = React.createRef<HTMLFormElement>();

    @boundMethod
    public async onClick() {
        const {action, getJson, onChange} = this.props;

        try {
            const json = (await getJson?.()) ?? {};
            const response = await http.post(action, {json, timeout: false});

            onChange?.(response, () => this.form.current?.submit());
        } catch (error) {
            loading.catchApiAbort(error);
            return;
        }
    }

    public render() {
        return <IntlContext.Consumer children={this.renderButton} />;
    }

    @boundMethod
    private renderButton(intl: IntlShape) {
        const {
            action,
            children,
            dataTestId,
            method,
            className,
            disabled,
            icon,
            name,
            idSuffix,
            title,
            getJson,
            onChange,
            warningXerror,
        } = this.props;
        const id = "form-submit-" + idSuffix;
        const clicking = !!(getJson || onChange);

        return (
            <Tooltip
                content={warningXerror}
                visible={true}
                noSpanElement={true}
            >
                <button
                    type={clicking ? "button" : "submit"}
                    className={className}
                    disabled={disabled}
                    form={id}
                    id={"button-" + id}
                    data-testid={dataTestId ?? id}
                    title={title?.(intl)}
                    onClick={clicking ? this.onClick : undefined}
                >
                    <form
                        ref={this.form}
                        method={method}
                        action={action}
                        className="d-none"
                        id={id}
                    >
                        <input
                            type="hidden"
                            name="token"
                            value={session.token ?? ""}
                        />
                        {children}
                    </form>
                    <FontAwesomeIcon
                        icon={icon}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    {name}
                </button>
            </Tooltip>
        );
    }
}

export default FormSubmit;

import {isEqual} from "lodash";

import EventHandlers from "@/services/handlers";
import http from "@/services/http";
import signal from "@/services/signal";

interface IEventHandler {
    hardDriveSpaceChanged?(value: number): void;
}

class HardDriveSpace {
    private readonly events = new EventHandlers<IEventHandler>();

    private _value = Infinity;

    public constructor() {
        signal.register({
            hardDriveSpaceChanged: (value) => {
                this.value = value;
            },
        });
    }

    public get value() {
        return this._value;
    }

    public set value(value: number) {
        if (isEqual(this._value, value)) {
            return;
        }

        this._value = value;
        this.events.publish((x) => x.hardDriveSpaceChanged?.(this._value));
    }

    public async retrieve() {
        try {
            const response = await http
                .get("/api/notification/diskspace", {cache: "no-cache"})
                .json<{freeSpace: number}>();

            this.value = response.freeSpace;
        } catch {
            this.value = Infinity;
        }
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const hardDriveSpace = new HardDriveSpace();
export default hardDriveSpace;

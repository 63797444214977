import "./DirectGroup.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ECheckStates} from "@toolbox/button-like/models";
import {IOnSelectHandler, ISample} from "./models";

import TristateCheckbox from "@toolbox/button-like/TristateCheckbox";
import SampleWithTimestamp from "./SampleWithTimestamp";

interface IDirectGroupProps {
    samples: ISample[];
    onChange: IOnSelectHandler;
}

class DirectGroup extends React.PureComponent<IDirectGroupProps> {
    private get checkState(): ECheckStates {
        const {samples} = this.props;
        const checked = samples.filter((x) => x.selected);
        switch (checked.length) {
            case 0:
                return ECheckStates.NotChecked;

            case samples.length:
                return ECheckStates.Checked;

            default:
                return ECheckStates.Indeterminate;
        }
    }

    @boundMethod
    public onSelfChanged(checked: boolean) {
        const {onChange, samples} = this.props;

        onChange({target: {checked, id: samples.map((x) => x.id)}});
    }

    public render() {
        const {samples, children} = this.props;
        const id = samples[0].id + "-group";

        return (
            <div className="form-check">
                <TristateCheckbox
                    suffixId={id}
                    value={this.checkState}
                    onChange={this.onSelfChanged}
                />
                <label
                    styleName="item-name"
                    className="form-check-label"
                    htmlFor={id}
                >
                    {children}
                </label>
                <ul className="list-unstyled mb-0">{this.renderNested()}</ul>
            </div>
        );
    }

    private renderNested() {
        const {samples, onChange} = this.props;

        return samples.map((x, i) => (
            <SampleWithTimestamp key={i} sample={x} onChange={onChange} />
        ));
    }
}

export default DirectGroup;

import React from "react";

import realtime from "@/services/realtime";
import {EActivities} from "../building-blocks/models";

interface IContainerActivityProps {
    left: JSX.Element;
    right: JSX.Element;
}

interface IContainerActivityState {
    activities: EActivities;
}

class ContainerActivity extends React.PureComponent<
    IContainerActivityProps,
    IContainerActivityState
> {
    public readonly state: IContainerActivityState = {
        activities: realtime.value.activities,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = realtime.subscribe({
            realtimeChanged: ({activities}) => this.setState({activities}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {left, right} = this.props;
        const {activities} = this.state;

        if (activities === EActivities.Hidden) {
            return left;
        }

        return (
            <div className="form-row">
                <div className="col-lg-9">{left}</div>
                <div className="col-lg-3">{right}</div>
            </div>
        );
    }
}

export default ContainerActivity;

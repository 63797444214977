import {
    faCheck,
    faExclamationTriangle,
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";

import loading from "@/services/loading";
import {ELoadingStatus} from "@/services/models";

import T from "@translate/T";

interface ILoadingIndicatorState {
    status: ELoadingStatus;
    count: number;
}

class LoadingIndicator extends React.PureComponent<{}, ILoadingIndicatorState> {
    public readonly state: ILoadingIndicatorState = {
        status: loading.status,
        count: 0,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = loading.subscribe({
            loadingChanged: (status, count) => this.setState({status, count}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {status, count} = this.state;

        if (status === ELoadingStatus.Nothing && count <= 0) {
            return null;
        }

        return (
            <div
                role="alert"
                className="toast top-right fade show m-0"
                style={{zIndex: 1000}}
                aria-atomic="true"
                aria-live="assertive"
            >
                {this.renderMsg()}
            </div>
        );
    }

    private renderMsg() {
        const {status, count} = this.state;

        if (status === ELoadingStatus.Failed && count <= 0) {
            return (
                <div className="alert alert-danger mb-0">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Something went wrong, please try again.</T>
                </div>
            );
        }

        if (status === ELoadingStatus.Success && count <= 0) {
            return (
                <div className="alert alert-success mb-0">
                    <FontAwesomeIcon
                        icon={faCheck}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Successfully done.</T>
                </div>
            );
        }

        return (
            <div className="alert alert-info mb-0">
                <FontAwesomeIcon
                    icon={faSpinner}
                    fixedWidth={true}
                    className="mr-1"
                    spin={true}
                />
                <T>Request in progress, please wait...</T>
            </div>
        );
    }
}

export default LoadingIndicator;

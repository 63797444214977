import React from "react";

import {EAuditActions} from "@/components/admin/audit/models";
import T from "@translate/T";

interface IAuditActionProps {
    titleCase?: boolean;
    value: EAuditActions;
}

class AuditAction extends React.PureComponent<IAuditActionProps> {
    public render() {
        return !this.props.titleCase ? this.renderLower() : this.getUpper();
    }

    private renderLower() {
        switch (this.props.value) {
            case EAuditActions.Create:
                return <T>created</T>;

            case EAuditActions.Modify:
                return <T>modified</T>;

            case EAuditActions.Rename:
                return <T>renamed</T>;

            case EAuditActions.Delete:
                return <T>deleted</T>;

            case EAuditActions.Import:
                return <T>imported</T>;

            case EAuditActions.Enqueue:
                return <T>enqueued</T>;

            case EAuditActions.Dequeue:
                return <T>dequeued</T>;

            case EAuditActions.LoginSuccess:
                return <T>login succeded</T>;

            case EAuditActions.LoginFail:
                return <T>login failed</T>;
        }
    }

    private getUpper() {
        switch (this.props.value) {
            case EAuditActions.Create:
                return <T>Create</T>;

            case EAuditActions.Modify:
                return <T>Modify</T>;

            case EAuditActions.Rename:
                return <T>Rename</T>;

            case EAuditActions.Delete:
                return <T>Delete</T>;

            case EAuditActions.Import:
                return <T>Import</T>;

            case EAuditActions.Enqueue:
                return <T>Enqueue</T>;

            case EAuditActions.Dequeue:
                return <T>Dequeue</T>;

            case EAuditActions.LoginSuccess:
                return <T>Login success</T>;

            case EAuditActions.LoginFail:
                return <T>Login fail</T>;
        }
    }
}

export default AuditAction;

import {ILocalizedText} from "@translate/models";
import {ELengthUnit, ETimeUnit} from "./models";
import {getTimeUnit} from "./time";

import {intl2Str} from "@translate/T";

export function getLengthUnit(unit: ELengthUnit): ILocalizedText {
    const values: ILocalizedText[] = [
        (intl) => intl2Str(intl, "nm"),
        (intl) => intl2Str(intl, "µm"),
        (intl) => intl2Str(intl, "mm"),
    ];

    return values[unit];
}

export function getRegressionUnit(
    lengthUnit: ELengthUnit,
    timeUnit: ETimeUnit,
): ILocalizedText {
    return (intl) =>
        getLengthUnit(lengthUnit)(intl) + "/" + getTimeUnit(timeUnit)(intl);
}

export function fromNanometer(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value;

        case ELengthUnit.Micrometer:
            return value / 1000;

        case ELengthUnit.Millimeter:
            return value / 1000 / 1000;
    }
}

export function toNanometer(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value;

        case ELengthUnit.Micrometer:
            return value * 1000;

        case ELengthUnit.Millimeter:
            return value * 1000 * 1000;
    }
}

export function fromMicrometer(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value * 1000;

        case ELengthUnit.Micrometer:
            return value;

        case ELengthUnit.Millimeter:
            return value / 1000;
    }
}

export function toMicrometer(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value / 1000;

        case ELengthUnit.Micrometer:
            return value;

        case ELengthUnit.Millimeter:
            return value * 1000;
    }
}

export function fromMillimeter(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value * 1000 * 1000;

        case ELengthUnit.Micrometer:
            return value * 1000;

        case ELengthUnit.Millimeter:
            return value;
    }
}

export function toMillimeter(value: number, unit: ELengthUnit) {
    switch (unit) {
        default:
        case ELengthUnit.Nanometer:
            return value / 1000 / 1000;

        case ELengthUnit.Micrometer:
            return value / 1000;

        case ELengthUnit.Millimeter:
            return value;
    }
}

const c = 299792458; // speed of light in m/s
const e = 1.60217733e-19; // planck constant in Js
const h = 6.6260755e-34; // electron volt in J
const conversionFactor = (h * c) / (1000 * e);

export function nm2Kev(value: number) {
    return (conversionFactor * 1e9) / value;
}

export function kev2Nm(value: number) {
    return (conversionFactor * 1e9) / value;
}

import {IBaseDocumentModel, IPagedList} from "@toolbox/building-blocks/models";
import {EDeviceClasses} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";
import {IPartialSelectedItem} from "./task-buttons/models";

export enum ESortColumns {
    // URL enum
    None = "",
    Name = "name",
    Modified = "modified",
    Created = "created",
}

export enum ETimeRange {
    // URL enum
    Any = "any",
    PastHour = "hour",
    PastDay = "day",
    PastWeek = "week",
    PastMonth = "month",
    PastYear = "year",
}

export enum EDocIdPrefixes {
    CentMeasures = "centMeasures",
    FracMeasures = "fracMeasures",
    SpocMeasures = "spocMeasures",
    SpocAlignments = "spocAlignments",
    CentAnalyses = "centAnalyses",
    FracAnalyses = "fracAnalyses",
    SpocAnalyses = "spocAnalyses",
    CentSops = "centSops",
    FracSops = "fracSops",
    SpocSops = "spocSops",
    CentReports = "centReports",
    SpocReports = "spocReports",
}

export interface IEnabledDocuments {
    devices: EDeviceClasses[];
    params: string;
}

export interface INestedBaseItem {
    id: string;
    name: string | JSX.Element;
    prefixName?: string;
}

export interface INestedItem extends INestedBaseItem {
    nestedItems?: INestedBaseItem[];
}

export interface INumberDocumentModel extends IBaseDocumentModel {
    id: number;
}

export interface IChangedMeasurement {
    id: number;
    project: number;
}

export interface IMeasurement {
    id: number;
    name: string;
    url: string;
    time: string;
    completion: string;
}

export interface ISearchDocumentModel {
    name: string;
    owner: string;
    docId: string;

    completion: string;
    created: string;
    modified: string;
    types: string[];

    nestedItems: INestedItem[] | undefined;
}

export interface IDocIdInfo {
    /** Document ID only */
    id: string;

    /** URL to icon image */
    icon: string;

    prefix: EDocIdPrefixes;

    /** URL to view document */
    url: string;

    /** Document type display string */
    type: ILocalizedText;

    device?: EDeviceClasses;
}

export interface ISelectedItem extends IPartialSelectedItem {
    owner: string;
    device?: EDeviceClasses;

    /** IDs of selected nested children */
    nested: string[];
}

export interface IProjectSearchResult extends IPagedList<ISearchDocumentModel> {
    devices?: IMatchCategory[];
    tags?: IMatchCategory[];
    types?: IMatchCategory[];
    wavelengths?: IMatchCategory[];
}

export interface IMatchCategory {
    count: number;
    key: string;
}

export interface ISearchBoxValue {
    query: string;
    dateRange: ETimeRange;
    pageSize: number | "all";
}

export interface ISearchItem {
    id: IDocIdInfo;
    doc: ISearchDocumentModel;
}

/**
 * true: Item is selected.
 * array: IDs of selected nested items.
 * null: Item not selected.
 */
export type IItemSelectionState = true | string[] | null;
export type IProjectDocumentResponse = IPagedList<ISearchDocumentModel>;

import {EDeviceClasses, IFakeMap} from "@toolbox/models";
import {EDocIdPrefixes} from "../models";

export enum EMissingExport {
    None = 0,
    Normalization = 1,
    Material = 2,
    Stamp = 4,
    Sample = 8,
}

export enum ECombineError {
    None = 0,
    Tilt = 1,
    DeviceParameter = 2,
    DifferentDevice = 4,
}

export interface IPartialSelectedItem {
    id: string;
    prefix: EDocIdPrefixes;
}

export interface ICombineModel {
    device: EDeviceClasses;
    ids: number[];
    name: string;
    project: number;
}

export interface IExportRequest {
    doc: IFakeMap<string[]>;
    project: number;
    name?: string;
}

export interface IExportResponse {
    id: string;
    missing: EMissingExport;
}

export type IDocType =
    | "centrifugeMeasurements"
    | "fractureMeasurements"
    | "spocMeasurements"
    | "spocAlignments"
    | "centrifugeAnalyses"
    | "fractureAnalyses"
    | "spocAnalyses"
    | "centrifugeSops"
    | "fractureSops"
    | "spocSops"
    | "reports";

import {boundMethod} from "autobind-decorator";
import React from "react";

import realtime from "@/services/realtime";
import {ILocalizedText} from "@translate/models";
import {EActivities} from "./models";

import FlyoutButton from "@toolbox/button-like/FlyoutButton";
import {intl2Str} from "@translate/T";

interface IActivityFlyoutButtonProps {
    className?: string;
}

interface IActivityFlyoutButtonState {
    show: boolean;
}

class ActivityFlyoutButton extends React.PureComponent<
    IActivityFlyoutButtonProps,
    IActivityFlyoutButtonState
> {
    public readonly state: IActivityFlyoutButtonState = {
        show: realtime.value.activities === EActivities.Visible,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = realtime.subscribe({
            realtimeChanged: ({activities}) =>
                this.setState({show: activities === EActivities.Visible}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {show} = this.state;
        realtime.setState({
            activities: !show ? EActivities.Visible : EActivities.Hidden,
        });
    }

    public render() {
        const {className} = this.props;
        const {show} = this.state;
        const title: ILocalizedText = show
            ? (intl) => intl2Str(intl, "Collapse recent activities")
            : (intl) => intl2Str(intl, "Expand recent activities");

        return (
            <FlyoutButton
                left={!show}
                className={className}
                title={title}
                onClick={this.onClick}
            />
        );
    }
}

export default ActivityFlyoutButton;

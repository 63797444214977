import {faFileArchive} from "@fortawesome/free-solid-svg-icons";

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ILocalizedText} from "@translate/models";
import {EDocIdPrefixes} from "../models";
import {
    EMissingExport,
    IDocType,
    IExportRequest,
    IExportResponse,
    IPartialSelectedItem,
} from "./models";

import FormSubmit from "@toolbox/button-like/FormSubmit";
import {bitwiseAND} from "@toolbox/functions/bitwise";
import T, {intl2Str} from "@translate/T";

export interface IExportButtonProps {
    project: number;
    idSuffix: string;
    selection: IPartialSelectedItem[];

    className?: string;
    name?: string;
    disabled?: boolean;
}

interface IExportButtonState {
    idUrl: string;
    warning: EMissingExport;
}

class ExportButton extends React.PureComponent<
    IExportButtonProps,
    IExportButtonState
> {
    public readonly state: IExportButtonState = {
        idUrl: "",
        warning: EMissingExport.None,
    };

    @boundMethod
    public async onChange(response: Response, after: () => void) {
        const resolve = (await response.json()) as IExportResponse;

        this.setState(
            {idUrl: "/" + resolve.id, warning: resolve.missing},
            () => {
                after();
                this.setState({idUrl: ""});
            },
        );
    }

    @boundMethod
    public async getJson() {
        const {project, name, selection} = this.props;

        const json: IExportRequest = {project, doc: {}};

        if (name !== undefined) {
            json.name = name;
        }

        for (const item of selection) {
            const field = this.getExportField(item);
            if (!field) {
                continue;
            }

            if (!json.doc[field]) {
                json.doc[field] = [];
            }

            json.doc[field]!.push(item.id);
        }

        return json;
    }

    public render() {
        const {className, disabled, idSuffix} = this.props;
        const {idUrl} = this.state;
        const id = "export-docs-" + idSuffix;
        const title: ILocalizedText = (intl) =>
            intl2Str(
                intl,
                "Export to LUM proprietary file format suitable for import by SEPView.",
            );

        return (
            <FormSubmit
                action={"/api/export/docs" + idUrl}
                className={"btn btn-primary " + className}
                disabled={disabled}
                icon={faFileArchive}
                method="GET"
                name={<T>Export</T>}
                idSuffix={id}
                title={title}
                warningXerror={this.getTooltip()}
                getJson={this.getJson}
                onChange={this.onChange}
            />
        );
    }

    private getExportField(item: IPartialSelectedItem): IDocType {
        switch (item.prefix) {
            case EDocIdPrefixes.CentMeasures:
                return "centrifugeMeasurements";

            case EDocIdPrefixes.FracMeasures:
                return "fractureMeasurements";

            case EDocIdPrefixes.SpocMeasures:
                return "spocMeasurements";

            case EDocIdPrefixes.SpocAlignments:
                return "spocAlignments";

            case EDocIdPrefixes.CentAnalyses:
                return "centrifugeAnalyses";

            case EDocIdPrefixes.FracAnalyses:
                return "fractureAnalyses";

            case EDocIdPrefixes.SpocAnalyses:
                return "spocAnalyses";

            case EDocIdPrefixes.CentSops:
                return "centrifugeSops";

            case EDocIdPrefixes.FracSops:
                return "fractureSops";

            case EDocIdPrefixes.SpocSops:
                return "spocSops";

            case EDocIdPrefixes.CentReports:
            case EDocIdPrefixes.SpocReports:
                return "reports";
        }
    }

    private getTooltip() {
        const {warning} = this.state;

        if (warning === EMissingExport.None) {
            return undefined;
        }

        const fragment = (item: JSX.Element) => (
            <React.Fragment>
                <br />
                {item}
            </React.Fragment>
        );

        return (
            <span className="text-warning">
                <T>
                    Exported document is corrupted. Import might fail. Please
                    contact service with support data provided.
                </T>
                {!!bitwiseAND(warning, EMissingExport.Normalization) &&
                    fragment(<T>Normalization is missing.</T>)}
                {!!bitwiseAND(warning, EMissingExport.Material) &&
                    fragment(<T>Material is missing.</T>)}
                {!!bitwiseAND(warning, EMissingExport.Stamp) &&
                    fragment(<T>Stamp is missing.</T>)}
                {!!bitwiseAND(warning, EMissingExport.Sample) &&
                    fragment(<T>Sample is missing.</T>)}
            </span>
        );
    }
}

export default ExportButton;

import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {EPressureUnits} from "./models";

import InlineRadios from "@toolbox/button-like/InlineRadios";
import {getPressureUnit} from "@toolbox/functions/units/pressure";
import T from "@translate/T";

interface IPressureUnitProps {
    intl: IntlShape;
    value: EPressureUnits;
    onChange(value: EPressureUnits): void;
}

class PressureUnit extends React.PureComponent<IPressureUnitProps> {
    public render() {
        const {intl, onChange, value} = this.props;

        return (
            <div className="form-row mb-3">
                <div className="col-sm-2">
                    <label>
                        <T>Measurement unit</T>
                    </label>
                </div>

                <div className="col-sm-10">
                    <InlineRadios<EPressureUnits>
                        idSuffix="units"
                        selected={value}
                        onSelected={onChange}
                        values={[
                            <React.Fragment key={EPressureUnits.Pa}>
                                {getPressureUnit(EPressureUnits.Pa)(intl)}
                            </React.Fragment>,
                            <React.Fragment key={EPressureUnits.Psi}>
                                {getPressureUnit(EPressureUnits.Psi)(intl)}
                            </React.Fragment>,
                            <React.Fragment key={EPressureUnits.Nmm2}>
                                {getPressureUnit(EPressureUnits.Nmm2)(intl)}
                            </React.Fragment>,
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(PressureUnit);

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";
import {Route, RouteComponentProps, Switch} from "react-router";
import {Titled} from "react-titled";

import http from "@/services/http";
import navLinks from "@/services/nav-links";
import {IProjectModel} from "./models";

import AccessDenied from "@toolbox/render-page/AccessDenied";
import Loading from "@toolbox/render-page/Loading";
import NotFound from "@toolbox/render-page/NotFound";
import T, {intl2Str} from "@translate/T";
import DeleteProject from "./DeleteProject";
import Import from "./import/Index";
import Search from "./search/Index";

// Customers without LUMiFrac devices won't use fracture
const Frac = React.lazy(
    () => import(/* webpackChunkName: "fracture" */ "./frac/Index"),
);
// Customers without LUMiReader, LUMiSizer devices won't use centrifuge
const Cent = React.lazy(
    () => import(/* webpackChunkName: "centrifuge" */ "./cent/Index"),
);
// Customers without LUMiSpoc won't use spoc
const Spoc = React.lazy(
    () => import(/* webpackChunkName: "spoc" */ "./spoc/Index"),
);

interface IProjectViewProps {
    intl: IntlShape;
    id: number;
    baseUrl: string;
}

interface IProjectViewState {
    name: string;
    isLoading: boolean;
    isNotFound: boolean;
    isForbidden: boolean;
}

class ProjectView extends React.PureComponent<
    IProjectViewProps,
    IProjectViewState
> {
    public readonly state: IProjectViewState = {
        name: "",
        isLoading: true,
        isNotFound: false,
        isForbidden: false,
    };

    public componentDidMount() {
        const {intl, id} = this.props;

        if (!isNaN(id)) {
            this.retrieveProject();
        } else {
            this.setState({
                isLoading: false,
                isNotFound: false,
                isForbidden: false,
                name: intl2Str(intl, "Alignment"),
            });
        }
    }

    public componentWillUnmount() {
        navLinks.items = [];
    }

    @boundMethod
    public getTitle(parent: string) {
        return this.state.name + " | " + parent;
    }

    public render() {
        const {baseUrl, id} = this.props;
        const {isLoading, isNotFound, isForbidden} = this.state;

        if (isLoading) {
            return <Loading />;
        }

        if (isNotFound) {
            return <NotFound id={id} item={<T>The specified project</T>} />;
        }

        if (isForbidden) {
            return <AccessDenied reason={<T>view this project</T>} />;
        }

        return (
            <Titled title={this.getTitle}>
                <Switch>
                    <Route
                        path={`${baseUrl}/centrifuge`}
                        render={this.renderCentrifuge}
                    />
                    <Route
                        path={`${baseUrl}/fracture`}
                        render={this.renderFracture}
                    />
                    <Route path={`${baseUrl}/spoc`} render={this.renderSpoc} />
                    <Route
                        path={`${baseUrl}/import`}
                        render={this.renderImport}
                    />
                    <Route
                        path={`${baseUrl}/delete`}
                        render={this.renderDelete}
                    />

                    <Route render={this.renderSearch} />
                </Switch>
            </Titled>
        );
    }

    @boundMethod
    private renderDelete() {
        return <DeleteProject id={this.props.id} name={this.state.name} />;
    }

    @boundMethod
    private renderImport() {
        return <Import project={this.props.id} />;
    }

    @boundMethod
    private renderFracture(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Frac project={this.props.id} baseUrl={props.match.url} />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderSpoc(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Spoc
                    project={this.props.id}
                    baseUrl={props.match.url}
                    history={props.history}
                    match={props.match}
                    location={props.location}
                />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderCentrifuge(props: RouteComponentProps) {
        return (
            <React.Suspense fallback={<Loading />}>
                <Cent
                    project={this.props.id}
                    baseUrl={props.match.url}
                    history={props.history}
                    match={props.match}
                    location={props.location}
                />
            </React.Suspense>
        );
    }

    @boundMethod
    private renderSearch(props: RouteComponentProps) {
        return (
            <Search
                history={props.history}
                location={props.location}
                match={props.match}
                project={this.props.id}
            />
        );
    }

    private async retrieveProject() {
        const {baseUrl, id: project} = this.props;

        try {
            const response = await http
                .get("/api/projects/" + project)
                .json<IProjectModel>();

            this.setState({
                isLoading: false,
                isNotFound: false,
                isForbidden: false,
                name: response.name,
            });

            navLinks.items = [{text: response.name, url: baseUrl}];
        } catch (error) {
            if (error instanceof http.HTTPError) {
                if (error.response.status === 403) {
                    this.setState({isLoading: false, isForbidden: true});
                    return;
                }
            }

            this.setState({isLoading: false, isNotFound: true});
        }
    }
}

export default injectIntl(ProjectView);

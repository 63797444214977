import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {getCentColorImage, getSchemeImage} from "./images";
import {EDataColorSchemes} from "./models";

import InlineRadios from "@toolbox/button-like/InlineRadios";
import T, {intl2Str, parseNumber} from "@translate/T";

interface IFingerprintSchemeProps {
    intl: IntlShape;
    value: EDataColorSchemes;
    onChange(value: EDataColorSchemes): void;
}

interface IFingerprintSchemeState {
    height: number;
}

class FingerprintScheme extends React.PureComponent<
    IFingerprintSchemeProps,
    IFingerprintSchemeState
> {
    public readonly state: IFingerprintSchemeState = {height: 0};

    private readonly div = React.createRef<HTMLDivElement>();

    public componentDidMount() {
        const div = this.div.current;
        const height = (div?.clientHeight ?? 0) * 0.5;
        this.setState({height});
    }

    public render() {
        const {onChange, value, intl} = this.props;

        return (
            <div className="form-row mb-3">
                <div className="col-sm-2">
                    <label>
                        <T>Fingerprint scheme</T>
                    </label>
                </div>

                <div className="col-sm-4" ref={this.div}>
                    <InlineRadios<EDataColorSchemes>
                        breakEachElement={true}
                        idSuffix="fingerprint-scheme"
                        selected={value}
                        onSelected={onChange}
                        values={this.getContent([
                            <T key={EDataColorSchemes.RdGn}>Red green</T>,
                            <T key={EDataColorSchemes.RdYlGn}>
                                Red yellow green
                            </T>,
                            <T key={EDataColorSchemes.Viridis}>Viridis</T>,
                            <T key={EDataColorSchemes.Inferno}>Inferno</T>,
                            <T key={EDataColorSchemes.Turbo}>Turbo</T>,
                            <T key={EDataColorSchemes.ColorBlind}>
                                Color blind
                            </T>,
                        ])}
                    />
                </div>

                <div className="col-sm-3 align-self-center">
                    <img
                        alt={intl2Str(intl, "Fingerprint scheme")}
                        src={getCentColorImage(value)}
                        height={this.state.height}
                    />
                </div>
            </div>
        );
    }

    @boundMethod
    private getContent(schemes: JSX.Element[]) {
        const {intl} = this.props;

        return schemes.map((scheme) => (
            <React.Fragment key={scheme.key ?? undefined}>
                {scheme}
                <br />
                <img
                    alt={intl2Str(intl, "Fingerprint scheme")}
                    src={getSchemeImage(
                        parseNumber(scheme.key as string) as EDataColorSchemes,
                    )}
                    width="256"
                    height="10"
                />
            </React.Fragment>
        ));
    }
}

export default injectIntl(FingerprintScheme);

import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {lowerCase, toLower} from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link, Redirect} from "react-router-dom";

import http from "@/services/http";
import {IPendingTaskModel} from "@toolbox/button-like/models";

import DeleteButton from "@toolbox/button-like/DeleteButton";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import T from "@translate/T";

// Simplifies the specified name to lowercase & shortens the verification
export function getVerificationName(str: string) {
    const lower = lowerCase(toLower(str));
    const words = lower.split(" ");

    return words.length <= 6 ? lower : words.slice(0, 6).join(" ");
}

export interface IDeleteProjectProps {
    id: number;
    name: string;
}

interface IDeleteProjectState {
    isDeleted: boolean;
    userInput: string;
}

class DeleteProject extends React.PureComponent<
    IDeleteProjectProps,
    IDeleteProjectState
> {
    public readonly state: IDeleteProjectState = {
        isDeleted: false,
        userInput: "",
    };

    private get isConfirmed() {
        const {name} = this.props;
        const {userInput} = this.state;

        return userInput === getVerificationName(name);
    }

    @boundMethod
    public setUserInput(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({userInput: e.target.value});
    }

    @boundMethod
    public async delete() {
        try {
            await http
                .delete("/api/projects/" + this.props.id)
                .json<IPendingTaskModel>();

            this.setState({isDeleted: true});

            return true;
        } catch {
            return false;
        }
    }

    public render() {
        const {isDeleted, userInput} = this.state;

        if (isDeleted) {
            return <Redirect to="/" push={true} />;
        }

        const {id, name} = this.props;
        const required = <kbd>{getVerificationName(name)}</kbd>;
        const projectName = <em className="text-warning"> {name} </em>;

        return (
            <ContainerCol col={6}>
                <h1>
                    <T>Delete project</T>
                </h1>
                <hr />

                <div className="form-text">
                    <b>
                        <FormattedMessage
                            id="You are going to delete project {name} and all its data. This action cannot be reversed."
                            defaultMessage="You are going to delete project {name} and all its data. This action cannot be reversed."
                            values={{name: projectName}}
                        />
                    </b>
                </div>

                <div className="mb-3">
                    <label htmlFor="project-name">
                        <FormattedMessage
                            id="Enter the project name {required} in the textbox below to confirm."
                            defaultMessage="Enter the project name {required} in the textbox below to confirm."
                            values={{required}}
                        />
                    </label>
                    <input
                        type="text"
                        id="project-name"
                        data-testid="project-name"
                        autoComplete="off"
                        className="form-control"
                        required={true}
                        value={userInput}
                        onChange={this.setUserInput}
                    />
                </div>

                <div className="d-flex">
                    <DeleteButton
                        id="delete"
                        classNameButton="mr-auto"
                        disabled={!this.isConfirmed}
                        noAnimation={true}
                        skipConfirm={true}
                        onDelete={this.delete}
                    />

                    <Link to={`/project/${id}/edit`}>
                        <button type="button" className="btn btn-secondary">
                            <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            <T>Cancel</T>
                        </button>
                    </Link>
                </div>
            </ContainerCol>
        );
    }
}

export default DeleteProject;

import {EDocumentTypes} from "@/components/project/models";
import {
    IPagedList,
    IProjectDocumentModel,
} from "@toolbox/building-blocks/models";
import {IUserInfoModel} from "../models";

export enum EAuditActions {
    Create,
    Modify,
    Rename,
    Delete,
    Import,
    Enqueue,
    Dequeue,
    LoginSuccess,
    LoginFail,
}

export interface IAuditEntryModelBase {
    id: number;
    action: EAuditActions;
    author: IUserInfoModel;
    time: string;
}

export interface IDetailAuditEntryModel extends IAuditEntryModelBase {
    document: IProjectDocumentModel;
}

export interface IAuditEntryRequest {
    actions: EAuditActions[];
    types: EDocumentTypes[];
    from: string | null;
    page: number;
    pageSize: number;
    query: string;
    until: string | null;
    user: string;
}

export type IAuditEntryResponse = IPagedList<IDetailAuditEntryModel>;

import {boundMethod} from "autobind-decorator";
import React from "react";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import signal from "@/services/signal";
import {IImportFile, IImportSession, EImportProgress} from "./models";

import AccessDenied from "@toolbox/render-page/AccessDenied";
import T from "@translate/T";
import ImportPage from "./ImportPage";

interface IImportProps {
    project: number;
}

interface IImportState {
    clientId: string;
    files: IImportFile[];
}

class Import extends React.PureComponent<IImportProps, IImportState> {
    public readonly state: IImportState = {clientId: "", files: []};

    private unsubscribe?: () => void;

    public async componentDidMount() {
        try {
            const response = await http.get("/api/data/import/id");
            const clientId = await response.text();

            this.unsubscribe = await signal.registerImportHandler(
                clientId,
                this.updateProgress,
            );

            this.setState({clientId});
        } catch {
            this.setState({clientId: ""});
        }
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public onUpload(files: IImportFile[], accepted: File[]) {
        this.setState({files}, () => {
            if (accepted.length) {
                this.upload(accepted);
            }
        });
    }

    public render() {
        const {project} = this.props;
        const {files, clientId} = this.state;

        if (!session.hasRole(ERoles.Editor, project)) {
            return <AccessDenied reason={<T>import data to this project</T>} />;
        }

        return (
            <ImportPage
                title={<T>Data import</T>}
                files={files}
                accept=".xlsx,.lum,.lum7"
                // maxSize={1073741824 /*1GB*/}
                clientId={clientId}
                project={project}
                onUpload={this.onUpload}
            />
        );
    }

    private async upload(accepted: File[]) {
        const {project} = this.props;
        const {clientId} = this.state;

        const form = new FormData();
        form.append("client", clientId);
        for (const file of accepted) {
            form.append(file.name, file);
        }

        try {
            const endpoint = `/api/data/import/${project}`;
            const response = await http.post(endpoint, {body: form});

            if (!response.ok) {
                throw new http.HTTPError(response);
            }
        } catch (error) {
            // Http Error, 413 Request Entity Too Large
            if (error instanceof http.HTTPError) {
                if (error.response.status === 413) {
                    return this.handleError(
                        EImportProgress.FileTooLarge,
                        accepted,
                    );
                }
            }

            // Error, set status as not imported
            this.handleError(EImportProgress.NotImported, accepted);
        }
    }

    @boundMethod
    private updateProgress(imports: IImportSession) {
        const {clientId, files} = this.state;
        if (imports.clientId !== clientId) {
            return;
        }

        const updated = [...files];
        for (const file of imports.files) {
            const {importedDocs, name} = file;
            const index = updated.findIndex((x) => x.name === name);

            if (index < 0) {
                updated.push(file);
                continue;
            }

            const docs = [...updated[index].importedDocs];
            for (const doc of importedDocs) {
                if (
                    docs.find(
                        (x) =>
                            x.id === doc.id &&
                            x.type === doc.type &&
                            x.deviceClass === doc.deviceClass,
                    )
                ) {
                    continue;
                }

                docs.push(doc);
            }

            updated.splice(index, 1, {...file, importedDocs: docs});
        }

        this.setState({files: updated});
    }

    private async handleError(progress: EImportProgress, accepted: File[]) {
        const files = [] as IImportFile[];
        for (const {name} of accepted) {
            files.push({importedDocs: [], name, progress});
        }

        this.setState({files});
    }
}

export default Import;

import calibration from "@/images/icons/calibration.svg";
import fracture from "@/images/icons/fracture.svg";
import measurement from "@/images/icons/measurement.svg";
import report from "@/images/icons/report.svg";
import sop from "@/images/icons/sop.svg";

import {EModules} from "@/models";
import {
    getDeviceDisplayName,
    convertString2Device,
} from "@toolbox/functions/device-check";
import {EDeviceClasses} from "@toolbox/models";
import {ILocalizedText} from "@translate/models";
import {EDocIdPrefixes, IDocIdInfo} from "./models";

import {getModuleInfo} from "@toolbox/display-blocks/ModuleIcon";
import {intl2Str} from "@translate/T";

export function getDocIdInfo(
    docId: string,
    project: number,
    isCalibration?: boolean,
): IDocIdInfo {
    const parts = docId.split("/");
    const id = parts[1];
    const prefix = parts[0] as EDocIdPrefixes;

    switch (prefix) {
        case EDocIdPrefixes.CentMeasures: {
            const device = convertString2Device(parts[2]);
            const deviceName = getDeviceDisplayName(device);
            const type: ILocalizedText = (intl) =>
                intl2Str(intl, "{deviceName} measurement", {deviceName});

            return {
                device,
                icon: measurement,
                id,
                prefix,
                type,
                url: `/project/${project}/centrifuge/measurement/${id}`,
            };
        }

        case EDocIdPrefixes.FracMeasures:
            return {
                device: EDeviceClasses.Fracture,
                icon: measurement,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiFrac measurement"),
                url: `/project/${project}/fracture/measurement/${id}`,
            };

        case EDocIdPrefixes.SpocMeasures: {
            if (isCalibration) {
                return {
                    device: EDeviceClasses.Spoc,
                    icon: calibration,
                    id,
                    prefix,
                    type: (intl) => intl2Str(intl, "LUMiSpoc calibration"),
                    url: `/project/${project}/spoc/measurement/${id}`,
                };
            }

            return {
                device: EDeviceClasses.Spoc,
                icon: measurement,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiSpoc measurement"),
                url: `/project/${project}/spoc/measurement/${id}`,
            };
        }

        case EDocIdPrefixes.SpocAlignments: {
            return {
                device: EDeviceClasses.Spoc,
                icon: calibration,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiSpoc alignment"),
                url: `alignments/spoc/alignment/${id}`,
            };
        }

        case EDocIdPrefixes.CentAnalyses: {
            const engine = parts.slice(2).join("/") as EModules;
            const analysis = getModuleInfo(engine);

            return {
                icon: analysis.icon,
                id,
                prefix,
                type: analysis.type,
                url: `/project/${project}/centrifuge/analysis/${id}`,
            };
        }

        case EDocIdPrefixes.FracAnalyses:
            return {
                icon: fracture,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiFrac analysis"),
                url: `/project/${project}/fracture/analysis/${id}`,
            };

        case EDocIdPrefixes.SpocAnalyses: {
            const engine = parts.slice(2).join("/") as EModules;
            const analysis = getModuleInfo(engine);

            return {
                icon: analysis.icon,
                id,
                prefix,
                type: analysis.type,
                url: `/project/${project}/spoc/analysis/${id}`,
            };
        }

        case EDocIdPrefixes.CentSops: {
            const device = convertString2Device(parts[2]);
            const deviceName = getDeviceDisplayName(device);
            const type: ILocalizedText = (intl) =>
                intl2Str(intl, "{deviceName} SOP", {deviceName});

            return {
                icon: sop,
                id,
                prefix,
                type,
                url: `/project/${project}/centrifuge/sop/${id}`,
            };
        }

        case EDocIdPrefixes.FracSops:
            return {
                icon: sop,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiFrac SOP"),
                url: `/project/${project}/fracture/sop/${id}`,
            };

        case EDocIdPrefixes.SpocSops:
            return {
                icon: sop,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "LUMiSpoc SOP"),
                url: `/project/${project}/spoc/sop/${id}`,
            };

        case EDocIdPrefixes.CentReports: {
            return {
                icon: report,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "Analysis report"),
                url: `/project/${project}/centrifuge/report/${id}`,
            };
        }

        case EDocIdPrefixes.SpocReports: {
            return {
                icon: report,
                id,
                prefix,
                type: (intl) => intl2Str(intl, "Analysis report"),
                url: `/project/${project}/spoc/report/${id}`,
            };
        }
    }
}

import {IPagedList} from "@toolbox/building-blocks/models";
import {IProjectStats} from "../models";

export const MIN_WIDTH_PER_PROJECT = 300;

export enum EProjectDisplayModes {
    Cards,
    List,
}

export type IProjectResponse = IPagedList<IProjectStats>;

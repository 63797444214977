import {round} from "lodash";
import {EAxisScale, ILabel} from "@chart/models";

import {ILocalizedText} from "@translate/models";

import {intl2Str} from "@translate/T";

export function getAxisScale(scale: EAxisScale): ILocalizedText {
    return scale === EAxisScale.Lin
        ? (intl) => intl2Str(intl, "lin")
        : (intl) => intl2Str(intl, "log");
}

/** Concatenates label name, unit and scaling, if unit/scaling is defined. */
export function getDisplayLabel(
    label: ILabel,
    opts?: {nameOnly?: boolean; omitScale?: boolean; short?: number},
): ILocalizedText {
    return (intl) => {
        let name = label.name(intl);
        const unit = label.unit?.(intl);
        const scale = label.scaling?.(intl);

        if (opts?.short && name.length > opts.short) {
            name = name.slice(0, opts.short) + "...";
        }

        if (opts?.nameOnly || !unit || unit === "-") {
            if (scale && !opts?.omitScale) {
                return intl2Str(intl, "{name} / a.u. ({scale})", {name, scale});
            }

            return name;
        }

        if (scale && !opts?.omitScale) {
            return intl2Str(intl, "{name} / {unit} ({scale})", {
                name,
                scale,
                unit,
            });
        }

        return intl2Str(intl, "{name} / {unit}", {name, unit});
    };
}

export function adjustCanvas(
    canvas: HTMLCanvasElement,
    rect?: {width: number; height: number},
) {
    if (!rect) {
        const box = canvas.getBoundingClientRect();
        rect = {height: box.height, width: box.width};
    }

    const width = round(rect.width * devicePixelRatio);
    if (canvas.width !== width) {
        canvas.width = width;
    }

    const height = round(rect.height * devicePixelRatio);
    if (canvas.height !== height) {
        canvas.height = height;
    }
}

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import license from "@/services/license";
import networkDevices from "@/services/network-devices";
import preferences from "@/services/preferences";
import {IDeviceId} from "@toolbox/models";
import {EConnection, IDeviceConnection} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import T, {parseNumber} from "@translate/T";

export function getDeviceConnection(device: IDeviceId) {
    return preferences.value.deviceConnections.find((x) =>
        networkDevices.sameSeries({series: x.series, fabrication: 0}, device),
    );
}

interface IDeviceConnectionProps {
    intl: IntlShape;
    values: IDeviceConnection[];
    onChange(value: IDeviceConnection[]): void;
}

class DeviceConnection extends React.PureComponent<IDeviceConnectionProps> {
    private get options() {
        const {values} = this.props;
        const {devices} = license.value;
        const options = [];

        for (const [i, value] of values.entries()) {
            if (
                devices.some((device) =>
                    networkDevices.sameSeries(
                        {series: value.series, fabrication: 0},
                        device,
                    ),
                )
            ) {
                options.push(i);
            }
        }

        return options;
    }

    @boundMethod
    public onEthernetToggle(e: React.ChangeEvent<HTMLInputElement>) {
        const {values} = this.props;
        const ids = e.target.id.split("-");
        const i = parseNumber(ids[ids.length - 1]);
        const deviceConnection: IDeviceConnection = {...values[i]};

        if (e.target.checked) {
            deviceConnection.connection = EConnection.Both;
            this.onChange(deviceConnection, i);
        } else {
            deviceConnection.connection = EConnection.Usb;
            this.onChange(deviceConnection, i);
        }
    }

    @boundMethod
    public onUsbToggle(e: React.ChangeEvent<HTMLInputElement>) {
        const {values} = this.props;
        const ids = e.target.id.split("-");
        const i = parseNumber(ids[ids.length - 1]);
        const deviceConnection: IDeviceConnection = {...values[i]};

        if (e.target.checked) {
            deviceConnection.connection = EConnection.Both;
            this.onChange(deviceConnection, i);
        } else {
            deviceConnection.connection = EConnection.Ethernet;
            this.onChange(deviceConnection, i);
        }
    }

    public onChange(deviceConnection: IDeviceConnection, i: number) {
        const {onChange, values} = this.props;
        const deviceConnections = [...values];
        deviceConnections[i] = deviceConnection;

        onChange(deviceConnections);
    }

    public render() {
        const options = this.options;
        if (!options.length) {
            return null;
        }

        return (
            <div className="form-row mb-3">
                <div className="col-sm-2">
                    <label>
                        <T>Device connection</T>
                    </label>
                </div>

                <div className="col-sm-10">
                    {options.map((x) => this.renderConnection(x))}
                </div>
            </div>
        );
    }

    private renderConnection(i: number) {
        const {intl, values} = this.props;
        const value = values[i];

        return (
            <div key={i}>
                <div>
                    {networkDevices.getDeviceName(intl, {
                        deviceClass: value.deviceClass,
                        series: value.series,
                        fabrication: 0,
                    })}
                </div>

                <InlineCheckbox
                    idSuffix={"device-connection-ethernet-" + i}
                    checked={value.connection !== EConnection.Usb}
                    toggle={this.onEthernetToggle}
                >
                    <T>Ethernet</T>
                </InlineCheckbox>
                <InlineCheckbox
                    idSuffix={"device-connection-usb-" + i}
                    checked={value.connection !== EConnection.Ethernet}
                    toggle={this.onUsbToggle}
                >
                    <T>USB</T>
                </InlineCheckbox>

                {/* <InlineRadios<EConnection> 
                    idSuffix="device-connection"
                    selected={value.connection}
                    onSelected={localChange}
                    values={[
                        <React.Fragment key={EConnection.Ethernet}>
                            <T>Ethernet</T>
                        </React.Fragment>,
                        <React.Fragment key={EConnection.Usb}>
                            <T>USB</T>
                        </React.Fragment>,
                    ]}
                /> */}
            </div>
        );
    }
}

export default injectIntl(DeviceConnection);

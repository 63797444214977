import {debounce} from "lodash";

import EventHandlers from "@/services/handlers";
import {ELoadingStatus} from "@/services/models";
import {BUTTON_ANIMATION_DELAY} from "@toolbox/models";

interface IEventHandler {
    loadingChanged?: (status: ELoadingStatus, count: number) => void;
}

class LoadingService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    private readonly trigger = debounce(
        () => (this.status = ELoadingStatus.Nothing),
        BUTTON_ANIMATION_DELAY,
    );

    private _status = ELoadingStatus.Nothing;
    private _count = 0;

    public constructor() {
        const pushState = history.pushState;
        // tslint:disable-next-line:only-arrow-functions
        history.pushState = function () {
            loading.reset();

            return pushState.apply(
                history,
                arguments as unknown as [
                    data: any,
                    unused: string,
                    url?: string | URL | null | undefined,
                ],
            );
        };
    }

    public get count() {
        return this._count;
    }

    /** Gets a value indicating if there is any resource loaded from server */
    public get status() {
        return this._status;
    }

    /** Sets a value indicating that a @see resource is loaded from server */
    public set status(status: ELoadingStatus) {
        switch (status) {
            case ELoadingStatus.Waiting:
                this.trigger.cancel();
                this._count++;
                break;

            default:
                this._count--;
                break;
        }

        // do not override "all good" when we have an error
        if (
            status === ELoadingStatus.Success &&
            this._status === ELoadingStatus.Failed
        ) {
            return;
        }

        if (status === ELoadingStatus.Success && this._count <= 0) {
            this.trigger();
        }

        this._status = status;
        this.handlers.publish((x) => x.loadingChanged?.(status, this._count));
    }

    public reset() {
        if (!this.count && this._status === ELoadingStatus.Nothing) {
            return;
        }

        this._count = 0;
        this._status = ELoadingStatus.Nothing;
        this.handlers.publish((x) =>
            x.loadingChanged?.(this._status, this._count),
        );
    }

    public catchApiAbort(error: unknown) {
        if (this._count <= 0) {
            return; // Do not show error modal, if there was no counting.
        }

        const err = (error as object).toString();
        if (
            err.includes("The user aborted a request.") ||
            err.includes("The operation was aborted.")
        ) {
            loading.status = ELoadingStatus.Aborted;
        }
    }

    /** Subscribes to loading state change event */
    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }
}

const loading = new LoadingService();
export default loading;

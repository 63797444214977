import {
    faCaretDown,
    faCaretRight,
    faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {motion} from "framer-motion";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {animation, onClosed, onOpen} from "./models";

import {intl2Str} from "@translate/T";

export interface ICardProps {
    title: JSX.Element | string;

    className?: string;
    headerClassName?: string;
    style?: React.CSSProperties;
    suffixId?: string;

    /** Optional value to do not show card body */
    collapsed?: boolean;

    /** Optional to remove margin mb-3 */
    noMargin?: boolean;

    xOverflow?: boolean;
    yOverflow?: boolean;

    onTitleClick?(collapsed: boolean): void;
    toolTip?: ILocalizedText;
    renderButtons?(intl: IntlShape): JSX.Element | null;
    renderCustomize?(): JSX.Element | null;
    onCustomizeAnimationComplete?(): void;

    /** Give Card optional Function to toggle as well once you press the menu-button. */
    onToggle?(showCustomize: boolean): void;
}

interface ICardState {
    showCustomize?: boolean;
}

class Card extends React.PureComponent<ICardProps, ICardState> {
    public readonly state: ICardState = {};

    public readonly card = React.createRef<HTMLDivElement>();

    private readonly buttonGroup = React.createRef<HTMLDivElement>();
    private disabled: (string | null)[] = [];

    public componentDidMount(): void {
        const {collapsed} = this.props;

        if (collapsed) {
            this.disable(this.props.collapsed);
        }
    }

    public componentDidUpdate(prevProps: Readonly<ICardProps>): void {
        const {collapsed} = this.props;

        if (collapsed !== prevProps.collapsed) {
            this.disable(collapsed);
        }
    }

    private disable(collapsed?: boolean) {
        if (collapsed) {
            this.disabled = [];
        }

        const buttons =
            this.buttonGroup.current?.querySelectorAll("button.btn") ?? [];
        for (const [i, a] of buttons.entries()) {
            if (collapsed) {
                this.disabled.push(a.getAttribute("disabled"));
                a.setAttribute("disabled", "");
            } else {
                if (typeof this.disabled[i] !== "string") {
                    a.removeAttribute("disabled");
                }
            }
        }
    }

    @boundMethod
    public toggleFromOutside(showCustomize: boolean) {
        this.setState({showCustomize});
    }

    @boundMethod
    public toggleCustomize(e: React.SyntheticEvent) {
        e.preventDefault();

        const showCustomize = !this.state.showCustomize;
        this.setState({showCustomize}, () =>
            this.props.onToggle?.(showCustomize),
        );
    }

    @boundMethod
    public onTitleClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {collapsed, onTitleClick} = this.props;
        if (!collapsed) {
            // if we remove body, remove customize as well
            this.toggleFromOutside(false);
        }

        onTitleClick?.(!collapsed);
    }

    public render() {
        const {noMargin, className, suffixId, style} = this.props;
        const margin = noMargin ? "" : " mb-3";
        const extra = className ? " " + className : "";

        return (
            <div
                ref={this.card}
                className={`card${margin}${extra}`}
                id={"card" + (suffixId ? "-" + suffixId : "")}
                data-testid={"card" + (suffixId ? "-" + suffixId : "")}
                style={style}
            >
                {this.renderHeader()}
                {this.renderCustomize()}
                {this.renderBody()}
            </div>
        );
    }

    private renderHeader() {
        const {headerClassName} = this.props;

        let className = "card-header has-buttons bg-primary";
        if (headerClassName) {
            if (headerClassName.includes("bg-")) {
                className = `card-header has-buttons ${headerClassName}`;
            } else {
                className += ` ${headerClassName}`;
            }
        }

        return (
            <div className={className}>
                <IntlContext.Consumer children={this.renderTitle} />
                <div className="card-height" />
                <IntlContext.Consumer children={this.renderButtons} />
            </div>
        );
    }

    @boundMethod
    private renderTitle(intl: IntlShape) {
        const {
            title: renderTitle,
            toolTip,
            onTitleClick,
            collapsed,
        } = this.props;

        let icon = null;
        let className;
        let onClick;
        let title = toolTip?.(intl);

        if (onTitleClick) {
            className = "pointer-cursor";
            onClick = this.onTitleClick;
            title = collapsed
                ? intl2Str(intl, "Click to expand.")
                : intl2Str(intl, "Click to collapse.");
            icon = (
                <FontAwesomeIcon
                    icon={collapsed ? faCaretRight : faCaretDown}
                    fixedWidth={true}
                />
            );
        }

        return (
            <div className={className} onClick={onClick} title={title}>
                {icon}
                {renderTitle}
            </div>
        );
    }

    @boundMethod
    private renderButtons(intl: IntlShape) {
        const {headerClassName, renderButtons, renderCustomize} = this.props;

        let toggle: JSX.Element | null = null;
        if (renderCustomize?.()) {
            let toggleClass = "btn btn-primary";
            if (headerClassName?.includes("bg-")) {
                const className = headerClassName
                    .split(" ")
                    .find((x) => x.includes("bg-"))!
                    .slice(3);
                toggleClass = `btn btn-${className}`;
            }

            if (this.state.showCustomize) {
                toggleClass += " active";
            }

            const customizeLabel = intl2Str(intl, "Customize");

            toggle = (
                <button
                    type="button"
                    className={toggleClass}
                    title={customizeLabel}
                    aria-label={customizeLabel}
                    onClick={this.toggleCustomize}
                >
                    <FontAwesomeIcon icon={faSlidersH} fixedWidth={true} />
                </button>
            );
        }

        const others = renderButtons?.(intl) ?? null;

        if (!toggle && !others) {
            return null;
        }

        return (
            <div ref={this.buttonGroup} className="btn-group btn-group-sm ml-1">
                {others}
                {toggle}
            </div>
        );
    }

    private renderCustomize() {
        const {renderCustomize, onCustomizeAnimationComplete} = this.props;
        const {showCustomize} = this.state;
        if (!showCustomize) {
            return null;
        }

        if (renderCustomize !== undefined) {
            return this.renderMotion(
                this.renderBorder(renderCustomize()),
                onCustomizeAnimationComplete,
            );
        }

        return null;
    }

    private renderBody() {
        const {children, collapsed, xOverflow, yOverflow} = this.props;

        if (collapsed) {
            return null;
        }

        let className = "";
        if (xOverflow) {
            className = "div-overflow-x";
        }

        if (yOverflow) {
            className = "div-overflow-y";
        }

        if (!className) {
            return this.renderMotion(children);
        }

        return <div className={className}>{this.renderMotion(children)}</div>;
    }

    private renderBorder(child: React.ReactNode) {
        return <div className="border-bottom">{child}</div>;
    }

    private renderMotion(
        child: React.ReactNode,
        onAnimationComplete?: () => void,
    ) {
        return (
            <motion.div
                animate={onOpen}
                exit={onClosed}
                initial={onClosed}
                transition={animation}
                onAnimationComplete={onAnimationComplete}
            >
                {child}
            </motion.div>
        );
    }
}

export default Card;

import {Location} from "history";
import React from "react";
import {Redirect, RouteComponentProps} from "react-router";

function getRedirectUrl(location: Location) {
    const existing = new URLSearchParams(location.search).get("redirect");
    if (existing) {
        return existing;
    }

    return location.pathname + location.search;
}

function createRedirector(target: string) {
    class Redirector extends React.PureComponent<RouteComponentProps> {
        public render() {
            const params = new URLSearchParams();
            params.set("redirect", getRedirectUrl(this.props.location!));

            return (
                <Redirect
                    to={{pathname: target, search: "?" + params}}
                    push={true}
                />
            );
        }
    }

    return Redirector;
}

export default createRedirector;

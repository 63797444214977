export function flipString(str: string) {
    return str.split("").reverse().join("");
}

export function replaceLast(
    str: string,
    searchString: string,
    replaceValue: string = "",
) {
    const index = str.lastIndexOf(searchString);
    if (index === -1) {
        return str;
    }

    return (
        str.slice(0, index) +
        replaceValue +
        str.slice(index + searchString.length)
    );
}

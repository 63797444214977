import {boundMethod} from "autobind-decorator";
import {kebabCase} from "lodash";
import React from "react";
import {Link} from "react-router-dom";

import {
    IMaterial,
    IMaterialDetailModel,
    EInvalidMaterialProperty,
} from "@/components/materials/models";
import {EModules} from "@/models";
import http from "@/services/http";
import {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED} from "@toolbox/functions/models";
import {ILocalizedText} from "@translate/models";
import {
    ICalcMaterial,
    ICalcMaterialRequest,
    ICalcMaterialResponse,
    IMaterialDetailRequest,
    IMaterialValues,
} from "./models";

import Tooltip from "@toolbox/building-blocks/Tooltip";
import {bitwiseAND} from "@toolbox/functions/bitwise";
import {parseNaNText} from "@toolbox/functions/requests";
import T from "@translate/T";
import MaterialModal from "./MaterialModal";

export async function loadCalcMaterialData(
    materialId: number,
    temperature?: number,
    wavelength?: number,
): Promise<ICalcMaterial> {
    const json: ICalcMaterialRequest = {
        temperature,
        wavelength,
    };

    try {
        const response = await http
            .post(`/api/material/${materialId}/calc`, {json})
            .json<ICalcMaterialResponse>();

        return {
            materialId,
            viscosity: parseNaNText(response.viscosity),
            density: parseNaNText(response.density),
            rpri: parseNaNText(response.rpri),
            ipri: parseNaNText(response.ipri),
            attenuation: parseNaNText(response.attenuation),
        };
    } catch {
        return {
            materialId,
            viscosity: NaN,
            density: NaN,
            rpri: NaN,
            ipri: NaN,
            attenuation: NaN,
        };
    }
}

export function getMaterialWarning(
    incompleteMaterial: boolean,
    invalidTempWave: boolean,
    materialData?: IMaterialValues,
) {
    if (incompleteMaterial) {
        if (!materialData) {
            return <T>Material detail definition incomplete.</T>;
        }

        const fragment = (item: JSX.Element) => (
            <React.Fragment>
                <br />
                {item}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <T>Material detail definition incomplete.</T>
                {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED(materialData.viscosity) &&
                    fragment(<T>Viscosity is incomplete.</T>)}
                {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED(materialData.density) &&
                    fragment(<T>Density is incomplete.</T>)}
                {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED(materialData.rpri) &&
                    fragment(<T>RPRI is incomplete.</T>)}
                {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED(materialData.ipri) &&
                    fragment(<T>IPRI is incomplete.</T>)}
                {CHECK_FOR_NAN_BUT_IGNORE_UNDEFINED(materialData.attenuation) &&
                    fragment(<T>Attenuation Coefficient is incomplete.</T>)}
            </React.Fragment>
        );
    }

    if (invalidTempWave) {
        return (
            <T>
                Sample temperature or wavelength is not in range of the
                Material.
            </T>
        );
    }

    return null;
}

export interface IMaterialSelectorProps {
    id: string;

    engine?: EModules;
    selected?: IMaterial;

    temperature?: number;
    wavelength?: number;

    showAll?: boolean;
    materialWarning?: JSX.Element | null;
    invalid?: EInvalidMaterialProperty;
    suppressTooltip?: boolean;
    triggerId?: string;
    overrideModalTitle?: ILocalizedText;

    onChange(material?: IMaterial): void;
    invalidTempWave?(invalidTempWave?: EInvalidMaterialProperty): void;
}

interface IMaterialSelectorState {
    material?: IMaterialDetailModel;

    showModal?: boolean;
}

class MaterialSelector extends React.PureComponent<
    IMaterialSelectorProps,
    IMaterialSelectorState
> {
    public readonly state: IMaterialSelectorState = {};

    public async componentDidMount() {
        await this.loadSelected();
    }

    public async componentDidUpdate() {
        await this.loadSelected();
    }

    @boundMethod
    public showModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showModal: true});
    }

    @boundMethod
    public onClose() {
        this.setState({showModal: false});
    }

    public render() {
        const {
            selected,
            invalid,
            materialWarning,
            id,
            triggerId,
            suppressTooltip,
        } = this.props;

        let tooltip = null;
        let className = "form-text text-middle m-0";
        if (!suppressTooltip) {
            // if this gets true, we will never enter this, hence no tooltip
            if (invalid) {
                tooltip = this.getErrorTooltip(invalid);
                className += " text-danger is-invalid";
            } else if (!selected || materialWarning) {
                tooltip = materialWarning ?? this.getWarningTooltip();
                className += " text-warning is-invalid-warning";
            }
        }

        return (
            <React.Fragment>
                <Tooltip content={tooltip} triggerId={triggerId}>
                    <Link
                        className={className}
                        id={kebabCase(id)}
                        to=""
                        onClick={this.showModal}
                    >
                        {selected?.name ?? <T>Press to select...</T>}
                    </Link>
                </Tooltip>

                {this.renderModal()}
            </React.Fragment>
        );
    }

    private renderModal() {
        const {
            id,
            engine,
            selected,
            onChange,
            temperature,
            wavelength,
            showAll,
            materialWarning,
            overrideModalTitle,
        } = this.props;
        const {showModal, material} = this.state;
        if (!showModal) {
            return null;
        }

        return (
            <MaterialModal
                id={id}
                selected={selected}
                engine={engine}
                material={material}
                temperature={temperature}
                wavelength={wavelength}
                showAll={showAll}
                materialWarning={materialWarning}
                overrideModalTitle={overrideModalTitle}
                onClose={this.onClose}
                onChange={onChange}
            />
        );
    }

    private getErrorTooltip(invalid: EInvalidMaterialProperty) {
        const fragment = (item: JSX.Element) => (
            <React.Fragment>
                <br />
                {item}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <T>Your material is bad.</T>
                {!!bitwiseAND(invalid, EInvalidMaterialProperty.Viscosity) &&
                    fragment(<T>Viscosity is bad.</T>)}
                {!!bitwiseAND(invalid, EInvalidMaterialProperty.Density) &&
                    fragment(<T>Density is bad.</T>)}
                {!!bitwiseAND(invalid, EInvalidMaterialProperty.Rpri) &&
                    fragment(<T>RPRI is bad.</T>)}
                {!!bitwiseAND(invalid, EInvalidMaterialProperty.Ipri) &&
                    fragment(<T>IPRI is bad.</T>)}
                {!!bitwiseAND(invalid, EInvalidMaterialProperty.Attenuation) &&
                    fragment(<T>Attenuation Coefficient is bad.</T>)}
            </React.Fragment>
        );
    }

    private getWarningTooltip() {
        return <T>You need to select a material.</T>;
    }

    private async loadSelected() {
        const {engine, temperature, wavelength, invalidTempWave, selected} =
            this.props;
        const materialId = selected?.id;
        if (materialId === undefined) {
            return {material: undefined};
        }

        if (materialId === this.state.material?.id) {
            return {};
        }

        const json: IMaterialDetailRequest = {
            engine,
            temperature,
            wavelength,
        };

        try {
            const response = await http
                .post(`/api/material/${materialId}`, {json})
                .json<IMaterialDetailModel>();

            invalidTempWave?.(response.invalids);
            this.setState({material: response});
        } catch {
            this.setState({material: undefined});
        }
    }
}

export default MaterialSelector;

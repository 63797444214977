import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import {IProjectModel} from "../models";

import AuditLog from "@toolbox/building-blocks/AuditLog";

class ProjectAudit extends React.PureComponent {
    public render() {
        return (
            <AuditLog<IProjectModel>
                endpoint="/api/audit/projects"
                renderEntry={this.renderEntry}
            />
        );
    }

    @boundMethod
    private renderEntry(doc: IProjectModel) {
        return <Link to={"/project/" + doc.id}>{doc.name}</Link>;
    }
}

export default ProjectAudit;

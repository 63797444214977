import "./ProjectCard.module.scss";

import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import {IProjectStats} from "../models";

import Card from "@toolbox/design/Card";
import {
    getAnalyses,
    getButtons,
    getMeasurements,
    getSops,
    getTitle,
} from "./ProjectView";

interface IProjectCardProps {
    intl: IntlShape;
    project: IProjectStats;
    width: number;
}

class ProjectCard extends React.PureComponent<IProjectCardProps> {
    public render() {
        const {intl, project, width} = this.props;
        const {analyses, id, measurements, name, sops} = project;
        const renderButtons = () => getButtons(id, intl);
        const className = "card-body" + (project.isHidden ? " bg-focus" : "");

        return (
            <Card
                headerClassName="bg-secondary"
                title={getTitle(name, id)}
                renderButtons={renderButtons}
                noMargin={true}
                style={{width}}
            >
                <div className={className}>
                    <ul className="list-unstyled mb-0">
                        <li>{getSops(intl, sops, id)}</li>
                        <li>{getMeasurements(intl, measurements, id)}</li>
                        <li>{getAnalyses(intl, analyses, id)}</li>
                    </ul>
                </div>
                <div styleName="project-card-footer" />
            </Card>
        );
    }
}

export default injectIntl(ProjectCard);

import {faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link, Redirect} from "react-router-dom";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import {ITemplateName, ITemplateNameId} from "./models";

import SaveButton from "@toolbox/button-like/SaveButton";
import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import ContainerCol from "@toolbox/display-blocks/ContainerCol";
import NotFound from "@toolbox/render-page/NotFound";
import T, {parseNumber} from "@translate/T";

export async function getTemplateWithId(
    id: number,
): Promise<ITemplateNameId | undefined> {
    try {
        const response = await http
            .get("/api/template/" + id)
            .json<ITemplateNameId>();

        return response;
    } catch {
        return undefined;
    }
}

interface IEditTemplateProps {
    id: string;
    baseUrl: string;
}

interface IEditTemplateState {
    name: string;
    isLoading: boolean;
    isNotFound: boolean;
    isSaved: boolean;
}

class EditTemplate extends React.PureComponent<
    IEditTemplateProps,
    IEditTemplateState
> {
    public readonly state: IEditTemplateState = {
        name: "",
        isLoading: true,
        isNotFound: false,
        isSaved: false,
    };

    private readonly saveButton = React.createRef<SaveButton>();

    public async componentDidMount() {
        await this.retrieveProject();
    }

    @boundMethod
    public updateName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    @boundMethod
    public async saveChanges() {
        const {id} = this.props;
        const {name} = this.state;
        const json: ITemplateName = {name};

        try {
            await http.post(`/api/template/${id}`, {json});

            this.setState({isSaved: true});

            return true;
        } catch {
            return false;
        }
    }

    @boundMethod
    public submitForm() {
        this.saveButton.current?.submitForm();
    }

    public render() {
        const {baseUrl, id} = this.props;
        const {isNotFound, isSaved, name} = this.state;

        if (isNotFound) {
            return <NotFound id={id} item={<T>The specified template</T>} />;
        }

        if (isSaved) {
            return <Redirect to={baseUrl} push={true} />;
        }

        return (
            <ContainerCol col={4}>
                <h1>
                    <T>Edit template</T>
                </h1>
                <hr />

                <ValidatedForm
                    suffixId="edit-template"
                    onSubmit={this.submitForm}
                >
                    <div className="mb-3">
                        <label htmlFor="analysis-name-trim">
                            <T>Template name</T>
                        </label>

                        <input
                            type="text"
                            id="analysis-name-trim"
                            className="form-control"
                            required={true}
                            value={name}
                            onChange={this.updateName}
                        />
                        <div className="invalid-feedback">
                            <T>Template name is required!</T>
                        </div>
                    </div>

                    <div className="d-flex mt-3">
                        {this.renderDeleteButton()}

                        <div className="ml-auto">
                            {this.renderSaveButton()}
                            <Link to={baseUrl}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        fixedWidth={true}
                                        className="mr-1"
                                    />
                                    <T>Cancel</T>
                                </button>
                            </Link>
                        </div>
                    </div>
                </ValidatedForm>
            </ContainerCol>
        );
    }

    private renderDeleteButton() {
        const {id} = this.props;
        if (!session.hasRole(ERoles.Editor)) {
            return null;
        }

        return (
            <Link to={`${this.props.baseUrl}/delete/${id}`}>
                <button type="button" className="btn btn-danger">
                    <FontAwesomeIcon
                        icon={faTrash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <T>Delete</T>
                </button>
            </Link>
        );
    }

    private renderSaveButton() {
        if (!session.hasRole(ERoles.Editor)) {
            return null;
        }

        return (
            <SaveButton
                ref={this.saveButton}
                id="saveTemplate"
                classNameButton="mr-1"
                noAnimation={true}
                onSave={this.saveChanges}
            />
        );
    }

    private async retrieveProject() {
        const ele = await getTemplateWithId(parseNumber(this.props.id));
        if (!ele) {
            this.setState({isLoading: false, isNotFound: true});
            return;
        }

        this.setState({isLoading: false, name: ele.name});
    }
}

export default EditTemplate;

import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";

import {ERoles} from "@/services/models";

import {bitwiseAND} from "@toolbox/functions/bitwise";
import T from "@translate/T";

interface IRolesRowProps {
    value: ERoles;

    showGlobal?: boolean;
    showText?: boolean;
}

class RolesRow extends React.PureComponent<IRolesRowProps> {
    public render() {
        return (
            <tr>
                {this.renderHeader()}
                {this.renderRole(ERoles.Viewer, <T>Viewer</T>)}
                {this.renderRole(ERoles.Recorder, <T>Recorder</T>)}
                {this.renderRole(ERoles.Editor, <T>Editor</T>)}
                {this.renderRole(ERoles.Manager, <T>Manager</T>)}
                {this.renderGlobals()}
            </tr>
        );
    }

    private renderHeader() {
        const {children} = this.props;
        if (!children) {
            return null;
        }

        return <th scope="row">{children}</th>;
    }

    private renderRole(role: ERoles, text: JSX.Element) {
        const {showText, value} = this.props;
        const hasRole = bitwiseAND(value, role) === role;

        return (
            <td>
                <FontAwesomeIcon
                    icon={hasRole ? faCheck : faTimes}
                    fixedWidth={true}
                    className={showText ? "mr-1" : undefined}
                />
                {!!showText && text}
            </td>
        );
    }

    private renderGlobals() {
        if (!this.props.showGlobal) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderRole(ERoles.Administrator, <T>Administrator</T>)}
                {this.renderRole(
                    ERoles.GlobalAdministrator,
                    <T>Global administrator</T>,
                )}
            </React.Fragment>
        );
    }
}

export default RolesRow;

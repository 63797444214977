import {faList, faThLarge} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import realtime from "@/services/realtime";
import {EProjectDisplayModes} from "./models";

import {intl2Str} from "@translate/T";

interface IProjectDisplayModeProps {
    intl: IntlShape;
}

interface IProjectDisplayModeState {
    display: EProjectDisplayModes;
}

class ProjectDisplayMode extends React.PureComponent<
    IProjectDisplayModeProps,
    IProjectDisplayModeState
> {
    public readonly state: IProjectDisplayModeState = {
        display: realtime.value.projectView,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const unRealtime = realtime.subscribe({
            realtimeChanged: (value) =>
                this.setState({display: value.projectView}),
        });

        this.unsubscribe = () => {
            unRealtime();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public onCardsClicked(e: React.SyntheticEvent) {
        e.preventDefault();

        realtime.setState({projectView: EProjectDisplayModes.Cards});
    }

    @boundMethod
    public onListClicked(e: React.SyntheticEvent) {
        e.preventDefault();

        realtime.setState({projectView: EProjectDisplayModes.List});
    }

    public render() {
        const {intl} = this.props;

        return (
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <button
                    type="button"
                    aria-label="project-cards"
                    id="mode-cards"
                    data-testid="mode-cards"
                    title={intl2Str(intl, "project tile view")}
                    onClick={this.onCardsClicked}
                    className={this.getButtonClass(EProjectDisplayModes.Cards)}
                >
                    <FontAwesomeIcon icon={faThLarge} fixedWidth={true} />
                </button>

                <button
                    type="button"
                    aria-label="project-list"
                    id="mode-list"
                    data-testid="mode-list"
                    title={intl2Str(intl, "project list view")}
                    onClick={this.onListClicked}
                    className={this.getButtonClass(EProjectDisplayModes.List)}
                >
                    <FontAwesomeIcon icon={faList} fixedWidth={true} />
                </button>
            </div>
        );
    }

    private getButtonClass(value: EProjectDisplayModes) {
        let className = "btn btn-secondary";

        if (value === this.state.display) {
            className += " active";
        }

        return className;
    }
}

export default injectIntl(ProjectDisplayMode);

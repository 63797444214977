import React from "react";

import {EDocumentTypes} from "@/components/project/models";
import {ERoles} from "@/services/models";
import session from "@/services/session";
import display from "@/services/display-name";

import DocumentLink from "./DocumentLink";

interface IUserLinkProps {
    username: string;
    displayName?: string;
    documentType: EDocumentTypes.User | EDocumentTypes.Group;
}

interface IUserLinkState {
    displayName: string;
}

class UserLink extends React.PureComponent<IUserLinkProps, IUserLinkState> {
    public readonly state: IUserLinkState = {
        displayName: this.props.displayName ?? "",
    };

    public async componentDidMount() {
        await this.updateName();
    }

    public async componentDidUpdate() {
        await this.updateName();
    }

    public render() {
        const {username, documentType} = this.props;
        const {displayName} = this.state;

        const id = documentType === EDocumentTypes.User ? "user" : "group";
        let type: EDocumentTypes = documentType;
        if (!session.hasRole(ERoles.Administrator)) {
            type = EDocumentTypes.Unknown;
        }

        return (
            <DocumentLink
                idSuffix={id + "-link"}
                value={{id: username, name: displayName, type, project: 0}}
            />
        );
    }

    private async updateName() {
        const {username} = this.props;
        const {displayName} = this.state;
        if (displayName === "") {
            this.setState({
                displayName: await display.getDisplayName(username),
            });
        }
    }
}

export default UserLink;

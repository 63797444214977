import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import T, {intl2Str} from "@translate/T";

interface IPasswordPanelProps {
    password: string;
    mustChange?: boolean;
    canChange?: boolean;
    onChange(password: string): void;
}

interface IPasswordPanelStates {
    showPassword: boolean;
    changePassword: boolean;
}

class PasswordPanel extends React.PureComponent<
    IPasswordPanelProps,
    IPasswordPanelStates
> {
    public readonly state: IPasswordPanelStates = {
        changePassword: false,
        showPassword: false,
    };

    @boundMethod
    public onPasswordChanged(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.props.onChange(e.target.value);
    }

    @boundMethod
    public toggleChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({changePassword: e.target.checked}, () => {
            if (!this.state.changePassword) {
                this.props.onChange("");
            }
        });
    }

    @boundMethod
    public toggleShowPassword(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showPassword: !this.state.showPassword});
    }

    public render() {
        return (
            <div className="mb-3">
                {this.renderCheckbox()}
                {this.renderNewPassword()}
            </div>
        );
    }

    private renderCheckbox() {
        const {mustChange, canChange} = this.props;
        if (mustChange) {
            return null;
        }

        return (
            <InlineCheckbox
                idSuffix="change-password"
                disabled={!canChange}
                title={
                    !canChange
                        ? (intl) => intl2Str(intl, "Not enough rights!")
                        : undefined
                }
                checked={this.state.changePassword}
                toggle={this.toggleChangePassword}
            >
                <T>Change password</T>
            </InlineCheckbox>
        );
    }

    private renderNewPassword() {
        const {changePassword, showPassword} = this.state;
        const {mustChange, password} = this.props;
        if (!mustChange && !changePassword) {
            return null;
        }

        return (
            <div>
                <label htmlFor="password">
                    <T>New password</T>
                </label>

                <div className="input-group">
                    <input
                        type={!showPassword ? "password" : "text"}
                        id="password"
                        className="form-control"
                        autoComplete="new-password"
                        required={true}
                        value={password}
                        onChange={this.onPasswordChanged}
                    />

                    <span className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-secondary rounded-right"
                            data-testid="toggle-show-password"
                            onClick={this.toggleShowPassword}
                        >
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                fixedWidth={true}
                            />
                        </button>
                    </span>

                    <div className="invalid-feedback">
                        <T>Password is required!</T>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordPanel;

import parseISO from "date-fns/parseISO";
import React from "react";
import {Link} from "react-router-dom";

import {ISample, IOnSelectHandler} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import Time from "@toolbox/design/Time";

interface ISampleWithTimestampProps {
    sample: ISample;
    onChange: IOnSelectHandler;
}

class SampleWithTimestamp extends React.PureComponent<ISampleWithTimestampProps> {
    public render() {
        const {sample, onChange} = this.props;

        return (
            <React.Fragment>
                <InlineCheckbox
                    idSuffix={sample.id}
                    className="mb-1"
                    checked={sample.selected}
                    toggle={onChange}
                >
                    {sample.prefixName && (
                        <span className="mr-1">{sample.prefixName}</span>
                    )}
                    <Link to={sample.url}>{sample.name}</Link>
                    <span className="text-muted ml-1">
                        <Time value={parseISO(sample.started)} />
                    </span>
                </InlineCheckbox>
                <br />
            </React.Fragment>
        );
    }
}

export default SampleWithTimestamp;

import {IRegionId} from "@/components/preferences/models";

export const HTTP_LOGS_KEY = "httplogs";
export const HTML_PRINT_KEY = "htmlprint";
export const SESSION_KEY = "session";
export const SIGNAL_KEY = "signalupdate";
export const SIGNAL_LOGS_KEY = "signallogs";
export const STORAGE_KEY = "language";

export enum EExpireReasons {
    LoggedOut,
    Expired,
}

export enum ELoadingStatus {
    Nothing,
    Waiting,
    Success,
    Failed,
    Aborted,
}

/** User/group roles (flags) */
export enum ERoles {
    /** No role assigned. */
    None = 0,

    /** Can view data. */
    Viewer = 1,

    /** Can record measurements. */
    Recorder = 2,

    /** Can edit data and delete own data. */
    Editor = 4,

    /** Can manage project and delete project data. */
    Manager = 8,

    /** Can manage users and groups. */
    Administrator = 16,

    /** The global administrator. */
    GlobalAdministrator = 32,
}

export interface INavLink {
    url: string;
    text: React.ReactNode;
}

export interface ILicenses {
    cent: boolean;
    frac: boolean;
    fuge: boolean;
    reader: boolean;
    sizer: boolean;
    spoc: boolean;
    xray: boolean;
}

export interface IDate {
    id: IRegionId;
    locale?: globalThis.Locale;
}

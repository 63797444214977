import {faHourglassHalf} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import license, {ILicenseStatus} from "@/services/license";

import {intl2Str} from "@translate/T";

interface ILicenseWarningState {
    value: ILicenseStatus;
}

class LicenseWarning extends React.PureComponent<{}, ILicenseWarningState> {
    public readonly state: ILicenseWarningState = {value: license.value};

    private unsubscribe?: () => void;

    public componentDidMount() {
        this.unsubscribe = license.subscribe({
            licenseChanged: (value) => this.setState({value}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        const {isValidSystemTime, licensee, serialKey, isTrial, daysLeft} =
            this.state.value;
        if (
            isValidSystemTime &&
            ((!licensee && !serialKey) || (!isTrial && daysLeft > 14))
        ) {
            return null;
        }

        return (
            <div className="alert alert-warning text-center">
                <FontAwesomeIcon
                    icon={faHourglassHalf}
                    fixedWidth={true}
                    className="mr-1"
                />
                <IntlContext.Consumer children={this.renderMessage} />
            </div>
        );
    }

    @boundMethod
    private renderMessage(intl: IntlShape) {
        const {isValidSystemTime, daysLeft, isTrial} = this.state.value;

        let systemTime = "";
        if (isValidSystemTime === false) {
            systemTime =
                " " +
                intl2Str(
                    intl,
                    "Server system date is older than the last stored date.",
                );
        }

        const buyNew =
            " " +
            intl2Str(intl, "Please consider purchasing or renew your license.");

        if (daysLeft < 0) {
            return (
                intl2Str(intl, "Your license is expired.") + buyNew + systemTime
            );
        }

        if (daysLeft <= 14 || isTrial) {
            switch (daysLeft) {
                case 1:
                    return (
                        intl2Str(intl, "Your license will expire in 1 day.") +
                        buyNew +
                        systemTime
                    );

                default:
                    return (
                        intl2Str(
                            intl,
                            "Your license will expire in {daysLeft} days.",
                            {daysLeft},
                        ) +
                        buyNew +
                        systemTime
                    );
            }
        }

        if (systemTime !== "") {
            return systemTime.substring(1);
        }

        return intl2Str(intl, "License warning");
    }
}

export default LicenseWarning;

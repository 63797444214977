import React from "react";

import {ILocalizedText} from "@translate/models";

import {parseNumber} from "@translate/T";
import InlineRadio from "./InlineRadio";

interface IInlineRadiosProps<TValue> {
    // contains the displayName & key has to be the TValue
    values: JSX.Element[];
    idSuffix: string;
    selected: TValue;

    breakEachElement?: boolean;
    dataTestId?: string;
    disabled?: boolean | boolean[];
    titles?: ILocalizedText[];

    onSelected(value: TValue): void;
}

class InlineRadios<TValue> extends React.PureComponent<
    IInlineRadiosProps<TValue>
> {
    public render() {
        return this.props.values.map((value, i) => this.renderRadio(value, i));
    }

    private renderRadio(value: JSX.Element, i: number) {
        const {
            dataTestId,
            disabled,
            idSuffix,
            onSelected,
            breakEachElement,
            selected,
            titles,
        } = this.props;
        const id = "radios-" + value.key + "-" + idSuffix;
        const radioValue = (typeof selected === "number"
            ? parseNumber(value.key as string)
            : value.key) as unknown as TValue;

        return (
            <React.Fragment key={id}>
                <InlineRadio<TValue>
                    value={radioValue}
                    idSuffix={id}
                    data-testid={dataTestId}
                    disabled={Array.isArray(disabled) ? disabled[i] : disabled}
                    isSelected={selected === radioValue}
                    title={titles?.[i]}
                    onSelected={onSelected}
                >
                    {value}
                </InlineRadio>
                {breakEachElement && <br />}
            </React.Fragment>
        );
    }
}

export default InlineRadios;

import {boundMethod} from "autobind-decorator";
import React from "react";

import {ILocalizedText} from "@translate/models";
import {ISelectValue} from "../button-like/models";

import InlineSelect from "@toolbox/button-like/InlineSelect";
import {intl2Str, parseNumber} from "@translate/T";

interface IPageSizeProps {
    value: number | "all";

    allowAll?: boolean;
    classNameDiv?: string;
    classNameLabel?: string;
    label?: JSX.Element | ILocalizedText;

    onChange(value: number | "all"): void;
}

class PageSize extends React.PureComponent<IPageSizeProps> {
    @boundMethod
    public onChange(value: string) {
        const effective = value === "all" ? "all" : parseNumber(value);
        this.props.onChange(effective);
    }

    public render() {
        const {value, allowAll, label, classNameLabel, classNameDiv} =
            this.props;

        const title: ILocalizedText = (intl) =>
            intl2Str(intl, "Items per page");
        const values = ["20", "50", "100", "200"];

        if (allowAll) {
            values.push("all");
        }

        return (
            <InlineSelect<string>
                label={label}
                classNameLabel={classNameLabel}
                classNameDiv={classNameDiv}
                idSuffix="page-size"
                selected={value.toString()}
                title={title}
                onSelected={this.onChange}
                convert={this.convert}
                values={values}
            />
        );
    }

    private convert(id: string): ISelectValue {
        let name: ILocalizedText = (intl) => intl2Str(intl, "None");

        switch (id) {
            case "20":
                name = (intl) => intl2Str(intl, "20");
                break;

            case "50":
                name = (intl) => intl2Str(intl, "50");
                break;

            case "100":
                name = (intl) => intl2Str(intl, "100");
                break;

            case "200":
                name = (intl) => intl2Str(intl, "200");
                break;

            case "all":
                name = (intl) => intl2Str(intl, "All");
                break;
        }

        return {id, name};
    }
}

export default PageSize;

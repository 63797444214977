import "./SessionExpiredNotification.scss";

import {faUserSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import {EExpireReasons} from "@/services/models";
import session from "@/services/session";
import {CLOSING_DELAY, IDLE_DELAY} from "@toolbox/models";

import Modal from "@toolbox/modals/Modal";
import T from "@translate/T";
import Login from "./Login";

interface ISessionExpiredNotificationState {
    resumed: boolean;
    expired: boolean;
    showModal: boolean;
    toastMounted: boolean;
}

class SessionExpiredNotification extends React.PureComponent<
    {},
    ISessionExpiredNotificationState
> {
    public readonly state: ISessionExpiredNotificationState = {
        expired: !session.token,
        resumed: false,
        showModal: false,
        toastMounted: false,
    };

    private timer = 0;
    private timer2 = 0;
    private unsubscribe?: () => void;
    private readonly ref = React.createRef<Modal>();

    public componentDidMount() {
        this.unsubscribe = session.subscribe({
            expired: (reason) => {
                if (reason === EExpireReasons.LoggedOut) {
                    return;
                }

                this.setState({
                    expired: true,
                    resumed: false,
                    showModal: false,
                    toastMounted: false,
                });
            },
        });
    }

    public componentWillUnmount() {
        window.clearTimeout(this.timer);
        window.clearTimeout(this.timer2);
        this.unsubscribe?.();
    }

    @boundMethod
    public setToastRef(toast: HTMLDivElement) {
        if (!toast) {
            return;
        }

        this.timer2 = window.setTimeout(
            () => this.setState({toastMounted: true}),
            IDLE_DELAY,
        );
    }

    @boundMethod
    public showModal(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showModal: true});
    }

    @boundMethod
    public onLoginClosed() {
        if (!this.state.resumed) {
            // User dismissed login modal
            this.setState({showModal: false});
            return;
        }

        // Resumed, show resumed toast
        this.setState({expired: false, showModal: false, toastMounted: false});

        this.timer = window.setTimeout(
            this.dissmisResumed,
            0.5 * CLOSING_DELAY,
        );
    }

    @boundMethod
    public onAuthenitcated() {
        this.setState({resumed: true}, this.ref.current?.triggerClose);
    }

    @boundMethod
    public dissmisResumed() {
        window.clearTimeout(this.timer);
        this.setState({resumed: false});
    }

    @boundMethod
    public navigateToLogin() {
        window.location.reload();
    }

    public render() {
        const {expired, resumed, showModal} = this.state;
        if (showModal) {
            return this.renderLogin();
        }

        if (resumed) {
            return this.renderResumedToast();
        }

        if (expired) {
            return this.renderExpiredToast();
        }

        return null;
    }

    private renderLogin() {
        return (
            <Modal
                ref={this.ref}
                header={<T>Session resume</T>}
                afterClose={this.onLoginClosed}
            >
                <div className="modal-body">
                    <Login onAuthenticated={this.onAuthenitcated} />
                </div>
            </Modal>
        );
    }

    private renderResumedToast() {
        const visible = this.state.toastMounted ? " fade show" : "";

        return (
            <div
                ref={this.setToastRef}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                className={"toast top-right" + visible}
            >
                <div className="toast-header text-success">
                    <FontAwesomeIcon
                        icon={faUserSlash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <strong className="mr-auto">
                        <T>Session resumed</T>
                    </strong>
                    <button
                        type="button"
                        className="close ml-2 mb-1"
                        aria-label="Close"
                        onClick={this.dissmisResumed}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    <T>You may need to perform your previous action again.</T>
                </div>
            </div>
        );
    }

    private renderExpiredToast() {
        const visible = this.state.toastMounted ? " fade show" : "";
        const resume = (
            <Link to="" onClick={this.showModal}>
                <T>resume</T>
            </Link>
        );
        const login = (
            <Link to="" onClick={this.navigateToLogin}>
                <T>Login page</T>
            </Link>
        );

        return (
            <div
                ref={this.setToastRef}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                className={"toast top-right" + visible}
            >
                <div className="toast-header text-warning">
                    <FontAwesomeIcon
                        icon={faUserSlash}
                        fixedWidth={true}
                        className="mr-1"
                    />
                    <b>
                        <T>Session expired</T>
                    </b>
                </div>
                <div className="toast-body" onClick={this.showModal}>
                    <FormattedMessage
                        id="You can {resume} your session without losing your unsaved data, or navigate to {login}."
                        defaultMessage="You can {resume} your session without losing your unsaved data, or navigate to {login}."
                        values={{login, resume}}
                    />
                </div>
            </div>
        );
    }
}

export default SessionExpiredNotification;

import {boundMethod} from "autobind-decorator";
import React from "react";

import {IProjectDocumentModel} from "@toolbox/building-blocks/models";

import AuditLog from "@toolbox/building-blocks/AuditLog";
import DocumentLink from "@toolbox/building-blocks/DocumentLink";

interface IProjectAuditProps {
    project: number;
}

class ProjectAudit extends React.PureComponent<IProjectAuditProps> {
    public render() {
        return (
            <AuditLog<IProjectDocumentModel>
                renderEntry={this.renderEntry}
                endpoint={"/api/audit/projects/" + this.props.project}
            />
        );
    }

    @boundMethod
    private renderEntry(doc: IProjectDocumentModel) {
        return (
            <DocumentLink
                value={doc}
                idSuffix={"project-" + this.props.project}
            />
        );
    }
}

export default ProjectAudit;

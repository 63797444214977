import {faAtom, faVials} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Redirect} from "react-router";

import http from "@/services/http";
import {ICentrifugeMeasurementModel} from "@cent/sample/models";
import {EDocumentTypes} from "../../models";
import {ECombineError, ICombineModel} from "./models";

import ValidatedForm from "@toolbox/button-like/ValidatedForm";
import NameChecker from "@toolbox/display-blocks/NameChecker";
import {bitwiseAND} from "@toolbox/functions/bitwise";
import Modal, {CancelButton} from "@toolbox/modals/Modal";
import T from "@translate/T";

interface ICombineProps {
    model: ICombineModel;

    afterClose: () => void;
}

interface ICombineState {
    name: string;
    combined?: ICentrifugeMeasurementModel;

    combineError: ECombineError;
    showProgress: boolean;
    redirect: boolean;
}

class Combine extends React.PureComponent<ICombineProps> {
    public readonly state: ICombineState = {
        combineError: ECombineError.None,
        name: this.props.model.name,
        showProgress: false,
        redirect: false,
    };

    private readonly ref = React.createRef<Modal>();

    @boundMethod
    public setName(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        this.setState({name: e.target.value});
    }

    @boundMethod
    public combineMeasurements() {
        this.setState(
            {combineError: ECombineError.None, showProgress: true},
            this.combine2Server,
        );
    }

    @boundMethod
    public afterClose() {
        if (this.state.combined) {
            this.setState({redirect: true});
        } else {
            this.props.afterClose();
        }
    }

    public render() {
        const {model} = this.props;
        const {combined, name, showProgress, redirect} = this.state;

        if (combined && redirect) {
            return (
                <Redirect
                    to={{
                        pathname: `/project/${model.project}/centrifuge/analyze`,
                        search: `?m=${combined.id}`,
                    }}
                    push={true}
                />
            );
        }

        return (
            <Modal
                ref={this.ref}
                header={<T>Combine measurements</T>}
                size="sm"
                afterClose={this.afterClose}
            >
                <ValidatedForm
                    suffixId="combine"
                    onSubmit={this.combineMeasurements}
                >
                    <div className="modal-body">
                        <label htmlFor="measurement-name-trim">
                            <T>Measurement name</T>
                        </label>

                        <input
                            type="text"
                            id="measurement-name-trim"
                            className="form-control"
                            value={name}
                            required={true}
                            onChange={this.setName}
                        />
                        {this.renderCombineError()}

                        <NameChecker
                            htmlNativ="small"
                            apiUrl={`/api/projects/${model.project}/namecheck`}
                            name={name}
                            id={0}
                            type={EDocumentTypes.Measurement}
                        />

                        {showProgress && (
                            <div className="form-text">
                                <FontAwesomeIcon
                                    icon={faAtom}
                                    fixedWidth={true}
                                    className="mr-1"
                                    spin={true}
                                />
                                <T>Combining measurements, please wait...</T>
                            </div>
                        )}
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={showProgress}
                        >
                            <FontAwesomeIcon
                                icon={faVials}
                                fixedWidth={true}
                                className="mr-1"
                            />
                            <T>Combine</T>
                        </button>
                        <CancelButton />
                    </div>
                </ValidatedForm>
            </Modal>
        );
    }

    private renderCombineError() {
        const {combineError} = this.state;
        if (!combineError) {
            return null;
        }

        return (
            <small id="combine-error" className="form-text text-danger">
                {!!bitwiseAND(combineError, ECombineError.DifferentDevice) && (
                    <React.Fragment>
                        <T>
                            Measurements can only be combined for the same
                            device.
                        </T>
                        <br />
                    </React.Fragment>
                )}
                {!!bitwiseAND(combineError, ECombineError.Tilt) && (
                    <React.Fragment>
                        <T>Tilt is different.</T>
                        <br />
                    </React.Fragment>
                )}
                {!!bitwiseAND(combineError, ECombineError.DeviceParameter) && (
                    <React.Fragment>
                        <T>
                            Different device parameters, check sample details.
                        </T>
                    </React.Fragment>
                )}
            </small>
        );
    }

    @boundMethod
    private async combine2Server() {
        const {model} = this.props;
        const {name} = this.state;
        const json: ICombineModel = {
            ...model,
            name: name.trim(),
        };

        try {
            const combined = await http
                .post("/api/centrifuge/measurement/combine", {json})
                .json<ICentrifugeMeasurementModel>();

            this.setState(
                {
                    combined,
                    combineError: ECombineError.None,
                    showProgress: false,
                },
                this.ref.current?.triggerClose,
            );
        } catch (error) {
            if (error instanceof http.HTTPError) {
                const combineError: ECombineError = await error.response.json();
                this.setState({combineError, showProgress: false});
            }
        }
    }
}

export default Combine;

import {IconProp, library} from "@fortawesome/fontawesome-svg-core";
import {
    faSortAlphaDown,
    faSortAlphaUp,
    faSortNumericDown,
    faSortNumericUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
library.add(faSortAlphaUp, faSortAlphaDown, faSortNumericDown, faSortNumericUp);

import {boundMethod} from "autobind-decorator";
import React from "react";
import {Link} from "react-router-dom";

import {ISampleBaseModel} from "@shared/models";
import {ICompareFunction} from "./models";

export function getSortFunction(
    ascending: boolean,
    getValue: (sample: ISampleBaseModel) => number | string,
): ICompareFunction {
    return (a, b) => {
        const aa = getValue(a);
        const bb = getValue(b);
        const asc = ascending ? 1 : -1;

        if (typeof aa === "string" && typeof bb === "string") {
            return asc * (aa > bb ? 1 : aa < bb ? -1 : 0);
        } else if (typeof aa === "number" && typeof bb === "number") {
            const highXlow = ascending ? Infinity : -Infinity;
            const convertNaN = (val: number) => (isNaN(val) ? highXlow : val);

            return asc * (convertNaN(aa) - convertNaN(bb));
        } else {
            return 0;
        }
    };
}

export interface ISortHeaderProps<TValue> {
    name: TValue;
    active: TValue;
    ascending: boolean;
    isNumber?: boolean;
    getValue?(sample: ISampleBaseModel): number | string;

    onSorted(
        field: TValue,
        ascending: boolean,
        getValue?: (sample: ISampleBaseModel) => number | string,
    ): void;
}

class SortHeader<TValue = string> extends React.PureComponent<
    ISortHeaderProps<TValue>
> {
    @boundMethod
    public onClick(e: React.SyntheticEvent) {
        e.preventDefault();

        const {active, ascending, name, onSorted, getValue} = this.props;
        onSorted(name, active !== name ? true : !ascending, getValue);
    }

    public render() {
        return (
            <React.Fragment>
                <Link to="" onClick={this.onClick}>
                    {this.renderSortIcon()}
                    {this.props.children}
                </Link>
            </React.Fragment>
        );
    }

    private renderSortIcon() {
        const {active, ascending, isNumber, name} = this.props;
        if (active !== name) {
            return null;
        }

        let testId: string;

        let icon: IconProp;
        if (!isNumber) {
            icon = ascending ? faSortAlphaDown : faSortAlphaUp;
            testId = ascending ? "icon-sort-alpha-down" : "icon-sort-alpha-up";
        } else {
            icon = ascending ? faSortNumericDown : faSortNumericUp;
            testId = ascending
                ? "icon-sort-numeric-down"
                : "icon-sort-numeric-up";
        }

        return (
            <FontAwesomeIcon
                data-testid={testId}
                icon={icon}
                fixedWidth={true}
            />
        );
    }
}

export default SortHeader;

import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import {debounce} from "lodash";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";
import {RouteComponentProps} from "react-router";
import {Titled} from "react-titled";

import http from "@/services/http";
import {ERoles} from "@/services/models";
import ParamsParser from "@/services/params";
import session from "@/services/session";
import {IDLE_DELAY} from "@toolbox/models";
import {IProjectModel, IProjectStats} from "../models";
import {IProjectResponse} from "./models";

import ActivityFlyoutButton from "@toolbox/building-blocks/ActivityFlyoutButton";
import Pager from "@toolbox/building-blocks/Pager";
import TasksPanel from "@toolbox/building-blocks/TasksPanel";
import ContainerActivity from "@toolbox/display-blocks/ContainerActivity";
import SearchSuggestion from "@toolbox/display-blocks/SearchSuggestion";
import {sortObject} from "@toolbox/functions/object-iterator";
import SearchInput from "@toolbox/nativ-inputs/SearchInput";
import T, {intl2Str} from "@translate/T";
import CreateProject from "../CreateProject";
import {getBasicParams, setBasicParams} from "../search/Index";
import ProjectAudit from "./ProjectAudit";
import ProjectDisplayMode from "./ProjectDisplayMode";
import RecentProjects from "./RecentProjects";
import SearchResult from "./SearchResult";

interface IProjectsListProps extends RouteComponentProps {
    intl: IntlShape;
}

interface IProjectsListState {
    projects: IProjectStats[];

    showCreate: boolean;
    emptyResult: boolean;
    total: number;
    suggestions?: string[];
}

class ProjectsList extends React.PureComponent<
    IProjectsListProps,
    IProjectsListState
> {
    public readonly state: IProjectsListState = {
        projects: [],
        showCreate: false,
        emptyResult: false,
        total: 0,
    };

    private readonly trigger = debounce(this.refresh, IDLE_DELAY);

    private get params() {
        const parser = new ParamsParser(this.props.location.search);

        return sortObject(getBasicParams(parser));
    }

    private set params(value) {
        const parser = new ParamsParser();
        setBasicParams(parser, value);

        const {history, location} = this.props;
        history.replace({...location, search: parser.toString()});
    }

    public async componentDidMount() {
        await this.trigger();
    }

    public componentWillUnmount() {
        this.trigger.cancel();
    }

    @boundMethod
    public getTitle(parent: string) {
        return intl2Str(this.props.intl, "Projects | {parent}", {parent});
    }

    @boundMethod
    public showCreateProject(e: React.SyntheticEvent) {
        e.preventDefault();

        this.setState({showCreate: true});
    }

    @boundMethod
    public hideCreateProject(created?: IProjectModel) {
        if (!created) {
            this.setState({showCreate: false});
            return;
        }

        const withStats: IProjectStats = {
            analyses: 0,
            measurements: 0,
            sops: 0,
            ...created,
        };

        this.setState({
            emptyResult: false,
            projects: [withStats].concat(this.state.projects),
            showCreate: false,
        });
    }

    @boundMethod
    public search(query: string) {
        this.params = {...this.params, query};
        this.trigger();
    }

    @boundMethod
    public setPage(page: number) {
        this.params = {...this.params, page};
        this.trigger();
    }

    public render() {
        return (
            <Titled title={this.getTitle}>
                <div className="container-fluid">
                    <ContainerActivity
                        left={this.renderLeft()}
                        right={this.renderRight()}
                    />

                    {this.renderCreateProject()}
                </div>
            </Titled>
        );
    }

    private renderLeft() {
        const {suggestions} = this.state;

        return (
            <React.Fragment>
                <TasksPanel>
                    <div className="btn-toolbar mr-auto">
                        {this.renderNewProjectButton()}
                    </div>

                    <ProjectDisplayMode />
                    <ActivityFlyoutButton className="ml-1" />
                </TasksPanel>

                <SearchInput query={this.params.query} onSearch={this.search} />
                <SearchSuggestion
                    suggestions={suggestions}
                    onClick={this.search}
                />

                {this.renderResult()}
            </React.Fragment>
        );
    }

    private renderRight() {
        return (
            <React.Fragment>
                <RecentProjects />
                <ProjectAudit />
            </React.Fragment>
        );
    }

    private renderResult() {
        const {total, projects, emptyResult} = this.state;
        const {page, pageSize} = this.params;

        if (emptyResult) {
            return (
                <p className="text-center text-warning">
                    <T>Your search did not match any project</T>
                </p>
            );
        }

        return (
            <React.Fragment>
                <SearchResult projects={projects} />
                <Pager
                    page={page}
                    total={total}
                    pageSize={pageSize}
                    bottomMargin={true}
                    onChange={this.setPage}
                />
            </React.Fragment>
        );
    }

    private renderNewProjectButton() {
        if (!session.hasRole(ERoles.Manager)) {
            return null;
        }

        return (
            <button
                type="button"
                id="new-project"
                className="btn btn-primary mr-1"
                onClick={this.showCreateProject}
            >
                <FontAwesomeIcon
                    icon={faPlus}
                    fixedWidth={true}
                    className="mr-1"
                />
                <T>New project</T>
            </button>
        );
    }

    private renderCreateProject() {
        if (!this.state.showCreate) {
            return null;
        }

        return <CreateProject onClose={this.hideCreateProject} />;
    }

    private async refresh() {
        const params = this.params;
        const pageSize = params.pageSize !== "all" ? params.pageSize : 200;

        const searchParams = {
            ...params,
            pageSize,
        };

        try {
            const response = await http
                .get("/api/projects", {searchParams})
                .json<IProjectResponse>();

            this.params = {...this.params, page: response.page};

            this.setState({
                emptyResult: !response.items.length,
                total: response.total,
                projects: response.items,
                suggestions: response.suggestions,
            });
        } catch {
            this.params = {...this.params, page: 1};
            this.setState({total: 0, emptyResult: true, projects: []});
        }
    }
}

export default injectIntl(ProjectsList);

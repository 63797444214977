import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {rgb2hex} from "@toolbox/functions/get-color-scheme";
import {intl2Num, intl2Str} from "@translate/T";
import {nm2Kev} from "../functions/units/length";

/** numberXunit: 0 number and unit, >0 number only, <0 unit only */
export function displayWavelength(
    intl: IntlShape,
    wavelength: number | number[],
    numberXunit = 0,
    seporator: string = " ",
    forceWavelength: boolean = false,
) {
    let wavelengths = wavelength;
    if (!Array.isArray(wavelength)) {
        wavelengths = [wavelength];
    }

    let value = intl2Num(intl, wavelengths, 0, {useGrouping: false});
    let unit = intl2Str(intl, "nm");
    if (usePicometer(wavelengths)) {
        if (forceWavelength) {
            value = intl2Num(
                intl,
                (wavelengths as number[]).map((x) => x * 1000),
                0,
                {useGrouping: false},
                seporator,
            );
            unit = intl2Str(intl, "pm");
        } else {
            value = intl2Num(
                intl,
                (wavelengths as number[]).map((x) => nm2Kev(x)),
                1,
                {useGrouping: false},
                seporator,
            );
            unit = intl2Str(intl, "keV");
        }
    }

    if (numberXunit > 0) {
        return value;
    } else if (numberXunit < 0) {
        return unit;
    }

    return value + " " + unit;
}

export function displayWavelengthLabel(
    intl: IntlShape,
    wavelengths?: number | number[],
) {
    return usePicometer(wavelengths ?? getPicoNumberForBoolean(false))
        ? intl2Str(intl, "Energy")
        : intl2Str(intl, "Wavelength");
}

export function getPicoNumberForBoolean(usePico: boolean) {
    return usePico ? 1 : 100;
}

function usePicometer(wavelengths: number | number[]) {
    if (!Array.isArray(wavelengths)) {
        return wavelengths <= 10;
    }

    return (wavelengths as number[]).some((x) => x <= 10);
}

export function getWavelengthColor(wavelength: number) {
    let r;
    let g;
    let b;
    let alpha;
    if (wavelength >= 380 && wavelength < 440) {
        r = (440 - wavelength) / (440 - 380);
        g = 0;
        b = 1;
    } else if (wavelength >= 440 && wavelength < 490) {
        r = 0;
        g = (wavelength - 440) / (490 - 440); // true blue with 440
        b = 1;
    } else if (wavelength >= 490 && wavelength < 510) {
        r = 0;
        g = 1;
        b = (510 - wavelength) / (510 - 490);
    } else if (wavelength >= 510 && wavelength < 580) {
        r = (wavelength - 510) / (580 - 510);
        g = 1;
        b = 0;
    } else if (wavelength >= 580 && wavelength < 645) {
        r = 1;
        g = (645 - wavelength) / (645 - 580); // true red with 645
        b = 0.0;
    } else if (wavelength >= 645 && wavelength < 780) {
        r = 1;
        g = 0;
        b = 0;
    } else {
        r = 0;
        g = 0;
        b = 0;
    }

    // intensty is lower at the edges of the visible spectrum.
    if (wavelength > 780 || wavelength < 380) {
        alpha = 0;
    } else if (wavelength > 700) {
        alpha = (780 - wavelength) / (780 - 700);
    } else if (wavelength < 420) {
        alpha = (wavelength - 380) / (420 - 380);
    } else {
        alpha = 1;
    }

    return rgb2hex([r, g, b], alpha);
}

// old system, will be keept if sales decide otherwise for now
// export function getWavelengthColor(wavelength: number) {
//     if (wavelength >= 380 && wavelength < 510) {
//         // blue light
//         return "#0000ff";
//     }

//     if (wavelength >= 510 && wavelength < 780) {
//         // red light
//         return "#ff0000";
//     }

//     // black or not visible
//     return "#000000";
// }

interface IWavelengthProps {
    intl: IntlShape;
    wavelength: number | number[];
}

class Wavelength extends React.PureComponent<IWavelengthProps> {
    public render() {
        const {wavelength} = this.props;

        const wavelengths = Array.isArray(wavelength)
            ? wavelength
            : [wavelength];
        const len = wavelengths.length - 1;

        return wavelengths.map((w, i) => (
            <React.Fragment key={i}>
                {this.getWavelength(w)}
                {i < len ? " " : null}
            </React.Fragment>
        ));
    }

    private getWavelength(wavelength: number) {
        const {intl} = this.props;
        const color = getWavelengthColor(wavelength);

        return (
            <span style={{color}}>{displayWavelength(intl, wavelength)}</span>
        );
    }
}

export default injectIntl(Wavelength);

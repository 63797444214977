import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {boundMethod} from "autobind-decorator";

interface ICustomizeLabelProps {
    label?: JSX.Element | ILocalizedText;
    htmlFor?: string;
    title?: ILocalizedText;

    labelNumber?: number;
    half?: boolean;
    noMargin?: boolean;
    topMargin?: boolean;
}

class CustomizeLabel extends React.PureComponent<ICustomizeLabelProps> {
    public render() {
        const {half, noMargin, topMargin} = this.props;

        if (half) {
            return <IntlContext.Consumer children={this.renderInner} />;
        }

        let margin = " mb-2";
        if (noMargin) {
            margin = "";
        } else if (topMargin) {
            margin = " mt-2";
        }

        return (
            <div className={`form-row${margin}`}>
                <IntlContext.Consumer children={this.renderInner} />
            </div>
        );
    }

    @boundMethod
    private renderInner(intl: IntlShape) {
        const {children, half, label, htmlFor, labelNumber, title} = this.props;

        const _labelNumber = half ? 3 : labelNumber ?? 4;
        const _otherNumber = half ? 3 : 12 - _labelNumber;

        if (!label) {
            const className =
                "col-sm-" + _otherNumber + " offset-sm-" + _labelNumber;

            return <div className={className}>{children}</div>;
        }

        return (
            <React.Fragment>
                <div
                    className={"d-flex col-sm-" + _labelNumber}
                    title={title?.(intl)}
                >
                    <label
                        className="d-flex align-items-center my-0"
                        htmlFor={htmlFor}
                    >
                        {typeof label === "function" ? label(intl) : label}
                    </label>
                </div>
                <div className={"col-sm-" + _otherNumber}>{children}</div>
            </React.Fragment>
        );
    }
}

export default CustomizeLabel;

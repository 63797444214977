import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlShape, injectIntl} from "react-intl";

import {ILocalizedText} from "@translate/models";
import {EDetailedFingerprint} from "./models";

import InlineCheckbox from "@toolbox/button-like/InlineCheckbox";
import T, {intl2Str} from "@translate/T";
import {getCentDetailImage} from "./images";

interface IDetailedFingerprintProps {
    intl: IntlShape;
    value: EDetailedFingerprint;
    onChange(value: EDetailedFingerprint): void;
}

interface IDetailedFingerprintState {
    height: number;
}

class DetailedFingerprint extends React.PureComponent<
    IDetailedFingerprintProps,
    IDetailedFingerprintState
> {
    public readonly state: IDetailedFingerprintState = {height: 0};

    private readonly div = React.createRef<HTMLDivElement>();

    public componentDidMount() {
        const div = this.div.current;
        const height = (div?.clientHeight ?? 0) * 5;
        this.setState({height});
    }

    @boundMethod
    public onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onChange(
            e.target.checked
                ? EDetailedFingerprint.Detailed
                : EDetailedFingerprint.Simple,
        );
    }

    public render() {
        const {intl, value} = this.props;
        const title: ILocalizedText = (_intl) =>
            intl2Str(
                _intl,
                "Shows out of time range profies grayed out, first valid profile in blue, zero profile in black.",
            );

        return (
            <div className="form-row mb-3">
                <div className="col-sm-2">
                    <label>
                        <T>Fingerprint view</T>
                    </label>
                </div>

                <div className="col-sm-4" ref={this.div}>
                    <InlineCheckbox
                        idSuffix="reference"
                        checked={value === EDetailedFingerprint.Detailed}
                        title={title}
                        toggle={this.onChange}
                    >
                        <T>Detailed</T>
                    </InlineCheckbox>
                </div>

                <div className="col-sm-3 align-self-center">
                    <img
                        alt={intl2Str(intl, "Fingerprint scheme")}
                        src={getCentDetailImage(value)}
                        height={this.state.height}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(DetailedFingerprint);

import React from "react";
import {FormattedMessage} from "react-intl";

import ErrorMessage from "@toolbox/design/ErrorMessage";
import T from "@translate/T";

interface IAccessDeniedProps {
    reason: JSX.Element;
}

class AccessDenied extends React.PureComponent<IAccessDeniedProps> {
    public render() {
        const {reason} = this.props;

        return (
            <ErrorMessage header={<T>Access denied</T>}>
                <FormattedMessage
                    id="Your account does not have the permission to {reason}."
                    defaultMessage="Your account does not have the permission to {reason}."
                    values={{reason}}
                />
            </ErrorMessage>
        );
    }
}

export default AccessDenied;

import React from "react";
import {NavLink} from "react-router-dom";

import {ESopLicenses} from "@/components/license/models";
import license from "@/services/license";
import {INavLink} from "@/services/models";
import navLinks from "@/services/nav-links";
import session from "@/services/session";

import T from "@translate/T";

interface INavLinksState {
    links: INavLink[];
    authenticated: boolean;
}

class NavLinks extends React.PureComponent<{}, INavLinksState> {
    public readonly state: INavLinksState = {
        authenticated: !!session.token,
        links: navLinks.items,
    };

    private unsubscribe?: () => void;

    public componentDidMount() {
        const unLinks = navLinks.subscribe({
            navLinkChanged: (links) => this.setState({links}),
        });
        const unSession = session.subscribe({
            authenticated: () => this.setState({authenticated: true}),
            expired: () => this.setState({authenticated: false}),
        });

        this.unsubscribe = () => {
            unLinks();
            unSession();
        };
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    public render() {
        return <ul className="navbar-nav mr-auto">{this.renderItems()}</ul>;
    }

    private renderItems() {
        const {links} = this.state;
        const itemLinks = links?.length ? links : this.getDefaultLinks();

        return itemLinks.map((itemLink) => (
            <li
                key={itemLink.url}
                className="nav-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
            >
                <NavLink className="nav-link" to={itemLink.url} exact={true}>
                    {itemLink.text}
                </NavLink>
            </li>
        ));
    }

    private getDefaultLinks() {
        if (!this.state.authenticated) {
            return [];
        }

        const {sops} = license.value;
        const devices = license.getSopLicences();
        const links = [];

        if (devices.frac) {
            links.push({text: <T>Test stamps</T>, url: "/stamps"});
        }

        if (devices.cent || devices.spoc) {
            links.push({text: <T>Materials</T>, url: "/materials"});
        }

        if (sops.includes(ESopLicenses.Template)) {
            links.push({text: <T>Templates</T>, url: "/templates"});
        }

        if (devices.frac || devices.cent) {
            links.push({text: <T>Downloads</T>, url: "/downloads"});
        }

        if (devices.frac || devices.fuge || devices.sizer) {
            links.push({text: <T>RCA calculator</T>, url: "/rca"});
        }

        return links;
    }
}

export default NavLinks;

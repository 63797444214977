import {boundMethod} from "autobind-decorator";
import React from "react";
import {IntlContext, IntlShape} from "react-intl";

import {ISelectValue} from "@toolbox/button-like/models";
import {ILocalizedText} from "@translate/models";
import {IRegion} from "./models";

import InlineSelect from "@toolbox/button-like/InlineSelect";
import Time from "@toolbox/design/Time";
import T, {intl2Num, intl2Str} from "@translate/T";

export const regions: IRegion[] = [
    {id: "AO", name: (intl) => intl2Str(intl, "Angola")},
    {id: "AR", name: (intl) => intl2Str(intl, "Argentinia")},
    {id: "AT", name: (intl) => intl2Str(intl, "Austria")},
    {id: "AU", name: (intl) => intl2Str(intl, "Australia")},
    {id: "BE", name: (intl) => intl2Str(intl, "Belgium")},
    {id: "BR", name: (intl) => intl2Str(intl, "Brazil")},
    {id: "CA", name: (intl) => intl2Str(intl, "Canada")},
    {id: "CH", name: (intl) => intl2Str(intl, "Switzerland")},
    {id: "CN", name: (intl) => intl2Str(intl, "China")},
    {id: "CO", name: (intl) => intl2Str(intl, "Colombia")},
    {id: "CZ", name: (intl) => intl2Str(intl, "Czech Republic")},
    {id: "DE", name: (intl) => intl2Str(intl, "Germany")},
    {id: "DK", name: (intl) => intl2Str(intl, "Denmark")},
    {id: "ES", name: (intl) => intl2Str(intl, "Spain")},
    {id: "FI", name: (intl) => intl2Str(intl, "Finland")},
    {id: "FR", name: (intl) => intl2Str(intl, "France")},
    {id: "GB", name: (intl) => intl2Str(intl, "United Kingdom")},
    {id: "GR", name: (intl) => intl2Str(intl, "Greece")},
    {id: "HK", name: (intl) => intl2Str(intl, "Hong Kong")},
    {id: "ID", name: (intl) => intl2Str(intl, "Indonesia")},
    {id: "IE", name: (intl) => intl2Str(intl, "Ireland")},
    {id: "IL", name: (intl) => intl2Str(intl, "Israel")},
    {id: "IN", name: (intl) => intl2Str(intl, "India")},
    {id: "IT", name: (intl) => intl2Str(intl, "Italy")},
    {id: "JP", name: (intl) => intl2Str(intl, "Japan")},
    {id: "KR", name: (intl) => intl2Str(intl, "Korea (South)")},
    {id: "LU", name: (intl) => intl2Str(intl, "Luxembourg")},
    {id: "MX", name: (intl) => intl2Str(intl, "Mexico")},
    {id: "MY", name: (intl) => intl2Str(intl, "Malaysia")},
    {id: "MZ", name: (intl) => intl2Str(intl, "Mozambique")},
    {id: "NL", name: (intl) => intl2Str(intl, "Netherlands")},
    {id: "NO", name: (intl) => intl2Str(intl, "Norway")},
    {id: "NZ", name: (intl) => intl2Str(intl, "New Zealand")},
    {id: "PH", name: (intl) => intl2Str(intl, "Philippines")},
    {id: "PL", name: (intl) => intl2Str(intl, "Poland")},
    {id: "PT", name: (intl) => intl2Str(intl, "Portugal")},
    {id: "RU", name: (intl) => intl2Str(intl, "Russia")},
    {id: "SE", name: (intl) => intl2Str(intl, "Sweden")},
    {id: "SG", name: (intl) => intl2Str(intl, "Singapore")},
    {id: "SK", name: (intl) => intl2Str(intl, "Slovakia")},
    {id: "TH", name: (intl) => intl2Str(intl, "Thailand")},
    {id: "TR", name: (intl) => intl2Str(intl, "Turkey")},
    {id: "TW", name: (intl) => intl2Str(intl, "Taiwan")},
    {id: "US", name: (intl) => intl2Str(intl, "United States")},
];

interface IRegionsProps {
    value: string;
    onChange(value: string): void;
}

class Regions extends React.PureComponent<IRegionsProps> {
    public render() {
        return (
            <div className="form-row mb-3">
                <IntlContext.Consumer children={this.renderDropdown} />
                <div className="col-sm-3 align-self-center text-center">
                    <IntlContext.Consumer children={this.renderNumber} />
                </div>
                <div className="col-sm-3 align-self-center text-center">
                    <Time value={new Date()} />
                </div>
            </div>
        );
    }

    @boundMethod
    private renderDropdown(intl: IntlShape) {
        const {value, onChange} = this.props;
        const title: ILocalizedText = (_intl) =>
            intl2Str(_intl, "Applies to date, time, and numbers.");
        const values = regions
            .map(({id, name}) => ({id, name: name(intl)}))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x) => x.id);

        // add default option
        values.unshift("");

        return (
            <InlineSelect<string>
                label={<T>Number Format</T>}
                classNameLabel="col-sm-2 d-flex align-items-center my-0"
                classNameDiv="col-sm-4"
                idSuffix="predefined-region"
                selected={value}
                title={title}
                onSelected={onChange}
                convert={this.convert}
                values={values}
            />
        );
    }

    private renderNumber(intl: IntlShape) {
        return intl2Num(intl, 1234567.89, undefined, {notation: "standard"});
    }

    @boundMethod
    private convert(id: string): ISelectValue {
        return (
            regions.find((x) => x.id === id) ?? {
                id,
                name: (intl) => intl2Str(intl, "Default"),
            }
        );
    }
}

export default Regions;

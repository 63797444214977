import {faBook} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {boundMethod} from "autobind-decorator";
import React from "react";
import {injectIntl, IntlShape} from "react-intl";

import http from "@/services/http";
import license from "@/services/license";
import {ILicenses} from "@/services/models";
import session from "@/services/session";
import {ANY_FALSE} from "@toolbox/functions/models";

import {replaceLast} from "@toolbox/functions/string-operator";
import {searchObjectsEvery} from "@toolbox/functions/object-iterator";
import T from "@translate/T";

interface IUserManualProps {
    intl: IntlShape;
}

interface IUserManualState {
    licenses: ILicenses;
}

class UserManual extends React.PureComponent<
    IUserManualProps,
    IUserManualState
> {
    public readonly state: IUserManualState = {
        licenses: license.getSopLicences(),
    };

    private unsubscribe?: () => void;

    private get api() {
        return "/resources/";
    }

    private get locale() {
        const {intl} = this.props;
        const locale = intl.locale.split("-")[0];

        if (locale === "en") {
            return "";
        }

        return "-" + locale;
    }

    private get localePdf() {
        return this.locale.replace("-", ".");
    }

    private get token() {
        return `?token=${session.token ?? "not_found"}`;
    }

    public componentDidMount() {
        this.unsubscribe = license.subscribe({
            licenseChanged: () =>
                this.setState({licenses: license.getSopLicences()}),
        });
    }

    public componentWillUnmount() {
        this.unsubscribe?.();
    }

    @boundMethod
    public async onClick(path: string) {
        try {
            const response = await http.get(`/api/resources`, {
                searchParams: {path},
            });

            if (!response.ok) {
                throw new http.HTTPError(response);
            }

            window.open(path + this.token, "_blank")?.focus();
        } catch {
            const pathHtml = replaceLast(path, this.locale, "");
            const pathHtmlPdf = replaceLast(pathHtml, this.localePdf, "");

            window.open(pathHtmlPdf + this.token, "_blank")?.focus();
        }
    }

    public render() {
        const {licenses} = this.state;

        if (searchObjectsEvery<ILicenses>(licenses, ANY_FALSE)) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderLumiFrac()}
                {this.renderLumiReader()}
                {this.renderLumiSizerFuge()}
                {this.renderLumiSpoc()}
                {this.renderLumiReaderXRay()}
                <div className="dropdown-divider" />
            </React.Fragment>
        );
    }

    private renderLumiFrac() {
        if (!this.state.licenses.frac) {
            return null;
        }

        return this.renderButton(
            <T>LUMiFrac manual</T>,
            `${this.api}Manual.LUMiFrac${this.localePdf}.pdf`,
        );
    }

    private renderLumiReader() {
        if (!this.state.licenses.reader) {
            return null;
        }

        return (
            <React.Fragment>
                {this.renderButton(
                    <T>LUMiReader analysis manual</T>,
                    `${this.api}manual-reader-analysis${this.locale}/index.html`,
                )}
                {this.renderButton(
                    <T>LUMiReader device manual</T>,
                    `${this.api}manual-reader-device${this.locale}/index.html`,
                )}
            </React.Fragment>
        );
    }

    private renderLumiSizerFuge() {
        const {sizer, fuge} = this.state.licenses;
        if (!sizer && !fuge) {
            return null;
        }

        return this.renderButton(
            <T>LUMiSizer/Fuge manual</T>,
            `${this.api}Manual.LUMiSizer.LUMiFuge${this.localePdf}.pdf`,
        );
    }

    private renderLumiSpoc() {
        if (!this.state.licenses.spoc) {
            return null;
        }

        return this.renderButton(
            <T>LUMiSpoc manual</T>,
            `${this.api}Manual.LUMiSpoc${this.localePdf}.pdf`,
        );
    }

    private renderLumiReaderXRay() {
        if (!this.state.licenses.xray) {
            return null;
        }

        return this.renderButton(
            <T>LUMiReader X-Ray manual</T>,
            `${this.api}Manual.LUMiReaderXRay${this.localePdf}.pdf`,
        );
    }

    private renderButton(title: JSX.Element, path: string) {
        const onClick = (e: React.SyntheticEvent) => {
            e.preventDefault();

            this.onClick(path);
        };

        return (
            <button type="button" className="dropdown-item" onClick={onClick}>
                <FontAwesomeIcon
                    fixedWidth={true}
                    icon={faBook}
                    className="mr-1"
                />
                {title}
            </button>
        );
    }
}

export default injectIntl(UserManual);

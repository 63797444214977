export function bitwiseAND(value: number, flag: number): number {
    // tslint:disable-next-line:no-bitwise
    return value & flag;
}

export function bitwiseOR(value: number, flag: number): number {
    // tslint:disable-next-line:no-bitwise
    return value | flag;
}

export function bitwiseXOR(value: number, flag: number): number {
    // tslint:disable-next-line:no-bitwise
    return value ^ flag;
}

export function bitwiseNOT(value: number): number {
    // tslint:disable-next-line:no-bitwise
    return ~value;
}

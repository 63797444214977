import EventHandlers from "@/services/handlers";

interface IEventHandler {
    modalChanged?: (hasModal: boolean) => void;
}

class ModalService {
    private readonly handlers = new EventHandlers<IEventHandler>();

    private hasModalValue = false;

    /** Gets a value indicating if there's any modal visible */
    public get hasModal() {
        return this.hasModalValue;
    }

    /** Sets a value indicating if there's any modal visible */
    public set hasModal(value: boolean) {
        if (value === this.hasModalValue) {
            return;
        }

        this.hasModalValue = value;
        this.handlers.publish((x) => x.modalChanged?.(value));
    }

    /** Subscribes to modal state change event */
    public subscribe(handler: IEventHandler) {
        return this.handlers.register(handler);
    }
}

const modal = new ModalService();
export default modal;

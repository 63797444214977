import {ITemplateModel} from "@/components/template/models";
import {EModules} from "@/models";

export enum EAnalysisTab {
    Standard,
    Template,
}

export interface IModuleGroup {
    modules: EModules[];
    id: string;
    header?: React.ReactChild;
}

export interface IModuleItemGroup extends IModuleGroup {
    items: ITemplateModel[];
}

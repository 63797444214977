import React from "react";

import {EModuleLicences} from "@/components/license/models";
import {EModules} from "@/models";
import license from "@/services/license";
import {IModuleGroup} from "@shared/analysis/analyze-button/models";
import {EServerId} from "@shared/models";

import AnalyzeButton from "@shared/analysis/analyze-button/Index";
import {isSize} from "@toolbox/functions/engine-check";
import T from "@translate/T";

function isEngineLicensed(engine: EModules) {
    const {modules} = license.value;

    switch (engine) {
        case EModules.SpocView:
            return modules.includes(EModuleLicences.SpocView);

        case EModules.SpocAlignment:
            return true;

        case EModules.SpocCount:
            return modules.includes(EModuleLicences.SpocCount);

        default:
            if (isSize(engine)) {
                return modules.includes(EModuleLicences.SpocSize);
            }

            return false;
    }
}

function getAvailableModules(): IModuleGroup[] {
    const groups: IModuleGroup[] = [
        {
            id: "spoc-view",
            modules: [EModules.SpocView],
            header: <T>Spoc view</T>,
        },
    ];

    if (isEngineLicensed(EModules.SpocCount)) {
        groups.push({
            id: "spoc-analyses",
            modules: [EModules.SpocCount],
            header: <T>Basic analysis</T>,
        });
    }

    if (isEngineLicensed(EModules.Size)) {
        groups.push({
            id: "spoc-psa",
            modules: [
                /*ToDo: Modules.SpocSizeVolume,*/ EModules.SpocSizeNumber,
            ],
            header: <T>Particle size characterization</T>,
        });
    }

    return groups;
}

interface ISpocAnalyzeButtonProps {
    targets: string;
    project: number;

    className?: string;
    hrefTarget?: string;

    getTargets?(): Promise<string>;
}

class SpocAnalyzeButton extends React.PureComponent<ISpocAnalyzeButtonProps> {
    public render() {
        const {className, project, hrefTarget, targets, getTargets} =
            this.props;
        const _getTargets = async () => targets;

        return (
            <AnalyzeButton
                className={className}
                hrefTarget={hrefTarget}
                project={project}
                serverId={EServerId.Spoc}
                getModules={this.getModules}
                getTargets={getTargets ?? _getTargets}
            />
        );
    }

    private async getModules(_: string) {
        return getAvailableModules();
    }
}

export default SpocAnalyzeButton;

import EventHandlers from "@/services/handlers";
import {INavLink} from "@/services/models";

interface IEventHandler {
    navLinkChanged?: (links: INavLink[]) => void;
}

class NavLinks {
    private readonly events = new EventHandlers<IEventHandler>();

    private value = [] as INavLink[];

    public get items() {
        return this.value;
    }

    public set items(value) {
        this.value = value;
        this.events.publish((x) => x.navLinkChanged?.(value));
    }

    public subscribe(handler: IEventHandler) {
        return this.events.register(handler);
    }
}

const navLinks = new NavLinks();
export default navLinks;

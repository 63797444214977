import React from "react";
import {IntlShape} from "react-intl";

import {ESmoothing} from "./models";

import InlineSelect from "@toolbox/button-like/InlineSelect";
import T, {intl2Str} from "@translate/T";

export function getSmootingDisplay(
    intl: IntlShape,
    smoothing: ESmoothing,
): string {
    switch (smoothing) {
        case ESmoothing.None:
            return intl2Str(intl, "None");

        case ESmoothing.Average3:
            return intl2Str(intl, "Moving average (3 points)");

        case ESmoothing.Average5:
            return intl2Str(intl, "Moving average (5 points)");

        case ESmoothing.Average9:
            return intl2Str(intl, "Moving average (9 points)");

        case ESmoothing.SavitzkyGolay:
            return intl2Str(intl, "Savitzky Golay");
    }
}

interface ISmoothingProps {
    value: ESmoothing;
    onChange(value: ESmoothing): void;
}

class Smoothing extends React.PureComponent<ISmoothingProps> {
    public render() {
        const {value, onChange} = this.props;

        return (
            <div className="form-row mb-3">
                <InlineSelect<ESmoothing>
                    label={<T>Smoothing</T>}
                    classNameLabel="col-sm-2 d-flex align-items-center my-0"
                    classNameDiv="col-sm-4"
                    idSuffix="smoothing"
                    selected={value}
                    onSelected={onChange}
                    otherOptionDisplay={getSmootingDisplay}
                    otherSelectDisplay={getSmootingDisplay}
                    values={
                        Object.values(ESmoothing).filter(
                            (x) => typeof x !== "string",
                        ) as ESmoothing[]
                    }
                />
            </div>
        );
    }
}

export default Smoothing;
